/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from './Button'
import StudentData from './StudentData'
import TutorData from './TutorData'
import { useLocation, useNavigate } from 'react-router';
import FeeHeader from './FeeHeader';
import FeeManagement from './FeeManagement';



const Fee = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const [date,setdate] = useState("")
  const location = useLocation();
  const navigate = useNavigate()
  const [active, setActive] = useState("all")
  const [mainActive, setMainActive] = useState("Student")
  const [activeStatus, setActiveStatus] = useState(null)
  const [activeUserType, setActiveUserType] = useState(null)
  const StudentTutorFee = ["Student's Fee", "Tutor Fee"]
  const [headerCityId,setHeaderCityId] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  const [searchText, setSearchText] = useState("");
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };


  const handleClick = (data) => {
    const res = returnValueButton(data)
    setMainActive(res)
    navigate(`?filter=${res}`);

  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newTab = queryParams.get('filter');
    const activeTab = queryParams.get('tab');
    if (newTab && activeTab) {
      setMainActive(newTab)
      navigate(`?filter=${newTab}&tab=${activeTab}`);
    }
    else {
      setActive(newTab)
      navigate(`?filter=${mainActive}&tab=all`);
    }

  }, []);


  const newSetState = (data) => {
    if (data?.userType) setActiveUserType(data?.userType)
    if (data?.status) setActiveStatus(data?.status)
  }


  function returnValueButton(state) {
    switch (state) {
      case "Student's Fee":
        return "Student"
      case "Tutor Fee":
        return "Tutor"
      default:
        return ""
    }
  }

  

  return (
    <>
      <FeeHeader setdate={setdate} date={date} active={active} activeStatus={activeStatus} activeUserType={activeUserType} headerCityId={headerCityId} setHeaderCityId={setHeaderCityId} searchText={searchText} handleSearch={handleSearch} roleId={roleId}  />

      <div className='overflow-hidden overflow-y-auto'>
        {/* <section className='first_two_button flex gap-4 m-5'>
          {StudentTutorFee.map((item, index) => {
            return (
              <div key={index} className={` px-4 py-2 flex justify-center font-semibold  text-white rounded-full ${mainActive === returnValueButton(item) ? 'bg-[#023565]' : 'bg-[#9C9EAB]'}`} >
                <Button onclick={() => handleClick(item)} name={item}></Button>
              </div>
            )
          })}
        </section> 
        {
          mainActive === "Student" && <StudentData newSetState={newSetState} setActive={setActive} mainActive={mainActive} setdate={setdate} date={date} headerCityId={headerCityId} searchText={searchText} />
        }
        {
          mainActive === "Tutor" && <TutorData newSetState={newSetState} setActive={setActive} mainActive={mainActive} setdate={setdate} date={date} headerCityId={headerCityId} searchText={searchText} />
        } */}

        <FeeManagement headerCityId={headerCityId} searchText={searchText} setdate={setdate} date={date} />
      </div>
    </>
  )
}

export default Fee