import moment from 'moment'
import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

function TableBanner({ getAdminNotification, totalPages, currentPages, pageSize, handlePreviousPage, handleNextPage, handleRead, highlightedRow }) {

  return (
    <section className="py-10  md:block hidden">
      <div className="rounded-lg bg-white overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-4 text-left text-lg font-bold text-[#313131] table_head tracking-wider">
                #
              </th>
              <th className="px-4 py-4 text-left text-lg font-bold text-[#313131] table_head tracking-wider">
                Message
              </th>
              <th className="px-4 py-3 text-left text-lg font-bold text-[#313131] table_head tracking-wider">
                City
              </th>
              <th className="px-4 py-3 text-left text-lg font-bold text-[#313131] table_head tracking-wider">
                Date
              </th>
              {/* <th className="px-4 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Action
              </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {getAdminNotification && getAdminNotification.length > 0 && getAdminNotification.map((item, index) => (
              <tr
                key={item.id}
                className={`capitalize cursor-pointer ${item.isRed ? ' bg-white' : "bg-[#023565] , text-white"}`}
                onClick={() => handleRead(item)} >
                <td className="px-4 py-4 whitespace-nowrap ">
                  {(currentPages - 1) * pageSize + index + 1}
                </td>
                <td className="px-4 py-4 whitespace-nowrap  ">
                 {item.msg}
                </td>
                <td className="px-4 py-4 whitespace-nowrap ">
                  {item.cityId?.name}
                </td>
                <td className="px-4 py-4 whitespace-nowrap ">
                  {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : null}
                </td>
                <td className="px-4 py-4 whitespace-nowrap " onClick={(e) => {
                  e.stopPropagation();
                  handleRead(item);
                }}>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPages === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handlePreviousPage}
              disabled={currentPages === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">
                {currentPages}
              </span>{" "}
              of{" "}
              <span className="border px-4 py-2 text-black  rounded">
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPages === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
              onClick={handleNextPage}
              disabled={currentPages === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default TableBanner
