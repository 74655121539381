import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action.js";
import Card from "./Card.js";
import ButtonFirst from "./ButtonFirst.js";
import ButtonSecond from "./ButtonSecond.js";
import ButtonThird from "./ButtonThird.js";
import ButtonFour from "./ButtonFour.js";
import Dashboardjson2 from "../dashboard/Dashboard2.json";
import ButtonCom from "./ButtonCom";
import KycHeader from "./KycHeader.js";
import KycTabel from "./KycTabel.js";
import LatestTutorChnage from "./LatestTutorChnage.js";
import "./Dashboard.css";
import DashBoardHeader from "./DashboardHeader/DashBoardHeader.js";
import { useLocation, useNavigate } from "react-router";


const Dashboard = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [dashBoardCount, setDashBoardCount] = useState({ "onGoingTuition": 0, "onDemoTuition": 0, "demoDoneTuition": 0, "latestDemoDone": 0, "latestDemoRequest": 0, "newReferral": 0, "newTicket": 0, "latestTutorChange": 0 })
  const [showData, setShowdata] = useState();
  const [newState, setNewState] = useState();
  const [active, setShowActive] = useState("latest-demo-request");
  const [response, setResponse] = useState([]);
  const [cardActive, setCardActive] = useState();
  const navigate = useNavigate()
  useEffect(() => {
    const cityData = roleId !== 1 ? adminCity && adminCity.length ? adminCity : [] : []
    dispatch(dashboardAction.getDashboardCount({cityId:cityData}));
  }, []);
  const [nlatestReferalListData, setnlatestReferalListData] = useState()
  const [totalPages, setTotalPages] = useState();
  const [newTicketList, setnewTicketList] = useState()
  const [totalPages4, setTotalPages4] = useState(1);

  const currentPage = 1
  const pageSize = 10
  const [totalPages5, setTotalPages5] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  let [tutorData, setTutorData] = useState({})
  const [fullData, setFullData] = useState('')
  let [DemoRequestListData, setDemoRequestList] = useState()
  const [suggestedTutor, setSuggestedTutor] = useState({})
  let [allTutorDataList, setAllTutorDataList] = useState()

  let [demoDoneListData, setDemoDoneListData] = useState([])
  let [DemoDoneListDataTutor, setDemoDoneListDataChange] = useState([])

  useEffect(() => {
    let demoDoneListData = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(dashboardAction.getLatestDemoDoneList(demoDoneListData));
    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(dashboardAction.getLatestDemoRequestList(demoRequestDetails));
    let newReferalDetails = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }

    dispatch(dashboardAction.getNewReferralsList(newReferalDetails))
    let demoDoneiiiListData = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    dispatch(dashboardAction.getLatestChangeTutorRequest(demoDoneiiiListData))
    let resData = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }
    dispatch(dashboardAction.getNewTicketList(resData))
  }, [currentPage]);



  useEffect(() => {
    setDemoRequestList(() => selector?.Dashboard?.getLatestDemoRequestList?.data?.list)
    setFullData(() => selector?.userList?.getTutorUpdateById?.data)
    setDemoDoneListData(selector?.Dashboard?.getLatestDemodDoneList?.data?.list)
    setDemoDoneListDataChange(() => selector?.Dashboard?.getlatestTutorChnageList?.data?.list)
    setTutorData(selector?.tution && selector?.tution?.getTutorDetails?.data)
    setnewTicketList(() => selector?.Dashboard?.getNewTicket?.data?.list)
    setnlatestReferalListData(() => selector?.Dashboard?.getLatestReferralList?.data?.list)
    setDashBoardCount(() => selector?.Dashboard?.dashboardCountList?.data)
    if (selector?.Dashboard?.getlatestTutorChnageList?.data?.total) {
      setTotalPages5(Math.ceil(selector?.Dashboard?.getlatestTutorChnageList?.data?.total / pageSize));
    }
    if (selector?.Dashboard?.getNewTicket?.data?.total) {
      setTotalPages4(Math.ceil(selector.Dashboard.getNewTicket.data.total / pageSize));
    }
    if (selector?.Dashboard?.getLatestReferralList?.data?.total) {
      setTotalPages(Math.ceil(selector.Dashboard.getLatestReferralList.data.total / pageSize));
    }
    if (selector?.Dashboard?.getLatestDemoRequestList?.data?.total) {
      setTotalPages2(Math.ceil(selector.Dashboard.getLatestDemoRequestList.data.total / pageSize));
    }
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
    setAllTutorDataList(selector?.tution && selector?.tution?.getAllTutorList && selector?.tution?.getAllTutorList?.data)
  }, [selector])



  const location = useLocation()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab');
    if (activeTab) {
      setShowActive(activeTab);
      navigate(`?tab=${activeTab}`)
    } else {
      setShowActive("latest-demo-request");
      navigate(`?tab=${"latest-demo-request"}`)
    }
  }, []);

  const handleResponse = (data) => {
    setResponse(data)
  }

  const buttonName = [
    `Latest Demo Request`,
    `Latest Demo Done`,
    `Latest Tutor Change Request`,
    `New Referrals`,
    `Latest Issues`,
  ]




  const handleBtnClick = (data) => {
    setShowdata(data);
    setShowActive(tabResValue(data));
    navigate(`?tab=${tabResValue(data)}`)
  };

  const handleActiveCard = (val) => {
    setCardActive(val);
  };

  const setNewStateHandle = (data) => {
    setNewState(data)
  }

  const handleOnChange = () => {
  }



  function valueReturnData(name) {
    switch (name) {
      case "Latest Demo Done":
        return dashBoardCount?.latestDemoDone;
      case "Latest Demo Request":
        return dashBoardCount?.latestDemoRequest;
      case "New Referrals":
        return dashBoardCount?.newReferral;
      case "Latest Issues":
        return dashBoardCount?.newTicket;
      case "Latest Tutor Change Request":
        return dashBoardCount?.latestTutorChange
      default:
        return 0;
    }
  }
  const [searchText, setSearchText] = useState("");


  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    returnData(value);
    if (!value) {
      setDemoRequestList(() => selector?.Dashboard?.getLatestDemoRequestList?.data?.list)
      setFullData(() => selector?.userList?.getTutorUpdateById?.data)
      setDemoDoneListData(selector?.Dashboard?.getLatestDemodDoneList?.data?.list)
      setDemoDoneListDataChange(() => selector?.Dashboard?.getlatestTutorChnageList?.data?.list)
      setTutorData(selector?.tution && selector?.tution?.getTutorDetails?.data)
      setnewTicketList(() => selector?.Dashboard?.getNewTicket?.data?.list)
      setnlatestReferalListData(() => selector?.Dashboard?.getLatestReferralList?.data?.list)
      setDashBoardCount(() => selector?.Dashboard?.dashboardCountList?.data)
      if (selector?.Dashboard?.getlatestTutorChnageList?.data?.total) {
        setTotalPages5(Math.ceil(selector?.Dashboard?.getlatestTutorChnageList?.data?.total / pageSize));
      }
      if (selector?.Dashboard?.getNewTicket?.data?.total) {
        setTotalPages4(Math.ceil(selector.Dashboard.getNewTicket.data.total / pageSize));
      }
      if (selector?.Dashboard?.getLatestReferralList?.data?.total) {
        setTotalPages(Math.ceil(selector.Dashboard.getLatestReferralList.data.total / pageSize));
      }
      if (selector?.Dashboard?.getLatestDemoRequestList?.data?.total) {
        setTotalPages2(Math.ceil(selector.Dashboard.getLatestDemoRequestList.data.total / pageSize));
      }
      setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
      setAllTutorDataList(selector?.tution && selector?.tution?.getAllTutorList && selector?.tution?.getAllTutorList?.data)
    }
  };

  function tabResValue(state) {
    switch (state) {
      case `Latest Demo Done`:
        return `latest-demo-done`
      case `Latest Demo Request`:
        return `latest-demo-request`
      case `New Referrals`:
        return `new-referrals`
      case `Latest Issues`:
        return `latest-issues`
      case `Latest Tutor Change Request`:
        return `latest-tutor-change-requests`
      default:
        return state
    }
  }


  function returnData(value) {
    const lowerCaseValue = value.toLowerCase();

    const filterData = (list, keys) => {
      return list.filter((item) =>
        keys.some((key) => {
          const data = key.split('.').reduce((o, i) => (o ? o[i] : null), item);
          return data?.toLowerCase().includes(lowerCaseValue);
        })
      );
    };

    if (active === "latest-demo-done") {
      const list = selector?.Dashboard?.getLatestDemodDoneList?.data?.list || [];
      const filteredData = filterData(list, ['parentId.name', 'parentId.mobNo', 'cityId.name', 'tutorId.name']);
      setDemoDoneListData(filteredData);
    }

    if (active === "latest-demo-request") {
      const list = selector?.Dashboard?.getLatestDemoRequestList?.data?.list || [];
      const filteredData = filterData(list, ['parentId.name', 'parentId.mobNo', 'cityId.name', 'tutorId.name']);
      setDemoRequestList(filteredData);
    }

    if (active === "new-referrals") {
      const list = selector?.Dashboard?.getLatestReferralList?.data?.list || [];
      const filteredData = filterData(list, ['refFrom.name', 'refFrom.mobNo', 'city.name', 'address', 'userId.name', 'userId.mobNo']);
      setnlatestReferalListData(filteredData);
    }

    if (active === "latest-issues") {
      const list = selector?.Dashboard?.getNewTicket?.data?.list || [];
      const filteredData = filterData(list, ['userId.name', 'userId.mobNo', 'title', 'cityId.name', 'msg']);
      setnewTicketList(filteredData);
    }

    if (active === "latest-tutor-change-requests") {
      const list = selector?.Dashboard?.getlatestTutorChnageList?.data?.list || [];
      const filteredData = filterData(list, ['parentId.name', 'parentId.mobNo', 'cityId.name', 'tutorId.name']);
      setDemoDoneListDataChange(filteredData);
    }
  }


  return (
    <>
      <DashBoardHeader handleOnChange={handleOnChange} handleSearch={handleSearch} searchText={searchText} />
      <section className="py-10 overflow-hidden overflow-y-auto">
        <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 xl:gap-10 2xl:gap-10 lg:px-10">
          <div className="w-full lg:w-4/5 mx-auto lg:px-0 px-4">
            <section className="three-card-section flex flex-col md:flex-row gap-3 cursor-pointer justify-between">
              {Dashboardjson2.slice(0, 3).map((item, index) => (
                <div
                  key={index}
                  className={`rounded-3xl w-full md:w-1/3 ${cardActive === index ? "bg-[#C60E6B] text-white"
                    : "bg-white text-[#023565] hover:bg-[#C60E6B] hover:text-white transition duration-300 ease-in-out"
                    }`}
                  onClick={() => handleActiveCard(index)}
                >
                  <Card
                    Group3={item.img}
                    index={cardActive}
                    title={item.title}
                    numbers={item.numbers}
                    buttonName={item.buttonName}
                    data={dashBoardCount}
                    onClick={() => handleResponse(item)}
                    link={item.url}
                  />
                </div>
              ))}
            </section>
            <section className="mt-8 w-full 2xl:w-[100%]">
              <div>
                <section className="flex flex-wrap section_button gap-3 text-center">
                  {buttonName.map((item, index) => {

                    return (
                      <div
                        key={index}
                        className={`text-gray-100 font-bold py-2 px-3 sm:flex-row rounded-full text-sm mb-3 lg:mb-0 ${active === tabResValue(item) ? "bg-[#023565]" : "bg-[#9C9EAB] hover:bg-[#C60E6B] hover:text-white transition duration-300 ease-in-out"}`}>
                        <ButtonCom onClick={() => handleBtnClick(item)} name={item} value={valueReturnData(item)}
                        />
                      </div>
                    )
                  })}
                </section>
              </div>
            </section>
            <div className="mt-6 lg:bg-white lg:p-4 rounded-lg lg:shadow-md">
              {active === tabResValue("Latest Demo Done") && (
                <ButtonFirst setNewStateHandle={setNewStateHandle} demoDoneListData={demoDoneListData} />
              )}
              {active === tabResValue("Latest Demo Request") && (
                <ButtonSecond setNewStateHandle={setNewStateHandle} DemoRequestListData={DemoRequestListData} fullData={fullData} totalPages2={totalPages2} suggestedTutor={suggestedTutor} allTutorDataList={allTutorDataList} />
              )}
              {active === tabResValue("New Referrals") && (
                <ButtonThird setNewStateHandle={setNewStateHandle} totalPages={totalPages} nlatestReferalListData={nlatestReferalListData} />
              )}
              {active === tabResValue("Latest Tutor Change Request") && (
                <LatestTutorChnage setNewStateHandle={setNewStateHandle} tutorData={tutorData} totalPages5={totalPages5} suggestedTutor={suggestedTutor} demoDoneListdata={DemoDoneListDataTutor} allTutorDataList={allTutorDataList} />
              )}
              {active === tabResValue("Latest Issues") && (
                <ButtonFour setNewStateHandle={setNewStateHandle} totalPages4={totalPages4} newTicketList={newTicketList} />
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/5 2xl:w-[25%]  lg:px-0 px-4">
            <div className="rounded-lg shadow-md">
              <div className="bg-[#D8D8D8] rounded-t-lg shadow-md py-2">
                <KycHeader />
              </div>
              <div className="bg-white rounded-b-lg shadow-md p-4 ">
                <KycTabel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
