import { SubjectConstants } from "../_constants/subject.constants";
import { APIcallFunction,headerForPrivateAPI } from "../_helpers"
import { alertActions } from "./alert.actions";


export const subjectAction={
    subjectGet,
    subjectCreate,
    subjectDelete,
    subjectUpdate,
    subjectEnaDis
}

function subjectGet(data) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/getSubjectList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: SubjectConstants.GET_SUBJECT_LIST_REQUEST } }
    function success(users) { return { type: SubjectConstants.GET_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: SubjectConstants.GET_SUBJECT_LIST_FAILURE, error } }


};
function subjectCreate(data,subj) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/create "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Subject created"));
                
                dispatch(success(users))
                dispatch(subjectGet(subj))
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: SubjectConstants.CREATE_SUBJECT_LIST_REQUEST } }
    function success(users) { return { type: SubjectConstants.CREATE_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: SubjectConstants.CREATE_SUBJECT_LIST_FAILURE, error } }


};

function subjectDelete(data,subj) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/delete"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
                dispatch(subjectGet(subj))
            },
            (error) => {
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: SubjectConstants.DELETE_SUBJECT_LIST_FAILURE } }
    function success(users) { return { type: SubjectConstants.DELETE_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: SubjectConstants.DELETE_SUBJECT_LIST_FAILURE, error } }


};
function subjectUpdate(data,subj) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/update"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
                dispatch(subjectGet(subj))
                dispatch(alertActions.success("Subject Updated"))

            },
            (error) => {
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: SubjectConstants.UPDATE_SUBJECT_LIST_REQUEST } }
    function success(users) { return { type: SubjectConstants.UPDATE_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: SubjectConstants.UPDATE_SUBJECT_LIST_FAILURE, error } }


};


function subjectEnaDis(data,subj) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/enableDisable"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
               
                dispatch(success(users))
                dispatch(subjectGet(subj))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: SubjectConstants.ENA_DIS_SUBJECT_LIST_REQUEST } }
    function success(users) { return { type: SubjectConstants.ENA_DIS_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: SubjectConstants.ENA_DIS_SUBJECT_LIST_FAILURE, error } }


};

