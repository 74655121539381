import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";
import FeeRefundHeader from './EarningHeader/FeeRefundHeader'
import Loader from '../../../components/Loader';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
const FeeRefund = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  let FeeRefundDataList = selector?.Fee?.feeRefundList?.data?.list
  let allFeeRefund = selector?.Fee?.allFeeRefund?.data
  const location = useLocation();
  const feeRefundData = location.state?.feeRefundData;
  let monthId = feeRefundData?.month
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;
  useEffect(() => {
    let feeRefundData = {
      "cityId": "",
      "month": monthId,
      "keyWord": "",
      "pageNo": currentPage,
      "size": pageSize
    }
    let feeRefundData1 = {
      "cityId": "",
      "month": monthId
    }
    dispatch(feeAction.getFeeRefundedList(feeRefundData))
    dispatch(feeAction.getAllFeeRefunded(feeRefundData1))
  }, [currentPage])
  const fields = ['Parent Name', 'Mobile Number', 'Student / Class', 'Due Date', 'Fee', 'Created At', 'Time'];
  const convertToCSV = (data) => {
    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const mobNo = item.parentId ? item.parentId.mobNo : '-';
      const studentsClasses = item.demoClassId && item.demoClassId.bookFreeDemo ? item.demoClassId.bookFreeDemo.map(demo => {
        const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(',') : '-';
        const className = demo.classId ? demo.classId.name : '-';
        return `${subjects} (${className})`;
      }).join(' | ') : 'N/A';

      const dueDate = item.demoClassId?.dueDate || '';
      const fee = item.fee || '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';

      const row = [parentName, mobNo, studentsClasses, dueDate, fee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };
  const handleBack = () => {
    window.history.back();
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (selector?.Fee?.feeRefundList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Fee?.feeRefundList?.data?.total / pageSize));
    }
  }, [selector]);
  return (
    <>
      <FeeRefundHeader />
      <section className='overflow-y-auto overflow-hidden'>
        <div className='m-5'>
          <Loader loading={selector?.Fee?.loading} />

          <div className="flex justify-between">
            <div className="flex gap-2">
              <IoArrowBack
                className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                onClick={handleBack}
              />
            </div>
            <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(allFeeRefund))}>
              <span>Export</span>  <img src="/export 1.png" alt='' />
            </button>
          </div>
          <div class="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3]">
            <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
              <thead class=" ">
                <tr className="capitalize border-b">
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    Parent name / Tutor name
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    subject
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Class
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Fee Refund
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {FeeRefundDataList &&
                  FeeRefundDataList?.map((item, id) => (
                    <tr class="capitalize  border-b dark:border-gray-700 forfontsmall">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="flex gap-3 pla">
                          <div>
                            <img
                              src={
                                item?.parentId?.image || item?.tutorId?.image ||
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              alt=''
                              className="w-8 h-8 rounded-full"
                            />
                          </div>
                          <div>
                            <p>{item?.parentId?.name || item?.tutorId?.name} ({item?.parentId ? "Parent" : item?.tutorId ? "Tutor" : null})</p>
                            <p>{item?.parentId?.mobNo || item?.tutorId?.mobNo}</p>
                          </div>
                        </div>
                      </th>
                      <td class="px-6 py-3">
                        {item?.demoClassId?.bookFreeDemo?.map((subj, id) => (
                          <p key={id}>{subj?.subjectId?.map((e) => e?.name).join(', ')}</p>
                        ))}
                      </td>
                      <td class="px-6 py-3">
                        {item?.demoClassId?.bookFreeDemo?.map((subj, id) => (
                          <p key={id}>{subj?.classId?.name}</p>
                        ))}
                      </td>
                      <td class="px-6 py-3">{item?.feeRefunded ?? "0"}</td>
                      <td class="px-6 py-3 text-green-700 font-semibold">
                        {item?.status === 0
                          ? " pending"
                          : item?.status === 1
                            ? " Received"
                            : item?.status === 2
                              ? " advance paid"
                              : "Refund"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2">
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                </span>
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default FeeRefund
