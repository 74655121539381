import React from 'react';
import { IoIosCloseCircle, IoIosAddCircle } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import Select from "react-select";


const RoleUpdateModal = ({
  roleUpdateModal,
  closeModal,
  editRole,
  setEditRole,
  allModule,
  options,
  selectedItems,
  setSelectedItems,
  submitUpdateData,
  customStyles,
  sidebarItems,
  errors,
}) => {
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEditRole({ ...editRole, [name]: value });
  };

  const handleModuleChange = (index, value) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index].name = value;
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const onSelect2 = (selectedList, selectedItem, index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index] = {
      ...updatedModules[index],
      access: selectedList.map((item) => item.label),
    };
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const onRemove2 = (selectedList, removedItem, index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index] = {
      ...updatedModules[index],
      access: selectedList.map((item) => item.label),
    };
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const removeModule = (index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules.splice(index, 1);
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const addModule = () => {
    const newModule = { name: '', access: [] };
    setEditRole({ ...editRole, accessModule: [...editRole.accessModule, newModule] });
  };

  return (
    roleUpdateModal && (
      <>
        


        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={closeModal}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Update Role</h2>
              </div>
              <div className="relative h-[480px] overflow-y-auto">
              <form className="p-4 space-y-2">
                  <div className="mb-4">
                    <label
                      htmlFor="role_name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Role Name
                    </label>
                    <input
                      type="text"
                      id="role_name"
                      name="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Update Role Name"
                      required
                      value={editRole.name}
                      onChange={handleOnChange}
                    />
                                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

                  </div>

                  {/* {editRole.accessModule?.map((module, index) => (
                    <div key={index} className="mb-4 border-2 p-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Module
                      </label>
                      <select
                        value={module.name}
                        onChange={(e) => handleModuleChange(index, e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        {allModule?.data?.map((item, id) => (
                          <option key={id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>

                      <label className="block mb-2 text-sm font-medium text-gray-900 mt-2">
                        Access
                      </label>
                      <Multiselect
                        options={options}
                        selectedValues={module.access.map((access) => ({ label: access }))}
                        onSelect={(selectedList, selectedItem) => onSelect2(selectedList, selectedItem, index)}
                        onRemove={(selectedList, removedItem) => onRemove2(selectedList, removedItem, index)}
                        displayValue="label"
                        className="bg-gray-50  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                      />

                      <button
                        type="button"
                        className="bg-red-500 text-white rounded px-4 py-2 hover:bg-red-600 transition"
                        onClick={() => removeModule(index)}
                      >
                        -
                      </button>
                    </div>
                  ))} */}

<div>
                        <Select
                          id="permission"
                          name="permission"
                          placeholder="Select permissions..."
                          value={editRole.permissions}
                          options={sidebarItems.map((item) => ({
                            value: item,
                            label: item.replace(/_/g, ' '), // Replacing underscores with spaces
                          }))}
                          className="capitalize text-black "
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption, actionMeta) => {
                            setEditRole({ ...editRole, ["permissions"]: selectedOption });
                          }}
                          isMulti
                        />
                        {errors.sidebarPermission && <p className="text-red-500 text-sm">{errors.sidebarPermission}</p>}

                      </div>

                  {/* <button
                    type="button"
                    className=" text-blue-800 rounded px-4 py-2 hover:bg-blue-600 transition mb-4"
                    onClick={addModule}
                  >
                    <IoIosAddCircle className="text-2xl" />
                  </button> */}

                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      onClick={submitUpdateData}
                    >
                      Update Role
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  );
};

export default RoleUpdateModal;
