import React, { useState } from "react";
import Button from "./Button";
import { ActiveParent } from "./ActiveParent";
import InActiveParent from "./InActiveParent";
import BlockedParent from "./BlockedParent";
import ParentHeader from "./ParentHeader";
import { parentAction } from "../../_actions";
import { useDispatch } from "react-redux";

const ParentStudentsProfile = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const buttonName = ["Active Parent", "Inactive Parent", "Blocked Parent"];
  const [active, setActive] = useState("Active Parent")
  const handleClick = (data) => {
    setActive(data)
  }

  const [currentPage] = useState(1);
  const pageSize = 10;
  const [searchText, setSearchText] = useState()
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")


  function ParamDataSendFunc(active) {
    switch (active) {
      case "Active Parent":
        return {
          status: "ACTIVE",
          userType: "PARENT",
          fromDate: "",
          toDate: "",
          keyWord: '',
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Inactive Parent":
        return {
          status: "INACTIVE",
          userType: "PARENT",
          fromDate: "",
          toDate: "",
          keyWord: '',
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Blocked Parent":
        return {
          status: "BLOCK",
          userType: "PARENT",
          fromDate: "",
          toDate: "",
          keyWord: '',
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      default:
        return {}
    }
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)

    // dispatch(parentAction.getUserList({ ...ParamDataSendFunc(active), keyWord: e.target.value }));
  }

  const handleReset = () => {
    setSearchText("");
    setHeaderCity(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  }



  return (
    <>
      <ParentHeader searchText={searchText} handleSearch={handleSearch} headerCity={headerCity} setHeaderCity={setHeaderCity} roleId={roleId} />

      <section className="overflow-hidden overflow-y-auto h-screen">
        <div className="m-5">
          <section className="flex gap-2">
            {buttonName.map((item, index) => (
              <div key={index} onClick={() => handleClick(item)} className={` px-4 py-2 rounded-full lg:text-sm text-xs font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-[#9C9EAB] text-white'}`}>
                <Button name={item} />
              </div>
            ))}
          </section>
          <section>
            {active === "Active Parent" && <ActiveParent searchText={searchText} headerCity={headerCity} handleReset={handleReset} />}
            {active === "Inactive Parent" && <InActiveParent searchText={searchText} headerCity={headerCity} handleReset={handleReset} />}
            {active === "Blocked Parent" && <BlockedParent searchText={searchText} headerCity={headerCity} handleReset={handleReset} />}
          </section>
        </div>
      </section>
    </>
  );
};

export default ParentStudentsProfile;
