import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TutorData from "./TutorData";
import ParentStudentData from "./ParentStudentData";
import RedemptionData from "./RedemptionData";
import WalletHeader from "./WalletHeader";
import { userListAction } from "../../_actions";
import Button from "../Tutor-Profile/Button";

const Wallet = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [active, setActive] = useState("Tutor");
  const buttonName = ["Tutor", "Parent/Student", "Redemption"];
  const handleClick = (data) => {
    setActive(data);
  };
  const [searchText, setSearchText] = useState("");
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchCity = (e) => {
    setHeaderCity(e.target.value);
  };

  useEffect(() => {
    dispatch(userListAction.getUserTypeList({ isManagement: true }));
  }, []);

  const tutorOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "TUTOR")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const parentOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "PARENT")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #000",
      },
      borderRadius: "5px",
    }),
    menu: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000 !important",
    }),
  };

  const handleReset = () => {
    setSearchText("");
    setHeaderCity(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "");
  };
  const userOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data.map((ele) => ({
      value: ele?._id,
      label: `${ele?.name} - ${ele?.mobNo} - ${ele?.userType === "PARENT" ? "Parent" : "Tutor"
        }`,
    }))
    : [];
  return (
    <>
      <WalletHeader
        handleSearch={handleSearch}
        searchText={searchText}
        handleSearchCity={handleSearchCity}
        headerCity={headerCity}
        roleId={roleId}
      />
      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5 ">
          <section className="flex flex-col lg:flex-row gap-3 sm:text-center">
            {buttonName.map((item, id) => (
              <div
                key={id}
                className={`px-10 py-1 rounded-full text-white ${active === item
                  ? "bg-[#023565] text-white text-center"
                  : "bg-gray-400 text-center"
                  }`}
                onClick={() => handleClick(item)}
              >
                <Button name={item} />
              </div>
            ))}
          </section>
          <section>
            {active === "Tutor" && (
              <TutorData
                searchText={searchText}
                headerCity={headerCity}
                tutorOptions={tutorOptions}
                customStyles={customStyles}
                handleReset={handleReset}
              />
            )}
            {active === "Parent/Student" && (
              <ParentStudentData
                searchText={searchText}
                headerCity={headerCity}
                parentOptions={parentOptions}
                customStyles={customStyles}
                handleReset={handleReset}
              />
            )}
            {active === "Redemption" && (
              <RedemptionData
                searchText={searchText}
                headerCity={headerCity}
                userOptions={userOptions}
                customStyles={customStyles}
                handleReset={handleReset}
              />
            )}
          </section>
        </div>
      </section>
    </>
  );
};

export default Wallet;
