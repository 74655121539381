/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaEye } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import { tutionAction } from "../../../_actions/tution.actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { IoIosArrowDroprightCircle, IoIosArrowDropupCircle } from "react-icons/io";
import Loader from "../../../components/Loader";
import { parentAction, userActions, userListAction } from "../../../_actions";
import { FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { teachModeViewData, timeStanderFollowFunction, truncateText } from "../../../_helpers";
import StudentDetail from "./Models/StudentDetail";
import ImagePreviewModal from "../../Tutor-Profile/Model/ImagePreview";
import UpdateNoteModel from "./Models/UpdateNoteModel";
import UpdateTuitionModel from "./Models/UpdateTuitionModel";
import ViewParent from "./Models/ViewParent";
import TuitionFilters from "./TuitionFilters";
import ViewMoreTuitionModel from "./Models/ViewMoreTuitionModel";
import { FaRegUserCircle } from "react-icons/fa";


const ButtonThirdTabel = (props) => {
  const { headerCity, searchText } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  // let tutorAssignData = selector?.tution?.demoList?.data?.list
  const [suggestedTutor, setSuggestedTutor] = useState();
  const [open, setOpen] = useState(false);
  const [tutorAssignData, setTutorAssign] = useState("");
  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [assignTutorList, setAssignTutorList] = useState("");
  const [demoIdForAssign, setDemoIdForAssign] = useState({});
  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 9;
  const [rejectResionModal, setRejectResionModal] = useState(false)
  const [rejectResion, setRejectResion] = useState('')
  const [rejectData, setRejectData] = useState({})
  const [fullData, setFullData] = useState('')
  const [tutorDetails, setTutorDetails] = useState(false);
  const menuRef = useRef(null)
  const mobileRef = useRef(null)
  const [filters, setFilters] = useState({
    // tuitionFee: "",
    tutorFee: "",
    preferredTeachMode: "",
    tutorId: "",
    classId: "",
    subjectId: "",
    classReference: "",
    // dueDate: "",
    demoDate: "",
  })
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setOpen(null);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let suggestTutorBtn = ["Applicants", "Suggested Tutors", "All Tutors"];

  let allTutorDataList =
    props && props?.getAllTutorList && props?.getAllTutorList?.data
      ? props?.getAllTutorList?.data
      : [];


  useEffect(() => {
    let listData = {
      status: [3],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : []
    };
    dispatch(tutionAction.getDemoClassListStatus(listData));
  }, [currentPage, filters, headerCity, searchText]);

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      );
    }
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data);
    setFullData(() => selector?.userList?.getTutorUpdateById?.data)

  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setTutorAssign(() => selector?.tution?.demoList?.data?.list);
  }, [selector]);

  const openAction = (itemId) => {
    setOpen((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query))
    setAssignTutorModal(true);
    setAssignTutorList(data);
    setDemoIdForAssign(data._id);
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };

  ///Handle Assign tutor=========================>>>>>>

  const handleAssign = (data) => {
    let Demodata = {
      status: [3],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };

    dispatch(tutionAction.changeTutorByAdmin(assignTutorInfo, Demodata));
    const alertBoxassign = document.getElementById("alert-box-assign");
    alertBoxassign.style.display = "block";
    setTimeout(() => {
      alertBoxassign.style.display = "none";
    }, 1000);
    setAssignTutorModal(false)
  };

  ///handle Reject====================>

  const handleRejectMsg = (data) => {
    setRejectResionModal(true)
    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };
    setRejectData(acceptedData)

  };

  const viewHistory = (data) => {
    navigate(`/app/viewhistory/${data?._id}`);
  };
  const handleview = (data) => {
    navigate(`/app/viewandupdate/${data?._id}/${data?.parentId?._id}`);
  };
  ///Mark Demo Done=====================>
  const handleMarkDemoDone = (data) => {
    let Demodata = {
      status: [3],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let demoDoneDetails = {
      demoId: data._id,
    };
    dispatch(tutionAction.demoDoneByAdmin(demoDoneDetails, Demodata));

    const alertBoxmark = document.getElementById("alert-boxmark");
    alertBoxmark.style.display = "block";
    setTimeout(() => {
      alertBoxmark.style.display = "none";
    }, 1000);
  };

  //Send to demo Request====================>

  const handleSendToDemoRequest = (data) => {
    let Demodata = {
      status: [3],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let sendToDemoDetails = {
      demoId: data._id,
    };

    dispatch(tutionAction.revertDemoClass(sendToDemoDetails, Demodata));
    const alertBoxsend = document.getElementById("alert-box-send");
    alertBoxsend.style.display = "block";
    setTimeout(() => {
      alertBoxsend.style.display = "none";
    }, 1000);
  };

  ////Reject    ==================>


  const [selectedTutorId, setSelectedTutorId] = useState(null);
  const showTutorDetails = (data) => {
    // setTutorDetails(true)
    setSelectedTutorId(selectedTutorId === data._id ? null : data._id);
    // setNewTutorDetailsData(data)
    let tutorId = {
      id: data._id
    }
    dispatch(userListAction.getTutorById(tutorId))
  };

  const [details, setFullDetails] = useState(false);
  const [fullDetailsData, setFullDetailsData] = useState("");

  const handleRes = (data) => {
    setFullDetails(true);
    setFullDetailsData(data);

  };

  const [remarkModal, setRemarkModal] = useState(false);
  const [remarkId, setRemarkId] = useState("");
  const [remark, setRemark] = useState("");

  const addRemark = (data) => {
    let remarkId = data?._id;
    setRemarkId(remarkId);
    setRemarkModal(true);
    setRemark(data?.reason ?? 'N/A')

  };


  const handleRemarkSubmit = (e) => {
    e.preventDefault();
    let demoData = {
      status: [3],
      // leadCreate: 'CREATED_SELF',
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : []
    };
    let resStatus = 'Hold'

    let remarkData = {
      demoId: remarkId,
      remark: remark ? remark : 'N/A',

    };
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus));
    setRemarkModal(false);
    setRemark("");
  };

  const remarkClose = () => {
    setRemark("");
    setRemarkModal(false);
  };

  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const [tuitionDetails, setTuitionDetails] = useState({})

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then((res) => {
      setfullDetailsModal(true);
      setfullDetailsModalData(res?.data);
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        teachingLocation: data?.teachingLocation,
        requiredGender: data?.requiredGender,
      })
    })

  };


  ///Reject==============================>>>>>>>>>>>>

  const handleRejectResion = (e) => {
    e.preventDefault()

    let demoData = {
      status: [3],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : []
    };

    let data = {
      ...rejectData,
      remark: rejectResion
    }

    dispatch(
      tutionAction.updateStatusDemoApproveOrReject(data, demoData)
    );
    setRejectResionModal(false)
    setRejectResion('')
    const alertBox = document.getElementById("alert-box");
    alertBox.style.display = "block";
    setTimeout(() => {
      alertBox.style.display = "none";
    }, 2000);

    setOpen(false);

  }


  const handlerejctCloseModal = () => {
    setRejectResionModal(false)
    setRejectResion('')
  }





  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])


  const [suggestedTutorId, setSuggestedTutorId] = useState('')
  const [suggestedTutorData, setSuggestedTutorData] = useState({})

  const showSugestedTutorDetails = (res) => {
    setSuggestedTutorId(suggestedTutorId === res._id ? null : res._id)
    setSuggestedTutorData(res)


  }

  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [studentListdata, setStudentListData] = useState();
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    };
    parentAction.getStudentListByParentIdAndDemoIdPromise(studentListDetails).then((resData) => {
      setViewStudentModal(true);
      setStudentListData(resData.data)
    }).catch((err) => {
    });
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };

  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField, setInputChangeField] = useState({})
  const [allStudentList, setAllStudentData] = useState([])
  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then((res) => {
      setUpdateDemoModel(true)
      setInputChangeField(res.data)
    })

    let studentListDetails = {
      parentId: data.parentId._id,
    };
    parentAction.getStudentListByParentIdForDemoPromise(studentListDetails).then((resData) => {
      setAllStudentData(resData.data)
    }).catch((err) => {
    });
  }
  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const [tuitionId, setTuitionId] = useState(null)

  const handleIsViewMoreTuitionModel = (item) => {
    setTuitionId(item?._id);
    setIsViewMoreModel(true)
  }


  return (
    <div className="">
      <Loader loading={selector?.tution?.loading} />

      <TuitionFilters filters={filters} setFilters={setFilters} />
      <div className="bg-white border lg:block hidden border-[#D3D3D3] rounded-lg mt-5 overflow-x-auto">
        <table className="min-w-full table-auto divide-y divide-gray-200">

          <thead className="min-w-full rounded-sm">
            <tr className="table_head border-b ">
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Parent Name</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Student</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">{" "}Address</tr>
                <tr className="flex justify-center items-center"> City</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">{" "} Demo Date</tr>
                <tr className="flex justify-center items-center"> Time</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Assigned Tutor</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Tutor Status</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">Lead Source</tr>
                <tr className="flex justify-center items-center">Manager</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Mode</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Note</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm">Action</th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {tutorAssignData && tutorAssignData.length > 0 ? (
              tutorAssignData.map((item, index) => (
                <tr key={index} className={`border-b capitalize `}>
                  <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                    <div className="flex items-center justify-center gap-2">
                      <span onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                        {item?.parentId && item?.parentId?.image ?
                          <img
                            alt=""
                            src={
                              item?.parentId && item?.parentId?.image
                                ? item?.parentId?.image
                                : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            onError={(e) => {
                              e.target.src =
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                            }}
                            className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                          />
                          : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                            <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                          </div>}
                      </span>
                      <div className="w-24">
                        <p className="font-semibold whitespace-nowrap text-[#023565]">
                          {item?.parentId?.name ?? "Not Available"}
                        </p>
                        <p className="text-gray-500 whitespace-nowrap">
                          {item?.parentId?.mobNo ?? "Not Available"}
                        </p>
                        <div className="flex items-center  gap-1 cursor-pointer" onClick={() => handleFullDetails(item)} >
                          <p className="text-base font-normal cursor-pointer">More</p>
                          <FaChevronCircleRight
                            className="text-[#023565] text-base cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="px-4 py-4 text-sm">
                    <span className="flex items-center justify-center"><FaEye size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => viewStudent(item?.parentId, item._id)} /></span>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="flex justify-center items-center text-center cursor-pointer" title={item?.address || 'N/A'}>  {truncateText(item?.address, 15) ?? 'N/A'}</span>
                    <span className="flex justify-center items-center">{item.cityId?.name ?? "N/A"}</span>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="text-center flex items-center justify-center">{moment(item?.demoDoneDate).format('YYYY-MMM-DD')}</span>
                    <span className="text-center flex items-center justify-center">{item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</span>
                  </td>

                  <td className="px-4 py-4 text-sm">
                    <span className="text-center flex items-center justify-center text-[#023565] font-semibold">{item?.tutorId?.name}</span>
                    <span className="text-center flex items-center justify-center text-gray-500">{item?.tutorId?.mobNo}</span>
                  </td>

                  <td className={`px-4 py-4 text-sm font-semibold text-center ${item.processStatus === 3 ? "text-yellow-500" : item.processStatus === 4 ? "text-green-500" : item.processStatus === 5 ? "text-red-500" : "text-gray-900"}`}>
                    {item.processStatus === 3 ? "Pending" : item.processStatus === 4 ? "Accepted" : item.processStatus === 5 ? "Rejected" : "N/A"}
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="flex items-center justify-center">{item.leadSource === "CREATED_SELF" ? "App" : "Admin"}</span>
                    <span className="flex items-center justify-center">{item?.assignToEmployee ? item.assignToEmployee.userName : "N/A"}</span>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900 text-center">
                    {teachModeViewData(item?.preferredTeachMode)}
                  </td>

                  <td className={`px-4 py-4 cursor-pointer font-semibold text-center ${item.reason ? 'text-[#00AFE2]' : 'text-[#023565]'}`} onClick={() => addRemark(item)}>{item?.reason ? 'View' : 'Add'}
                  </td>

                  <td className="px-4 py-4 text-gray-900 relative table-cell">
                    <span className="flex justify-center items-center"> <BsThreeDotsVertical onClick={() => openAction(item._id)} className="text-2xl cursor-pointer text-red-500 " /></span>
                    {open === item._id && (
                      <ul className="bg-white border text-[#023565] rounded-md w-48 fixed right-8 py-1 text-start text-sm font-bold shadow-lg" ref={menuRef}>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => assignTutor(item)}>
                          <button className="w-full text-left">Change Tutor</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleMarkDemoDone(item)}>
                          <button className="w-full text-left">Mark Demo Done</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleSendToDemoRequest(item)}>
                          <button className="w-full text-left">Send to Demo Request</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => updateDemoModelFunction(item)}>
                          <button className="w-full text-left">View & Update Info</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleRejectMsg(item)}>
                          <button className="w-full text-left">Reject</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1   flex justify-between items-center hover:text-[#C60E6B]" onClick={() => viewHistory(item)}>
                          <button className="w-full text-left">View History</button>
                          <FaAngleRight className="text-md" />
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        id="alert-box-assign"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Assign!
      </div>
      <div
        id="alert-box-assign"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Assign!
      </div>
      <div
        id="alert-boxmark"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Mark demo done!
      </div>
      <div
        id="alert-box-send"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Send to Demo Request!
      </div>
      <div
        id="alert-box"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition rejected!
      </div>

      <ViewParent
        fullDetails={fullDetailsModal}
        setFullDetails={setfullDetailsModal}
        DetailsData={fullDetailsModalData}
        tuitionDetails={tuitionDetails}
      />
      <StudentDetail
        setViewStudentModal={setViewStudentModal} viewStudentModal={viewStudentModal} setStudentListData={setStudentListData} studentListdata={studentListdata}
      />
      <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />

      {assignTutorModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[500px] ">
            <div className="flex justify-end m-2">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setAssignTutorModal(false)}
              >
                {" "}
                <span>
                  <MdOutlineClose />
                </span>
                Close
              </button>
            </div>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Assign Tutor</h2>
              </div>
              <section className="grid md:grid-cols-3 grid-cols-1  w-full">
                {suggestTutorBtn.map((itm, id) => (
                  <div key={id} className="m-2 ">
                    <button
                      className={`  text-sm py-2 w-full font-bold ${assignTutorTab === itm
                        ? "bg-[white] text-[#023565] border border-[#023565] rounded-md"
                        : "bg-[#D3D3D3]"
                        } `}
                      onClick={() => handleAssignTutor(itm)}
                    >
                      {itm}
                    </button>
                  </div>
                ))}
              </section>

              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <section>
                  {assignTutorTab === "Applicants" && (
                    <div class="h-[45vh] overflow-hidden overflow-y-auto">
                      <div>
                        <form class="flex items-center max-w-sm mx-auto">
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                            <input
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Search..."
                            />
                          </div>
                          <button
                            type="submit"
                            class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-4 h-4"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                            <span class="sr-only">Search</span>
                          </button>
                        </form>
                      </div>
                      <table className="min-w-full shadow-md rounded-md overflow-hidden">
                        <thead>
                          {assignTutorList &&
                            assignTutorList.tutorAppliedList &&
                            assignTutorList.tutorAppliedList.map((ele) => (
                              <div className="border rounded-xl p-6">
                                <div className="flex justify-between items-center">
                                  <div className="flex items-center space-x-6">
                                    <div className="flex items-center space-x-3">
                                      <div>
                                        <img
                                          src={
                                            ele && ele.image ? ele.image : 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'
                                          }
                                          className="rounded-full w-10 h-10"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p>
                                          {ele && ele.name ? ele.name : null}
                                        </p>
                                        <p>
                                          {ele && ele.mobNo ? ele.mobNo : null}
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      {
                                        selectedTutorId === ele?._id ? (
                                          <IoIosArrowDropupCircle
                                            size={20}
                                            className="cursor-pointer text-blue-700"
                                            onClick={() => showTutorDetails(ele)}
                                          />

                                        ) : (
                                          <IoIosArrowDroprightCircle
                                            size={20}
                                            className="cursor-pointer text-[#023565]"
                                            onClick={() => showTutorDetails(ele)}
                                          />
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      className="font-bold text-lg bg-[#C60E6B] px-3 text-white rounded-full hover:text-black "
                                      onClick={() => handleAssign(ele?._id)}
                                    >
                                      Assign
                                    </button>
                                  </div>
                                </div>
                                {selectedTutorId === ele._id && (
                                  <div className="w-full capitalize space-y-2 bg-gray-100 p-10 mx-auto transition-transform duration-300 ease-in-out transform scale-100 text-sm rounded-lg">

                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">DOB</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.dob ? moment(fullData?.tutorInfoId?.dob).format("DD-MM-YYYY") : "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Gender</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.gender ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Teaching Exp</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.teachingExp ?? "N/A"} Years</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Address</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.address ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Mobile No</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.mobNo ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Email</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.email ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">School Name</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.schoolName ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">UG Course</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">pg Course </p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.pgCourse ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Classes</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">
                                        {fullData?.tutorInfoId?.classYouCanTeach
                                          ?.map((ele) => ele?.name)
                                          .join(", ") ?? "N/A"}
                                      </p>
                                    </div>

                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%] capitalize">Subjects</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">
                                        {fullData?.tutorInfoId?.subYouCanTeach
                                          ?.map((ele) => ele?.name)
                                          .join(", ") ?? "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </thead>
                      </table>
                    </div>
                  )}
                </section>

                <section>
                  {assignTutorTab === "Suggested Tutors" && (
                    <div className="h-[45vh] overflow-hidden overflow-y-auto overflow-x-auto">
                      <div>
                        <form class="flex items-center max-w-sm mx-auto">
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                            <input
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Search..."
                            />
                          </div>
                          <button
                            type="submit"
                            class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-4 h-4"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                            <span class="sr-only">Search</span>
                          </button>
                        </form>
                      </div>
                      <div className="">
                        <table className="min-w-full  shadow-md  rounded-md overflow-hidden">
                          <tbody className="text-gray-600 text-sm font-light">
                            {suggestedTutor ? (
                              suggestedTutor.map((ele) => (
                                <div className="border rounded-xl p-6">
                                  <div className="flex justify-between items-center">
                                    <div className="flex items-center space-x-6">
                                      <div className="flex items-center space-x-3">
                                        <div>
                                          <img
                                            src={
                                              ele &&
                                                ele.userDetails &&
                                                ele.userDetails.image
                                                ? ele.userDetails.image
                                                : 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'
                                            }
                                            className="rounded-full w-10 h-10"
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>
                                            {ele &&
                                              ele.userDetails &&
                                              ele.userDetails.name
                                              ? ele.userDetails.name
                                              : null}
                                          </p>
                                          <p>
                                            {ele &&
                                              ele.userDetails &&
                                              ele.userDetails.mobNo
                                              ? ele.userDetails.mobNo
                                              : null}
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <FaChevronCircleRight
                                          size={20}
                                          className="cursor-pointer text-blue-700"
                                          onClick={() => showSugestedTutorDetails(ele)}
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="font-bold text-lg bg-[#C60E6B] text-white px-3 rounded-full hover:text-black "
                                        onClick={() =>
                                          handleAssign(ele?.userDetails?._id)
                                        }
                                      >
                                        Assign
                                      </button>
                                    </div>
                                  </div>
                                  {suggestedTutorId === ele._id && (
                                    <div className="tutor-details overflow-hidden overflow-x-auto">
                                      <div className="w-[700] border border-black rounded-md capitalize space-y-2 bg-gray-100 p-5 overflow-y-auto text-sm transition-opacity duration-300">
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">DOB</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">
                                            {suggestedTutorData?.tutorInfoDetails?.dob ? moment(suggestedTutorData?.tutorInfoDetails?.dob).format("DD-MM-YYYY") : "N/A"}
                                          </p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Gender</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.tutorInfoDetails?.gender ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Teaching Exp</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.tutorInfoDetails?.teachingExp ?? "N/A"} Years</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Address</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.userDetails?.address ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Mobile No</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.userDetails?.mobNo ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Email</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.userDetails?.email ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">School name</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.tutorInfoDetails?.schoolName ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">UG Course</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.tutorInfoDetails?.ugCourse ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">PG Course </p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">{suggestedTutorData?.tutorInfoDetails?.pgCourse ?? "N/A"}</p>
                                        </div>
                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%]">Classes</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">
                                            {suggestedTutorData?.classDetails
                                              ?.map((ele) => ele?.name)
                                              .join(", ") ?? "N/A"}
                                          </p>
                                        </div>

                                        <div className="w-full flex justify-between items-center">
                                          <p className="font-semibold w-[40%] capitalize">Subjects</p>
                                          <span className="w-[20%]">:</span>
                                          <p className="w-[40%]">
                                            {suggestedTutorData?.subjectDetails
                                              ?.map((ele) => ele?.name)
                                              .join(", ") ?? "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <div className="text-black">No Data</div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </section>

                <section>
                  {assignTutorTab === "All Tutors" && (
                    <div className="h-[45vh] overflow-hidden overflow-y-auto">
                      <div className="m-2 p-3">
                        <form class="flex items-center max-w-sm mx-auto">
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                            <input
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Search..."
                            />
                          </div>
                          <button
                            type="submit"
                            class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            <svg
                              class="w-4 h-4"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                            <span class="sr-only">Search</span>
                          </button>
                        </form>
                      </div>
                      <table className="min-w-full shadow-md rounded-md overflow-hidden m-2 ">
                        <thead>
                          {allTutorDataList ? (
                            allTutorDataList.map((ele) => (
                              <div className="border rounded-xl p-6 my-2">
                                <div className="flex justify-between items-center">
                                  <div className="flex items-center space-x-6 ">
                                    <div className="flex items-center space-x-3">
                                      <div>
                                        <img
                                          src={
                                            ele && ele.image ? ele.image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s'
                                          }
                                          className="rounded-full w-10 h-10"
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p className="capitalize">
                                          {ele && ele.name ? ele.name : null}
                                        </p>
                                        <p>
                                          {ele && ele.mobNo ? ele.mobNo : null}
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      {
                                        selectedTutorId === ele?._id ? (
                                          <IoIosArrowDropupCircle
                                            size={20}
                                            className="cursor-pointer text-blue-700"
                                            onClick={() => showTutorDetails(ele)}
                                          />

                                        ) : (
                                          <IoIosArrowDroprightCircle
                                            size={20}
                                            className="cursor-pointer text-[#023565]"
                                            onClick={() => showTutorDetails(ele)}
                                          />
                                        )
                                      }
                                      {/* <FaChevronCircleRight
                                          size={20}
                                          className="cursor-pointer text-blue-700"
                                          onClick={() => showTutorDetails(ele)}
                                        /> */}
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      className="font-bold text-lg bg-[#C60E6B] text-white rounded-full hover:text-black px-3"
                                      onClick={() => handleAssign(ele?._id)}
                                    >
                                      Assign
                                    </button>
                                  </div>
                                </div>
                                {selectedTutorId === ele._id && (
                                  <div className="w-full capitalize space-y-2 bg-gray-100 p-10 mx-auto transition-transform duration-300 ease-in-out transform scale-100 text-sm rounded-lg">

                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">DOB</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.dob ? moment(fullData?.tutorInfoId?.dob).format("DD-MM-YYYY") : "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Gender</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.gender ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Teaching Exp</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.teachingExp ?? "N/A"} Years</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Address</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.address ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Mobile No</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.mobNo ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Email</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.email ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">School name</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.schoolName ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">UG Course</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">PG Course </p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">{fullData?.tutorInfoId?.pgCourse ?? "N/A"}</p>
                                    </div>
                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%]">Classes</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">
                                        {fullData?.tutorInfoId?.classYouCanTeach
                                          ?.map((ele) => ele?.name)
                                          .join(", ") ?? "N/A"}
                                      </p>
                                    </div>

                                    <div className="w-full flex justify-between items-center">
                                      <p className="font-semibold w-[40%] capitalize">Subjects</p>
                                      <span className="w-[20%]">:</span>
                                      <p className="w-[40%]">
                                        {fullData?.tutorInfoId?.subYouCanTeach
                                          ?.map((ele) => ele?.name)
                                          .join(", ") ?? "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="text-black">No Data</div>
                          )}
                        </thead>
                      </table>
                    </div>
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        tutorDetails && (
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40"
          >
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <div className="flex justify-end">
                <button
                  onClick={() => setTutorDetails(false)}
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                >
                  <MdOutlineClose /> Close
                </button>In-Home
              </div>

              <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Tutor Details </h2>
                </div>

                <div className="grid md:grid-cols-2 gap-4 p-2">
                  <div className="w-full text-center">

                    <img
                      src={fullData?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                      alt="Tutor"
                      className="max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px] rounded-full"
                    />
                  </div>
                  <div className="w-full capitalize space-y-2">
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Name</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.name ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Gender</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.gender ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Teaching Exp</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.teachingExp ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Address</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.address ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Mobile No</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.mobNo ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Email</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.email ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">School name</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.schoolName ?? "N/A"}</p>
                    </div>


                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">ug Course</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">pg Course</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.pgCourse ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Classes</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">
                        <p>
                          {fullData?.tutorInfoId?.classYouCanTeach?.map((ele) => ele?.name).join(', ') ?? 'N/A'}
                        </p>
                      </p>
                    </div>

                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%] capitalize">Subjects</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">
                        {fullData?.tutorInfoId?.subYouCanTeach?.map((ele) => ele?.name).join(', ') ?? "N/A"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }


      {details && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[900px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setFullDetails(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{" "}
              Close
            </button>

            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div>
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Tutor Details</h2>
                </div>
                <div className="p-2 overflow-hidden overflow-x-auto">
                  <table className="table-auto w-full border">
                    <tr className="capitalize border-b">
                      <th className="px-4 py-2 text-left">Address</th>
                      <th className="px-4 py-2 text-left">Board</th>
                      <th className="px-4 py-2 text-left">Class Mode</th>
                      <th className="px-4 py-2 text-left">Tuition Fee</th>
                      <th className="px-4 py-2 text-left">Tutor Fee</th>
                      <th className="px-4 py-2 text-left">No Of Classes</th>
                      <th className="px-4 py-2 text-left">Class Duration</th>
                      <th className="px-4 py-2 text-left">Class Reference</th>
                      {/* <th className="px-4 py-2 text-left">Remark</th> */}
                    </tr>

                    <tbody>
                      <tr className="capitalize">
                        <td className="px-4 py-2">
                          {fullDetailsData?.address}
                        </td>
                        <td className="px-4 py-2">{fullDetailsData?.board}</td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.tutorInfoId?.teachingMode?.map(
                            (ele, idx) => (
                              <small key={idx}>{ele}</small>
                            )
                          )}
                        </td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.tuitionFee}
                        </td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.tutorFee}
                        </td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.numberOfClasses}
                        </td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.classDuration}
                        </td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.classReference}
                        </td>
                        {/* <td className="px-4 py-2">{fullDetailsData?.remark}</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}


      <UpdateTuitionModel
        handleBack={() => { setUpdateDemoModel(false); setInputChangeField({}) }}
        updateDemoModel={updateDemoModel}
        inputChangeField={inputChangeField}
        allStudentList={allStudentList}
        setInputChangeField={setInputChangeField}
        listPayload={{
          status: [3],
          keyWord: "",
          fromDate: "",
          toDate: "",
          sortOrder: "",
          sortBy: "",
          pageNo: currentPage,
          size: pageSize,
          ...filters,
          tutorId: (filters.tutorId != "") ? [filters.tutorId] : [],
          keyWord: searchText,
          cityId: headerCity ? [headerCity] : "",
        }}
      />

      {
        rejectResionModal && (

          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={handlerejctCloseModal}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Reason</h2>
                  </div>
                  <div className="relative  overflow-y-auto">
                    <form onSubmit={handleRejectResion} className="p-4">
                      <div className="mb-4">
                        <label htmlFor="skipResion" className="block text-gray-700 font-bold mb-2">Reason for Reject</label>
                        <input
                          type="text"
                          id="skipResion"
                          value={rejectResion}
                          onChange={(e) => setRejectResion(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md"
                          placeholder="Enter reason"
                        />
                      </div>
                      <div className="flex justify-center space-x-2">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

          </>

        )
      }


      <UpdateNoteModel
        remarkModal={remarkModal}
        remarkClose={remarkClose}
        Heading={"Update Note"}
        remark={remark}
        setRemark={setRemark}
        handleRemarkSubmit={handleRemarkSubmit}
        btnName={`${remark !== "N/A" || remark ? "Edit" : "Add"} Note`}
      />

      <section className="space-y-5 lg:hidden block py-6">
        {tutorAssignData && tutorAssignData.length > 0 ? (
          tutorAssignData?.map((item, index) => (
            <div key={index} className=" bg-white p-4 rounded-[10px] border border-[#023565]">
              <>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Parent Name
                    </p>
                  </div>
                  <div className="w-full flex items-start">
                    <div className="flex md:justify-start justify-center gap-2 w-[95%]">
                      <div onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                        {item?.parentId && item?.parentId?.image ?
                          <img
                            alt=""
                            src={
                              item?.parentId && item?.parentId?.image
                                ? item?.parentId?.image
                                : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            onError={(e) => {
                              e.target.src =
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                            }}
                            className="rounded  max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]"
                          />
                          : <FaRegUserCircle className="rounded max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]" />}
                      </div>
                      <div className="">
                        <p className="text-[12px] font-medium whitespace-nowrap">
                          {item?.parentId?.name ?? "Not Available"}
                        </p>
                        <p className="text-[12px] font-medium text-gray-500 whitespace-nowrap">
                          {item?.parentId?.mobNo ?? "Not Available"}
                        </p>
                        <div className="flex items-center  gap-1 cursor-pointer" onClick={() => handleFullDetails(item)} >
                          <p className="text-[12px] font-medium cursor-pointer">More</p>
                          <FaChevronCircleRight
                            className="text-[#023565] text-base cursor-pointer" />
                        </div>

                      </div>
                    </div>
                    <div className="w-[5%] relative">
                      <BsThreeDotsVertical
                        className="text-2xl text-red-600 cursor-pointer"
                        onClick={() => openAction(item._id)}
                      />
                      {open === item._id && (
                        <ul className="bg-white border text-[#023565] rounded-md w-48 absolute right-2 py-1 text-start text-sm font-bold shadow-lg" ref={mobileRef}>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => assignTutor(item)}>
                            <button className="w-full text-left">Change Tutor</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleMarkDemoDone(item)}>
                            <button className="w-full text-left">Mark Demo Done</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleSendToDemoRequest(item)}>
                            <button className="w-full text-left">Send to Demo Request</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => updateDemoModelFunction(item)}>
                            <button className="w-full text-left">View & Update Info</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleRejectMsg(item)}>
                            <button className="w-full text-left">Reject</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1   flex justify-between items-center hover:text-[#C60E6B]" onClick={() => viewHistory(item)}>
                            <button className="w-full text-left">View History</button>
                            <FaAngleRight className="text-md" />
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Student Name
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">
                      <FaEye size={24} className="  cursor-pointer  text-[#023565] hover:text-[#E4006F] " onClick={() => viewStudent(item?.parentId, item._id)} />
                    </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">Address</p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]" title={item?.address || 'N/A'}>
                      {truncateText(item?.address, 20) ?? 'N/A'} </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">City</p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]" title={item?.address || 'N/A'}>
                      {item?.cityId?.name}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">Demo Date / Time</p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">{item?.demoDate ? item?.demoDate : null} / {item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Assigned Tutor
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">{item?.tutorId?.name}</p>
                    <p className="text-[14px] font-[400]">{item?.tutorId?.mobNo}</p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Tutor Status
                    </p>
                  </div>
                  <div className="w-full">
                    <p className={`text-[14px] font-[400] ${item.processStatus === 3 ? "text-yellow-500" : item.processStatus === 4 ? "text-green-500" : item.processStatus === 5 ? "text-red-500" : "text-gray-900"}`}>{item.processStatus === 3 ? "Pending" : item.processStatus === 4 ? "Accepted" : item.processStatus === 5 ? "Rejected" : "N/A"}</p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Lead Source
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">{item.leadSource === "CREATED_SELF" ? "App" : "Admin"} / {item?.assignToEmployee ? item.assignToEmployee.userName : "N/A"}</p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">
                      Mode
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">{item?.preferredTeachMode == "ONLINE_TUITION" ? "Online" : "In-Home"} </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">Note</p>
                  </div>
                  <div className="w-full">
                    <p
                      className={`text-[14px] font-[700] ${item.reason ? "text-[#00AFE2]" : "text-[#023565]"
                        }`}
                      onClick={() => addRemark(item)}
                    >
                      {item?.reason ? "View" : "Add"}
                    </p>
                  </div>
                </div>
                {/* <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[14px] font-[600] text-left">Action</p>
                  </div>
                  <div className="w-full relative">
                    <BsThreeDotsVertical
                      className="text-2xl text-red-600 cursor-pointer"
                      onClick={() => openAction(item._id)}
                    />
                    {open === item._id && (
                      <ul className="bg-white border text-[#023565] rounded-md w-48 absolute right-8 py-1 text-start text-sm font-bold shadow-lg" ref={mobileRef}>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => assignTutor(item)}>
                          <button className="w-full text-left">Change Tutor</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleMarkDemoDone(item)}>
                          <button className="w-full text-left">Mark Demo Done</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleSendToDemoRequest(item)}>
                          <button className="w-full text-left">Send to Demo Request</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => updateDemoModelFunction(item)}>
                          <button className="w-full text-left">View & Update Info</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleRejectMsg(item)}>
                          <button className="w-full text-left">Reject</button>
                          <FaAngleRight className="text-md" />
                        </li>
                        <li className=" px-2 py-1   flex justify-between items-center hover:text-[#C60E6B]" onClick={() => viewHistory(item)}>
                          <button className="w-full text-left">View History</button>
                          <FaAngleRight className="text-md" />
                        </li>
                      </ul>
                    )}
                  </div>
                </div> */}
              </>
            </div>
          ))
        ) : (
          <p className="px-6 py-4 font-[400] bg-white rounded-md text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</p>
        )}
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      {isViewMoreModel && <ViewMoreTuitionModel isViewMoreModel={isViewMoreModel} setIsViewMoreModel={setIsViewMoreModel} tuitionId={tuitionId} />}

    </div>
  );
};

// export default ButtonThirdTabel;
const mapStateToProps = (state) => ({
  isStateMangerRes: state.tution.isStateMangerRes,
  suggestedTutorList: state.tution.suggestedTutorList,
  getAllTutorList: state.tution.getAllTutorList,
  getPincodeList: state.tution.getPincodeList,
});

const mapDispatchToProps = (dispatch) => ({
  tutionAction: () => dispatch(tutionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonThirdTabel);
