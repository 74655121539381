import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { TbUserEdit } from 'react-icons/tb'
import { timeZoneReturnDateType } from '../../../_helpers'

function TableBanner({ banner, handleChange, handleEdit, deleteUser, totalPages, currentPage, handlePreviousPage, handleNextPage }) {
  return (
    <section className="py-6  md:block hidden">
      <div className="rounded-lg bg-white overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-4 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Image
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Date
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Type
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Status
              </th>
              <th className="px-2 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Update
              </th>
              <th className="px-2 py-3 text-left text-xs font-bold text-[#313131] table_head tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {banner?.bannerGetList?.length > 0 ? (banner?.bannerGetList?.map((item, id) => (
              <tr key={id} className='capitalize'>
                <td className="px-4 py-4 whitespace-nowrap forfontsmall">
                  {item.name}
                </td>
                <td className="px-4 py-4 whitespace-nowrap forfontsmall">
                  <img
                    className="h-14 w-14 rounded"
                    src={item.image}
                    alt={item.name}
                  />
                </td>
                <td className="px-4 py-4 whitespace-nowrap forfontsmall">
                  {timeZoneReturnDateType(item?.createdAt)}
                </td>
                <td className="px-4 py-4 whitespace-nowrap forfontsmall">
                  {item.userType}
                </td>
                <td
                  className="px-4 py-4 whitespace-nowrap cursor-pointer forfontsmall"
                  title={item.isDisable ? "Enable" : "Disable"}
                >
                  <label className="inline-flex items-center cursor-pointer" >
                    <input
                      type="checkbox"
                      className="sr-only peer"

                      checked={item.isDisable}
                      onChange={() => handleChange(item._id)}
                    />
                    {item.isDisable ?
                      <div class="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      : 
                      <div class="relative w-11 h-6 bg-red-500 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>

                    }


                  </label>
                </td>
                <td className="px-2 py-4 whitespace-nowrap forfontsmall">
                  <button onClick={() => handleEdit(item)}>
                    <TbUserEdit className="text-black text-2xl" />
                  </button>
                </td>
                <td className="px-2 py-4 whitespace-nowrap forfontsmall ">
                  <button
                    onClick={() => deleteUser(item._id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <img src="/delete.png" className="cursor-pointer mx-auto" alt="" />
                  </button>
                </td>
              </tr>
            ))

            ) : (
              <tr>
                <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-center">
                  Data Not Available
                </td>
              </tr>
            )

            }
          </tbody>
        </table>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
            </span>
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default TableBanner
