import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { parentConstant } from "../_constants";
import { alertActions } from "./alert.actions";

export const parentAction = {
    getUserList,
    getStudentListByParentId,
    getOnGoingTutionList,
    getFeeHistoryByUserId,
    blockByAdmin,
    updateParentByAdmin,
    getLogHistoryListByUserId,
    getStudentListByParentIdUser,
    getOnGoingTutionListPromise,
    getStudentListByParentIdPromise,
    getStudentListByParentIdForDemoPromise,
    getStudentListByParentIdAndDemoIdPromise,
    addEditStudentByAdminPromise,
}

async function addEditStudentByAdminPromise(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/createStudentByAdmin"
    };
    return await APIcallFunction(credentials)
};

function getUserList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_USERLIST_REQUEST } }
    function success(users) { return { type: parentConstant.GET_USERLIST_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_USERLIST_FAILURE, error } }


};

function getStudentListByParentId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getStudentListByParentId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENTID_REQUEST } }
    function success(users) { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENTID_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENTID_FAILURE, error } }


};


async function getStudentListByParentIdPromise(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getStudentListByParentId"
    };
    return await APIcallFunction(credentials)
};

async function getStudentListByParentIdAndDemoIdPromise(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getStudentListByParentIdAndDemoId"
    };
    return await APIcallFunction(credentials)
};

async function getStudentListByParentIdForDemoPromise(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getStudentListByParentIdForDemo"
    };
    return await APIcallFunction(credentials)
};



function getStudentListByParentIdUser(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getStudentListByParentId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_REQUEST } }
    function success(users) { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_FAILURE, error } }


};

function getOnGoingTutionList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getOnGoingTuitionListByUserId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_ONGOING_TUTION_LIST_BYID_REQUEST } }
    function success(users) { return { type: parentConstant.GET_ONGOING_TUTION_LIST_BYID_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_ONGOING_TUTION_LIST_BYID_FAILURE, error } }


};

function getOnGoingTutionListPromise(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getOnGoingTuitionListByUserId"
    };
    return APIcallFunction(credentials)
};

function getFeeHistoryByUserId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "feeHistory/getFeeHistoryByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_FEE_HISTORY_BYUSERID_REQUEST } }
    function success(users) { return { type: parentConstant.GET_FEE_HISTORY_BYUSERID_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_FEE_HISTORY_BYUSERID_FAILURE, error } }


};

function blockByAdmin(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/blockByAdmin "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.BLOCK_BY_ADMIN_REQUEST } }
    function success(users) { return { type: parentConstant.BLOCK_BY_ADMIN_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.BLOCK_BY_ADMIN_FAILURE, error } }


};


function updateParentByAdmin(data, parentUserList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/updateParentByAdmin "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(alertActions.success("Successfully updated."))
                dispatch(getUserList(parentUserList))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.UPDATE_PARENT_BYADMIN_REQUEST } }
    function success(users) { return { type: parentConstant.UPDATE_PARENT_BYADMIN_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.UPDATE_PARENT_BYADMIN_FAILURE, error } }


};



function getLogHistoryListByUserId(data, parentUserList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getLogHistoryListByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(parentUserList))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: parentConstant.GET_LOG_HISTORY_BYID_REQUEST } }
    function success(users) { return { type: parentConstant.GET_LOG_HISTORY_BYID_SUCCESS, users } }
    function failure(error) { return { type: parentConstant.GET_LOG_HISTORY_BYID_FAILURE, error } }


};