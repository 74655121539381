import React from 'react';

const DateRangePicker = ({ fromDate, toDate, handleChange }) => {

  return (
    <>
      <div className=" space-x-1">
        <label htmlFor="formDate">From Date</label>
        <input
          type="date"
          id="formDate"
          value={fromDate}
          onChange={(e) => handleChange(e.target.value, 'fromDate')}
          className="px-2 py-2 bg-white border text-black rounded-[6px] text-sm placeholder-dark uppercase"
          max={toDate || undefined}
        />
      </div>
      <div className=" space-x-1">
        <label htmlFor="toDate">To Date</label>
        <input
          type="date"
          value={toDate}
          onChange={(e) => handleChange(e.target.value, 'toDate')}
          className="px-2 py-2 bg-white border text-black rounded-[6px] text-sm placeholder-dark uppercase"
          min={fromDate || undefined}

        />
      </div>
    </>
  );
};

export default DateRangePicker;
