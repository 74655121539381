import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import Holidays from './Holidays';
import moment from 'moment';
import HolidaysCalenderHeader from './HolidaysCalenderHeader';
import { holidaysAction } from '../../_actions';
const HolidayCalender = () => {
  const [active, setActive] = useState("Calender");
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    let holidaysData = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(holidaysAction.holidaysGet(holidaysData));
  }, [searchText]);
  const [dateStampNew, setDateStamp] = useState(moment().startOf('month').valueOf())

  const handleNavigate = (date) => {
    const dateStamp = moment(date).startOf('month').valueOf();
    setDateStamp(() => dateStamp)
    let holidaysData = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
      "currentDate": dateStamp,
    };
    dispatch(holidaysAction.holidaysGet(holidaysData));

  };

  const newFunction = () => {
    let dataRes = moment(dateStampNew).startOf('month').valueOf()
    let holidaysData = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
      "calenderData": dataRes
    };
    dispatch(holidaysAction.holidaysGet(holidaysData));
  }
  return (
    <>
      <HolidaysCalenderHeader handleSearch={handleSearch} searchText={searchText} />
      <div className='md:flex justify-end place-items-center gap-5 mx-5 pt-2'>
        <input
          type="month"
          className="py-1.5 px-2 bg-white text-black rounded-lg shadow"
          value={moment(dateStampNew).format('YYYY-MM')}
          onChange={(e) => {
            e.target.value != "" ? handleNavigate(e.target.value) : handleNavigate(moment().startOf('month').format('YYYY-MM'))
          }}
        />

      </div>
      <div className='m-5 overflow-hidden overflow-y-auto'>

        {active === "Calender" && <Holidays
          handleNavigate={(date, view) => handleNavigate(date, view)}
          newFunction={newFunction}
          dateStampNew={dateStampNew}
        />}

      </div>
    </>
  )
}

export default HolidayCalender
