import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, bannerActions, notificationAction } from "../../_actions";
import "./Banner.css";
import Loader from "../../components/Loader";
import { confirmAlert } from "react-confirm-alert";
import NotificationHeader from "./NotificationHeader";
import TableBanner from "./tables/FullTable";
import MobileTable from "./tables/MobileTable";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from "react-router";

const NotificationList = () => {
  const [searchText, setSearchText] = useState("");
  const [currentPages, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const bannersItem = useSelector((state) => state);
  const { banner } = bannersItem;
  const selector = useSelector(state => state)
  const [getAdminNotification, setGetAdminNotificatioion] = useState('')
  const [highlightedRow, setHighlightedRow] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const details = {

      "keyWord": "",
      "pageNo": currentPages,
      "size": pageSize


    }
    dispatch(notificationAction.adminNotificationsList(details))
  }, [currentPages]);

  useEffect(() => {
    if (selector?.notificationList?.getAdminNotificationList?.data?.total) {
      setTotalPages(Math.ceil(selector?.notificationList?.getAdminNotificationList?.data?.total / pageSize));
    }
    setGetAdminNotificatioion(selector?.notificationList?.getAdminNotificationList?.data?.list)
  }, [bannersItem]);

  const handlePreviousPage = () => {
    if (currentPages > 1) setCurrentPages(currentPages - 1);
  };

  const handleNextPage = () => {
    if (currentPages < totalPages) setCurrentPages(currentPages + 1);
  };

  const handleChange = (id) => {
    const bannerSet = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    const actionData = {
      id
    };
    dispatch(bannerActions.bannerEnaDis(actionData, bannerSet));
  }


  const handleSearch = (e) => {
    setCurrentPages(1);
    setSearchText(e.target.value);
    const bannerSet = {
      keyWord: e.target.value,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPages,
      size: pageSize,
    };
    setTimeout(() => {
      dispatch(bannerActions.BannerGet(bannerSet));

    }, 500)
  };

  let handleRead = (data) => {
    if (data.isRed) {
      navigate(`/app/${data.redirectUrl}`)
    } else {
      notificationAction.readNotificationsByAdminByPromise({
        id: data?._id
      }).then((res) => {
        navigate(`/app/${data.redirectUrl}`)
      }).catch(err => {
        dispatch(alertActions.error("Please try again later."))
      })
      // const refresh = {
      //   "keyWord": "",
      //   "pageNo": currentPages,
      //   "size": pageSize

      // };
      // const details = {
      //   id: data?._id
      // }
      // dispatch(notificationAction.readNotificationsByAdmin(details, refresh))
    }

  }

  return (
    <>
      <NotificationHeader
        handleSearch={handleSearch}
        searchText={searchText}

      />

      <section className="overflow-hidden  overflow-y-auto">
        <Loader loading={selector?.notificationList?.loading} />
        <div className="m-5">
          <TableBanner
            getAdminNotification={getAdminNotification}
            highlightedRow={highlightedRow}
            handleChange={(e) => handleChange(e)}
            totalPages={totalPages}
            pageSize={pageSize}
            currentPages={currentPages}
            handleRead={handleRead}
            handlePreviousPage={(e) => handlePreviousPage(e)}
            handleNextPage={(e) => handleNextPage(e)}
          />

          <MobileTable
            banner={banner}
          />
        </div>
      </section>
    </>
  );
};


export default NotificationList;

