import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import EarningTable from "./EarningTable";
import "./Earning.css";
import EarningHeader from "./EarningHeader/EarningHeader";
import { feeAction } from "../../../_actions/fee.action";
import moment from "moment";
import Loader from "../../../components/Loader";
import { FaAngleDown } from "react-icons/fa";

const Earning = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("");
  const selector = useSelector(state => state);
  const [feeData, setFeeData] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"))
  const [headerCityId, setHeaderCityId] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")

  useEffect(() => {
    let tutionEarning = {
      cityId: headerCityId != "" ? [headerCityId] : "",
    };
    if (currentMonth) {
      let momentDate = moment(currentMonth, "YYYY-MM");
      let timestamp = momentDate.valueOf();
      tutionEarning["month"] = timestamp
    }
    dispatch(feeAction.getTutionEarning(tutionEarning));

  }, [currentMonth, headerCityId])
  const handleSelectCity = (e) => {
    let cityId = e.target.value
    let tutionEarning = {
      cityId: [cityId],
    };
    dispatch(feeAction.getTutionEarning(tutionEarning));
  }


  const handleDateSearch = (e) => {
    // let dateID = e.target.value != "" ? e.target.value : moment().format("YYYY-MM")
    let dateID = e.target.value != "" ? e.target.value : ""
    setCurrentMonth(dateID)
  }

  useEffect(() => {
    setFeeData(selector?.Fee?.tutionList?.data ?? null)
  }, [selector])

  let totalRevenue =
    feeData?.reduce((acc, item) => acc + item.Revenue, 0) || 0;
  let totalFeePaid =
    feeData?.reduce((acc, item) => acc + item?.feePaid, 0) || 0;
  let totalFeePainding =
    feeData?.reduce((acc, item) => acc + item?.feePending, 0) || 0;
  let totalRefrencePaid =
    feeData?.reduce((acc, item) => acc + item?.referenceFee, 0) || 0;
  let totalRefrailPaid =
    feeData?.reduce((acc, item) => acc + item?.referralPaid, 0) || 0;
  let totalFeeRefund =
    feeData?.reduce((acc, item) => acc + item?.refundTutorFee, 0) || 0;
  let totalFeeRecived =
    feeData?.reduce((acc, item) => acc + item?.feeReceived, 0) || 0;


  return (
    <>
      <EarningHeader handleSelectCity={handleSelectCity} handleDateSearch={handleDateSearch} searchText={searchText} currentMonth={currentMonth} headerCityId={headerCityId} setHeaderCityId={setHeaderCityId} roleId={roleId} />
      <Loader loading={selector?.Fee?.loading} />

      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5 overflow-hidden overflow-y-auto ">
          <div className="pb-4 lg:flex justify-between items-center lg:hidden">
            <div>
              <h2 className="text-[17px] font-[700] text-[#000000]">Earning</h2>
            </div>
            <div className="flex items-center gap-6">
              <div className="w-full">
                <input
                  type="month"
                  onChange={handleDateSearch}
                  className="py-1.5 w-full px-2 bg-white border-2 text-black rounded-lg shadow appearance-none"
                  value={currentMonth}
                  style={{ color: currentMonth ? 'black' : 'transparent' }}
                />
              </div>
              <div className="w-full">
                <select className=" capitalize bg-white border-2 text-[#000] rounded-lg shadow  w-full px-4 py-2"
                  value={headerCityId}
                  onChange={(e) => { setHeaderCityId(e.target.value) }}
                  disabled={roleId !== 1 ? true : false}
                >
                  <option className="text-black align-middle" value={""}>
                    {" "}
                    All Cities
                  </option>
                  {selector?.Citys?.cityGetList?.map((city, id) => (
                    <option className="text-black capitalize" value={city._id} key={id}>
                      {" "}
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12  gap-4 forMobile_view">
            <div className=" col-span-3 bg-white rounded-3xl py-4 border ">
              <p className="text-center text-[#023565]">Total Fee Received</p>
              <p className="text-center table_head ">{totalFeeRecived}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Fee Paid</p>
              <p className="text-center table_head">{totalFeePaid}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Fee Pending</p>
              <p className="text-center table_head">{totalFeePainding}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Reference Paid</p>
              <p className="text-center table_head">{totalRefrencePaid}</p>
            </div>
          </div>

          <div className="grid grid-cols-12 mt-4 gap-4 forMobile_view">
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Referral Paid</p>
              <p className="text-center table_head ">{totalRefrailPaid}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Fee Refund</p>
              <p className="text-center table_head">{totalFeeRefund}</p>
            </div>
            <div className=" col-span-6 bg-white rounded-3xl py-4 border border-[#C60E6B]">
              <div className="flex place-items-center justify-between  h-full">
                <p className=" ps-10 text-[#C60E6B]">Total Revenue</p>
                <p
                  className="text-[#C60E6B] table_head pe-10 "
                  style={{ fontSize: "18px" }}
                >
                  {totalRevenue}
                </p>
              </div>
            </div>


          </div>
        </div>
        <div className=" m-5">
          <EarningTable feeData={feeData} />
        </div>
      </section>
    </>
  );
};

export default Earning;
