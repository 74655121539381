import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSettingAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAdd, MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Loader from "../../components/Loader";
import { timeZoneReturnDateType } from "../../_helpers";

const AppSettingComponent = (props) => {
  const {searchText} = props;
  const dispatch = useDispatch();
  const selector = useSelector(state => state);

  let [subjectList, setSubjectList] = useState([]);
  let [appSettingList, setAppSettingList] = useState([]);
  let [appSettingTotal, setAppSettingTotal] = useState(0);

  const [classModal, setAppSettingModal] = useState(false);
  const [actionAppSettingId, setActionAppSettingId] = useState(null);
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [classEditFormModal, setAppSettingEditFormModal] = useState(false);
  const [newEditAppSettingName, setNewEditAppSettingName] = useState({
    name: "",
    key: "",
    value: "",
    _id: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActionAppSettingId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [selectedValues, setSelectedValues] = useState([]);
  // const options = subjectList?.map((item, id) => ({ name: item.name, id: item._id }));


  useEffect(() => {
    const AppSettingList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(appSettingAction.appSettingGet(AppSettingList));
  }, [currentPage,searchText]);

  const DeleteAppSetting = (id) => {
    const resAppSettingList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: 10,
    };
    const DeleteId = { id };

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(appSettingAction.appSettingDelete(DeleteId, resAppSettingList))
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const classCreateFunction = () => {
    setAppSettingModal(true);
  };

  useEffect(() => {
    setSubjectList(selector?.Subject?.SubjectList)
    setAppSettingList(selector?.AppSetting?.appSettingList?.list)
    setAppSettingTotal(selector?.AppSetting?.appSettingList?.total)
    // setAppSettingLoader(selector?.AppSetting?.appSettingList?.loading)
  }, [selector])

  const handleAppSettingForm = (event) => {
    event.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const AppSettingList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: 10,
    };

    const classCreateName = { name, key, value };
    dispatch(appSettingAction.createAppSetting(classCreateName, AppSettingList));
    setAppSettingModal(false);
    closeModal();
  };

  const handleAppSettingEditForm = (data) => {
    setAppSettingEditFormModal(true);
    setNewEditAppSettingName({
      name: data.name,
      key: data.key,
      value: data.value,
      _id: data._id,
    });
  };

  const handleEditAppSetting = (event) => {
    const { name, value } = event.target;
    setNewEditAppSettingName({
      ...newEditAppSettingName,
      [name]: value,  // Dynamically set the property based on the input's name attribute
    });
  };

  const handleAppSettingEditFormSubmit = () => {
    const AppSettingList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: 10,
    };

    const editAppSettingData = {
      id: newEditAppSettingName._id,
      name: newEditAppSettingName.name,
      key: newEditAppSettingName.key,
      value: newEditAppSettingName.value,
    };

    dispatch(appSettingAction.updateAppSetting(editAppSettingData, AppSettingList));
    setAppSettingEditFormModal(false);
  };

  const handleAppSettingAction = (item) => {
    const AppSettingList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };
    const EnaDis = { id: item._id };

    confirmAlert({
      title: `Confirm to ${item?.isDisable === false ? 'Inactive' : 'Active'}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(appSettingAction.EnaDisAppSetting(EnaDis, AppSettingList))
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const handleAction = (cityId) => {
    setActionAppSettingId((prevCityId) => (prevCityId === cityId ? null : cityId));
  };

  const closeModal = () => {
    setAppSettingModal(false);
    setName("");
    setKey("");
    setValue("");
    setSelectedValues([]);
    setErrorData({})
  };

  useEffect(() => {
    if (selector?.AppSetting?.appSettingTotal) {
      setTotalPages(Math.ceil(selector?.AppSetting?.appSettingTotal / pageSize));
    }
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const [errorData, setErrorData] = useState({})
  const handleValidation = () => {
    const errors = {};
    if (!name) {
      errors["name"] = "Name is required";
    }
    if (!key) {
      errors["key"] = "Key is required";
    }

    if (!value) {
      errors["value"] = "Value is required";
    }
    // Add more validation checks as needed

    setErrorData(errors);

    return Object.keys(errors).length === 0;
  };



  return (
    <>
      <div className="flex justify-end md:-mt-14 mx-6">
        <div className="flex justify-between place-items-center">
          <span
            className="bg-white rounded-lg text-[#C60E6B] font-semibold px-4 py-2 mb-2 cursor-pointer flex justify-between items-center space-x-1"
            onClick={classCreateFunction}
          >
            <MdOutlineAdd className="mx-2" /> Add New
          </span>
        </div>
      </div>
      <section className="overflow-hidden overflow-y-auto">
        <Loader loading={selector?.AppSetting?.loading} />
        <div className="m-5">

          <div className="border-2 border-[#D3D3D3] bg-white my-4 mt-1 rounded-md md:block hidden">
            <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-left text-gray-500">
                <thead className="text-[#313131] bg-white border-b">
                  <tr>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">S.No</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Name</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Key</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Value</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Status</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Date</th>
                    <th className="xl:px-6 px-4 py-3 whitespace-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appSettingList && appSettingList.length > 0 ? (
                    appSettingList.map((item, id) => (
                      <tr key={id} className="border-b hover:bg-gray-50 capitalize">
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {(currentPage - 1) * pageSize + id + 1}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {item?.name ?? null}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {item?.key ?? null}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {item?.value ?? null}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {item.isDisable === false ? (
                            <span className="text-[#11C278] font-semibold">Active</span>
                          ) : (
                            <span className="text-[#E70707] font-semibold">Inactive</span>
                          )}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900">
                          {timeZoneReturnDateType(item?.createdAt)}
                        </td>
                        <td className="xl:px-6 px-4 py-3 whitespace-nowrap font-medium text-gray-900 relative">
                          <BsThreeDotsVertical onClick={() => handleAction(item._id)} />
                          {actionAppSettingId === item._id && (
                            <div className="absolute right-0 z-10 mt-2 w-48 bg-white border rounded shadow-lg">
                              <ul className="py-1 text-sm text-gray-700" aria-labelledby="options-menu" ref={menuRef} >
                                <li className="flex justify-between items-center text-[#023565] font-semibold cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleAppSettingAction(item)}>
                                  <span>
                                    {item.isDisable ? "Activate" : "InActive"}
                                  </span>
                                  <img src="/Group 1000001172.png" alt="icon" />
                                </li>
                                <li className="flex justify-between place-items-center text-[#023565] border-b font-semibold cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleAppSettingEditForm(item)}>
                                  <div className="p">View & Update</div>
                                  <img src="/Group 1000001230.png" alt="icon" />
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center text-gray-500 py-4">
                        Data Not Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2">
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                </span>
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>

          <div className="md:hidden">
            {appSettingList && appSettingList.length > 0 ? (
              appSettingList.map((item, id) => (
                <div key={id} className="bg-white shadow-md p-4 mb-4 rounded-md">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-semibold">Name: {item.name}</p>
                      <p className="font-semibold">
                        {/* Subject: {item.subjectId.map((subject, index) => (
                      <span key={index} className="mr-2">{subject.name}</span>
                    ))} */}
                      </p>
                      <p className="font-semibold">
                        Status: {item.isDisable === false ? (
                          <span className="text-[#11C278] font-semibold">Active</span>
                        ) : (
                          <span className="text-[#E70707] font-semibold">Inactive</span>
                        )}
                      </p>
                      <p className="font-semibold">Create Date: {timeZoneReturnDateType(item?.createdAt)}</p>
                    </div>
                    <div className="relative">
                      <BsThreeDotsVertical onClick={() => handleAction(item._id)} />
                      {actionAppSettingId === item._id && (
                        <div className="absolute right-0 z-10 mt-2 w-48 bg-white border rounded shadow-lg">
                          <ul className="py-1 text-sm text-gray-700" aria-labelledby="options-menu">
                            <li className="flex justify-between items-center text-[#023565] font-semibold cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleAppSettingAction(item)}>
                              <span>
                                {item.isDisable ? "Activate" : "InActivate"}
                              </span>
                              <img src="/Group 1000001172.png" alt="icon" />
                            </li>
                            <li className="flex justify-between place-items-center text-[#023565] border-b font-semibold cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleAppSettingEditForm(item)}>
                              <div className="p">View & Update</div>
                              <img src="/Group 1000001230.png" alt="icon" />
                            </li>
                            <li className="flex justify-between items-center gap-3 cursor-pointer text-[#023565] font-semibold hover:bg-gray-100 p-1 rounded" onClick={() => DeleteAppSetting(item._id)}>
                              <div className="p">Remove</div>
                              <img src="/Group 1000001230.png" alt="icon" />
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-4">
                Data Not Available
              </div>
            )}
            {appSettingTotal && appSettingTotal > 0 && (
              <div className="flex justify-end items-center py-2">
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {appSettingTotal}
                </span>
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === appSettingTotal ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === appSettingTotal}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>

        {classModal && (
          <>
            {/* <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg w-1/3">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold">Create App Setting</h3>
                  <MdOutlineClose className="cursor-pointer" onClick={closeModal} />
                </div>
                <form onSubmit={handleAppSettingForm}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errorData && errorData["name"] && <p className="text-red-500 text-sm">{errorData["name"]}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Key</label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={key}
                      onChange={(e) => setKey(e.target.value)}
                    />
                    {errorData && errorData["key"] && <p className="text-red-500 text-sm">{errorData["key"]}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    {errorData && errorData["value"] && <p className="text-red-500 text-sm">{errorData["value"]}</p>}
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div> */}

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[500px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={closeModal}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Create App Setting </h2>
                  </div>
                  <div className="relative lg:h-[350px] overflow-y-auto">
                    <form onSubmit={handleAppSettingForm} className="p-6">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                        <input
                          type="text"
                          placeholder="Name"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errorData && errorData["name"] && <p className="text-red-500 text-sm">{errorData["name"]}</p>}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Key</label>
                        <input
                          type="text"
                          placeholder="Key"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                        {errorData && errorData["key"] && <p className="text-red-500 text-sm">{errorData["key"]}</p>}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                        <input
                          placeholder="Value"
                          type="text"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                        {errorData && errorData["value"] && <p className="text-red-500 text-sm">{errorData["value"]}</p>}
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {classEditFormModal && (
          <>
            {/* <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg w-1/3">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold">Edit App Setting</h3>
                  <MdOutlineClose className="cursor-pointer" onClick={() => setAppSettingEditFormModal(false)} />
                </div>
                <form onSubmit={handleAppSettingEditFormSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={newEditAppSettingName.name}
                      onChange={handleEditAppSetting}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Key</label>
                    <input
                      type="text"
                      name="key"
                      id="key"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={newEditAppSettingName.key}
                      onChange={handleEditAppSetting}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                    <input
                      type="text"
                      name="value"
                      id="value"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={newEditAppSettingName.value}
                      onChange={handleEditAppSetting}
                      required
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2"
                      onClick={() => setAppSettingEditFormModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div> */}


            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[500px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setAppSettingEditFormModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Edit App Setting </h2>
                  </div>
                  <div className="relative lg:h-[350px] overflow-y-auto">
                    <form onSubmit={handleAppSettingEditFormSubmit} className="p-6">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={newEditAppSettingName.name}
                          onChange={handleEditAppSetting}
                          required
                          // disabled
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Key</label>
                        <input
                          type="text"
                          name="key"
                          id="key"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={newEditAppSettingName.key}
                          onChange={handleEditAppSetting}
                          required
                          disabled
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                        <input
                          type="text"
                          name="value"
                          id="value"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={newEditAppSettingName.value}
                          onChange={handleEditAppSetting}
                          required
                        />
                      </div>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default AppSettingComponent;
