/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parentAction, tutortoliveAction, userListAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleRight, FaChevronCircleRight, FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import "./Tutor.css";
import { tutionAction } from "../../_actions";
import Loader from "../../components/Loader";
import ImagePreviewModal from "./Model/ImagePreview";
import KycViewModel from "./Model/KycViewModel";
import DetailsPreViewModel from "./Model/DetailsPreViewModel";
import OnGoingTuitionCheck from "./Model/OnGoingTuitionCheck";
import BlockPopUp from "./Model/BlockPopUp";
import ViewAndUpdate from "./Model/ViewAndUpdate";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import { teachModeViewData } from "../../_helpers";
import RatingModel from "./Model/RatingModel";
import FilterComponent from "./Model/FilterComponent";
import { MdOutlineClose } from "react-icons/md";
import OngoingBatchView from "./Model/OngoingBatchView";
import moment from "moment"



const ActiveTutor = ({ selectedCities }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((state) => state);
  const [userList, setuserList] = useState();
  const [kycListData, setKycListData] = useState();
  const [onGoingTutuionListById, setOnGoingTutuionListById] = useState();
  const [ratingList, setRatingList] = useState();
  const [kycDataShow, setKycDataShow] = useState(false);
  const [onGoingTuitionList, setOnGoingTutionList] = useState(false);
  const [fullData, setFullData] = useState('')
  const [activeItem, setActiveItem] = useState(null);
  const [blockedPopUp, setBlockPoPUp] = useState(false);
  const [name, setName] = useState("");
  const [blockedData, setNewBlockedData] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [allPincodeList, setAllPincodeList] = useState("");
  const [allClassListByAdminData, setAllClassListByAdminData] = useState([]);
  const [subjectListData, setSubjectListData] = useState()
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState);
  const [selectedTeachMode, setSelectedTeachMode] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState([]);
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [filters, setFilters] = useState({
    pinCode: '',
    gender: '',
    classId: '',
    subjectId: '',
    teachingMode: ''
  });
  const menuRef = useRef(null);
  const mobileRef = useRef(null)
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setActiveItem(null);
    }
  };

  const [feepaymentModal, setFeePaymentModal] = useState(false);
  const [feePaymentListdata, setFeePaymentListData] = useState();
  const [isFetching, setIsFetching] = useState(false)


  const handleFeeHistory = (data) => {
    setFeePaymentModal(true);
    let feeDetails = {
      userId: data._id,
    };
    dispatch(parentAction.getFeeHistoryByUserId(feeDetails));
  };




  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    let resData = {
      status: "ACTIVE",
      userType: "TUTOR",
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };

    if (selectedCities != "") {
      resData["cityId"] = [selectedCities]
    }

    dispatch(userListAction.getUserList(resData));
    dispatch(tutionAction.gitAllClassListByAdmin());
    dispatch(userListAction.gitAllSubjectListBySelectedData())

  }, [currentPage, selectedCities]);

  useEffect(() => {
    if (selector?.userList?.userList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.userList?.userList?.data?.total / pageSize)
      );
    }
    setuserList(() => selector?.userList?.userList?.data?.demoList);
    // setOnGoingTutuionListById(selector?.userList?.getOnGoingTutionList?.data);
    setKycListData(selector?.userList?.userKycListByUserId?.data);
    setRatingList(selector?.userList?.getRatingList?.data);
    // setAllPincodeList(selector?.tution?.getPincodeList?.data || "");
    setAllClassListByAdminData(selector?.tution?.getAllClassList?.data);
    setSubjectListData(selector?.userList?.getAllSubjectList?.data)
    setFeePaymentListData(selector?.Parent?.getFeeHistoryById?.data)
    setAllPincodeList(selector?.Citys?.cityGetList?.find(city => city._id === selectedCities)?.pinCode ?? []);

  }, [selector]);



  useEffect(() => {
    setAllPincodeList(selector?.Citys?.cityGetList?.find(city => city._id === selectedCities)?.pinCode ?? []);
  }, [selectedCities])



  const closeModal = () => {
    setAcceptModal(false);
  };


  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };



  const kycDetailModal = (data) => {


    let kycData = {
      id: data?._id,
    };
    setKycDataShow(true);
    dispatch(userListAction.getKycByUserId(kycData));

  };


  const handleFeePayment = (item) => {
    navigate(`/app/feepayment`, { state: { tutorData: item } });
  };
  const handleRating = (data) => {
    setRatingModal(true);

    let ratingId = {
      userId: data._id,
    };

    dispatch(userListAction.getRatingByuserId(ratingId));
  };

  const handleAction = (resDataId) => {
    setActiveItem((prevresDataId) =>
      prevresDataId === resDataId ? null : resDataId
    );
  };


  const handleOnGoingTuitions = (data) => {
    let tutorIdData = {
      tutorId: data._id,
    };
    setOnGoingTutionList(true);
    setIsFetching(true)
    parentAction.getOnGoingTutionListPromise(tutorIdData).then((res) => {
      setOnGoingTutuionListById(res.data)
      setIsFetching(false)
    }).catch(err => {
      setIsFetching(false)

    })
  };

  ///Bloced Tutor---------------------------------->
  const [nameBlock, setNameBlock] = useState("")
  const handleBlockedTutor = (data) => {
    setBlockPoPUp(true)
    let blockedData = {
      status: "BLOCK",
      id: data._id,
    };
    setNameBlock(data?.name)
    setNewBlockedData(blockedData);
  };

  const handleSubmit = () => {
    let resData = {
      status: "ACTIVE",
      userType: "TUTOR",
      // 'TUTOR':'PARENT',
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    let blockednEWData = {
      ...blockedData,
      reason: name,
    };
    if (selectedCities != "") {
      resData["cityId"] = [selectedCities]
    }

    dispatch(userListAction.blockByAdmin(blockednEWData, resData));
    setBlockPoPUp(false);
    setActiveItem(null);
    setName("");
  };


  const handleUpdateTutor = async (user) => {
    tutortoliveAction.getTutorByPromiseId({
      id: user._id
    }).then(async (data) => {
      let user = data.data
      setNewAcceptData({
        _id: user._id,
        name: user?.name,
        email: user?.email,
        whatsappNo: user?.whatsappNo,
        address: user?.address,
        city: user?.city,
        pinCode: user?.pinCode,
        dob: user?.tutorInfoId?.dob,
        gender: user?.tutorInfoId?.gender,
        schoolName: user?.tutorInfoId?.schoolName,
        schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
        ugCourse: user?.tutorInfoId?.ugCourse,
        ugCollegeName: user?.tutorInfoId?.ugCollegeName,
        ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
        pgCourse: user?.tutorInfoId?.pgCourse,
        pgCollegeName: user?.tutorInfoId?.pgCollegeName,
        pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
        teachingExp: user?.tutorInfoId?.teachingExp,
      })
      setSelectedTeachMode(user?.tutorInfoId?.teachingMode.map((ele) => ({ name: teachModeViewData(ele), _id: ele })))
      setSelectedPinCode(user?.tutorInfoId?.pinCodeYouCanGo?.map((ele) => ({ name: ele, _id: ele })) || [])
      setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
      setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
      setAcceptModal((prev) => !prev);
    })

  }






  const handleViewHistory = (data) => {
    navigate(`/app/tutorviewhistory/${data._id}`);
  };

  ////Inactive tutor===============>







  const handleFilterSearchSearch = (data) => {
    let resData = {
      status: "ACTIVE",
      userType: "TUTOR",
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      ...data
    };
    if (selectedCities != "") {
      resData["cityId"] = [selectedCities]
    }
    setTimeout(() => {
      dispatch(userListAction.getUserList(resData));
    }, 500)
  }


  const [visibleDetails, setVisibleDetails] = useState({});

  const toggleDetails = (id) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };



  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };

  const [isModalDetails, setIsModalDetails] = useState(false);
  const handelDetailsModel = (data) => {
    if (data) {
      let tutorId = {
        id: data._id
      }
      tutortoliveAction.getTutorByPromiseId(tutorId).then((response) => {
        if (response.data) {
          setFullData(response.data)
          setIsModalDetails(!isModalDetails)
        }
      })
    } else {
      setFullData({})
      setIsModalDetails(!isModalDetails)
    }
  }

  return (
    <section className="">
      <Loader loading={selector?.userList?.loading} />

      <FilterComponent allClassListByAdminData={allClassListByAdminData} allPincodeList={allPincodeList} subjectListData={subjectListData} filters={filters} setFilters={setFilters} handleFilterSearchSearch={handleFilterSearchSearch} />


      <section className="lg:block hidden">

        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className="">
              <tr className="border-b border-[#D3D3D3]">
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">S.No.</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Tutor Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">City</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">KYC</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Ongoing Tuitions</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Fee Payment History</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Rating</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Action</th>
              </tr>
            </thead>
            <tbody className="">
              {userList && userList.length > 0 ? (
                userList?.map((item, index) => (
                  <tr className="capitalize border-b" key={index}>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center capitalize">{index + 1}</td>
                    <td className="px-4 py-4 flex items-center justify-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span onClick={item?.image ? () => handleCloseFunctionImageModel(item?.image) : null}>
                          {item?.image && item?.image ?
                            <img className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                              src={
                                item?.image}
                              alt="User"
                            /> : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.name ? (item.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">{item?.name ?? "N/A"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "No Contact"}</p>
                          <div className="flex items-center gap-1 cursor-pointer pt-1" onClick={() => handelDetailsModel(item)}>
                            <p className="text-base font-normal cursor-pointer">More</p>
                            <FaChevronCircleRight
                              className="text-[#023565] text-base cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center capitalize">{item?.city?.name ?? "No City"}</td>
                    <td className="px-4 py-4 text-sm text-[#023565]">
                      <span className="text-center flex items-center justify-center font-semibold cursor-pointer underline" onClick={() => kycDetailModal(item)}>View</span>
                    </td>
                    <td className="px-4 py-4 text-sm text-[#023565]">
                      <span className="text-center flex items-center justify-center font-semibold cursor-pointer underline" onClick={() => handleOnGoingTuitions(item)}>View</span>
                    </td>
                    <td className="px-4 py-4 text-sm text-[#023565]">
                      <span className="text-center flex items-center justify-center font-semibold cursor-pointer underline" onClick={() => handleFeeHistory(item)}>View</span>
                    </td>

                    <td className="px-4 py-4 text-sm">
                      <span className="text-center text-[#C60E6B] flex items-center justify-center">{item && item?.rating ? item.rating.toFixed(2) : "0"}</span>
                      <span className="text-center flex items-center justify-center font-semibold text-sm text-[#023565] mt-0.5 cursor-pointer underline" onClick={() => handleRating(item)}>View</span>
                    </td>

                    <td className="px-4 py-4 text-gray-900 relative table-cell">
                      <span className="flex justify-center items-center">
                        <BsThreeDotsVertical onClick={() => handleAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                      </span>

                      {activeItem && activeItem === item._id && (
                        <div className="absolute right-16 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                          <ul className="divide-y divide-gray-300 text-[#023565]">
                            <li className="flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-t-lg" onClick={() => handleUpdateTutor(item)}>
                              <span className=" text-sm font-semibold">View & Update Info</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li
                              className="flex justify-between items-center cursor-pointer hover:bg-gray-100 p-3 hover:text-[#E4006F] "
                              onClick={() => handleBlockedTutor(item)}
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                Block Tutor
                              </span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li
                              className="flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-b-lg"
                              onClick={() => handleViewHistory(item)}
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                View History
                              </span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="table_row">
                  <td colSpan={8} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
      </section>



      <ViewAndUpdate
        closeModal={closeModal}
        newAcceptData={newAcceptData}
        acceptModal={acceptModal}
        setNewAcceptData={setNewAcceptData}
        currentPage={currentPage}
        pageSize={pageSize}
        selectedTeachMode={selectedTeachMode}
        setSelectedTeachMode={setSelectedTeachMode}
        selectedPinCode={selectedPinCode}
        setSelectedPinCode={setSelectedPinCode}
        subjectYouCanTeach={subjectYouCanTeach}
        setSubjectYouCanTeach={setSubjectYouCanTeach}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        status={"ACTIVE"}
      />

      <DetailsPreViewModel isModalOpen={isModalDetails} closeModal={handelDetailsModel} response={fullData} />

      {/* <OnGoingTuitionCheck onGoingTuitionList={onGoingTuitionList} setOnGoingTutionList={setOnGoingTutionList} onGoingTutuionListById={onGoingTutuionListById} toggleDetails={toggleDetails} visibleDetails={visibleDetails} /> */}

      <OngoingBatchView
        data={onGoingTutuionListById}
        closeModal={() => { setOnGoingTutionList(false); setOnGoingTutuionListById(null) }}
        open={onGoingTuitionList}
        isFetching={isFetching}
      />
      <BlockPopUp blockedPoup={blockedPopUp} setBlockpouUp={setBlockPoPUp} name={name} setName={setName} handleSubmit={handleSubmit} nameBlock={nameBlock} />

      <RatingModel
        ratingList={ratingList}
        ratingModal={ratingModal}
        setRatingModal={setRatingModal}
      />
      <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />
      <KycViewModel kycDataShow={kycDataShow} setKycDataShow={setKycDataShow} kycListData={kycListData} />
      <section className="space-y-5 py-6 lg:hidden block">
        {userList && userList.length > 0 ? (
          userList?.map((item, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Tutor Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handelDetailsModel(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' >{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>KYC</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => kycDetailModal(item)}>View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Ongoing Tuitions</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleOnGoingTuitions(item)}>View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Fee Payment History</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleFeePayment(item)}> View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Rating</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item && item?.rating ? item.rating.toFixed(2) : "0"}</p>
                    <p className='text-[14px] font-[400] relative' onClick={() => handleRating(item)}> View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="text-2xl text-[#C60E6B]"
                        onClick={() => handleAction(item._id)}
                      />

                      {activeItem && activeItem === item._id && (
                        <div
                          className="absolute right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                          ref={mobileRef}
                        >
                          <ul className="divide-y divide-gray-300">
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-t-lg"
                            >
                              <span
                                className="text-[#C60E6B] text-sm font-semibold"
                                onClick={() => handleUpdateTutor(item)}
                              >
                                View & Update Info
                              </span>
                              <span className="ml-auto font-semibold text-[#C60E6B]">
                                <img alt="" src="/Group 1000001172.png" />
                              </span>
                            </li>
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3"
                              onClick={() => handleBlockedTutor(item)}
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                Block Tutor
                              </span>
                              <span className="ml-auto text-blue-600">
                                <img alt="" src="/Group 1000001230.png" />

                              </span>
                            </li>
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-b-lg"
                              onClick={() => handleViewHistory(item)}
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                View History
                              </span>
                              <span className="ml-auto text-blue-600">
                                <img alt="" src="/Group 1000001230.png" />

                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      {feepaymentModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[850px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setFeePaymentModal(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Fee Payment History </h2>
                </div>
                <div className="relative lg:h-[400px] overflow-y-auto">
                  <div className="overflow-x-auto overflow-hidden p-4">
                    <table className="table_full w-full border ">
                      <thead>
                        <tr className="text-center bg-gray-100 border-b">
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Class</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Subject</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Parent Name</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Fee</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feePaymentListdata && feePaymentListdata.length > 0 ?
                          feePaymentListdata?.map((fee, id) => (
                            <tr key={id} className="border-b capitalize">
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.studentId?.studentFullName).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.classId?.name).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.subjectId?.map(sub => sub.name).join(', ')).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.parentId?.name ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.fee ?? "No Fee"}
                              </td>
                              <td
                                className={`py-2 px-4 border whitespace-nowrap ${fee?.status === 0 ? "text-red-600" : "text-blue-600"
                                  } font-bold sm:px-4 sm:py-2`}
                              >
                                {/* {fee?.status === 0 ? "Pending" : "Done"} */}
                                <p>{fee?.feeRequestDate ? 'Request Received' : 'No Request'}</p>
                                <p>{fee?.feeRequestDate ? moment(fee.feeRequestDate).format('DD MMM YYYY') : ''}</p>
                                <p>{fee?.status === 0 ? "Unpaid" : fee?.status === 1 ? "Paid" : fee?.status === 2 ? "Paid in Advance" : fee?.status === 3 ? "Refund" : fee?.status === 4 ? "No Fee this Month" : "-"}</p>
                                <p>
                                  {fee?.payDate ? `Payment date: ${moment(fee?.payDate).format('YYYY-MM-DD')}` : 'Payment date: N/A'}
                                </p>
                                <p>
                                  {fee && fee.adminId && fee.adminId.roleId && fee.adminId.roleId === 1 ? `Done by: Super Admin` : fee && fee.adminId && fee.adminId.roleId && fee.adminId.roleId !== 1 ? `Done by: Sub Admin` : fee && fee.userId && fee.userId.name ? `Done by: Parent` : null}
                                </p>
                              </td>
                            </tr>
                          )) : <p className="text-center">Data Not Found</p>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>

  );
};

export default ActiveTutor;
