/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { downloadCSV } from "../../../../_helpers";
import { tutionAction } from "../../../../_actions";
import HeaderProfileComp from "../../../../components/Header/HeaderProfileComp";
import SearchIconComponent from "../../../../components/Header/SearchIconComponent";
import HeaderHeading from "../../../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../../../components/Header/MobileSearchHeader";
import { cityAction } from "../../../../_actions/city.action";
const TuitionHeader = ({ active, handleCity, handleSearch, searchText, headerCity, roleId }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  useEffect(() => {
    if (active === "On Going tution") {

      let data = {
        pageNo: 1,
        size: 1000000000,
      }
      dispatch(tutionAction.getOnGoingTuitionList(data))
    }
    if (active === "Demo Request") {
      let demoData = {
        status: [0],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000000000000,
      };
      dispatch(tutionAction.getDemoClassListStatus(demoData));
    }
    if (active === "Tutor Assigned") {
      let listData = {
        status: [3],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 10000,
      };
      dispatch(tutionAction.getDemoClassListStatus(listData));
    }
    if (active === "on-demo") {
      let listData = {
        status: [3, 4, 5],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000000000000,
      };
      dispatch(tutionAction.getDemoClassListStatus(listData));
    }
    if (active === "demo-done") {
      let listData = {
        status: [6, 7, 8],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };
      dispatch(tutionAction.getDemoClassListStatus(listData));
    }
    if (active === "On Hold") {
      let tutionDetails = {
        "status": [12],
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: 1,
        size: 1000,
      }
      dispatch(tutionAction.getDemoClassListStatus(tutionDetails))
    }
    if (active === "Completed") {
      let tutionData = {
        status: [11],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };

      dispatch(tutionAction.getDemoClassListStatus(tutionData))
    }
    if (active === "Rejected") {
      let listData = {
        status: [2],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };
      dispatch(tutionAction.getDemoClassListStatus(listData))
    }
  }, []);

  const onGoingTuitionData = selector?.tution?.getOngoungTutionList?.data
  const InActiveData = selector?.tution?.demoList?.data?.list
  const fields = ['Parent Name', 'Mobile Number', 'Address', 'City', 'Tuition Fee', 'Reference ', 'Tutor Fee', "Company", "Remark"];
  const demoRequest = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'Date/Time ', 'Gender', "Lead Sourece", "Remark"];
  const tutorAssign = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'Address', 'Date/Time ', 'Assigned Tutor', "Remark"];

  const onDemo = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Assigned Tutor', "Remark"];

  const DemoDone = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Assigned Tutor', "Feedback ", "Remark"];

  const onHold = ['Parent Name', 'Mobile Number', 'Address', 'City ', 'Tuition Fee ', 'Reference', "Tutor’s Fee", "Company", "Hold On", "Remark"];

  const completed = ['Parent Name', 'Mobile Number', 'Address', 'City ', 'Tuition Fee ', 'Reference', "Tutor’s Fee", "Company", "Remark"];

  const rejected = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Gender', "Remark"];

  const convertToCSV = (data, active) => {

    if (active === "On Going tution") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentInfo?.name ? item?.parentInfo?.name : '-';
        const mobNo = item?.parentInfo?.mobNo ? item?.parentInfo?.mobNo : '-';
        const city = item?.cityInfo?.name ? item?.cityInfo?.name : '-';
        const Address = item.address ? `"${item.address}"` : '-';
        const tuitionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const TutorFee = item.tutorFee ? item.tutorFee : '-';
        const company = item.companyShare ? item.companyShare : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, city, Address, tuitionFee, Reference, TutorFee, company, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "demo-request") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = demoRequest.join(',') + '\r\n';

      array?.forEach(item => {

        const parentName = item?.parentId?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoTime
        const Gender = item.requiredGender ? item.requiredGender : '-';
        const LeadSource = item.leadSource ? item.leadSource : '-'
        const Remark = item.reason ? item.reason : '-';
        const row = [parentName, mobNo, studentsClasses, city, DateTime, Gender, LeadSource, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "tutor-assigned") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = tutorAssign.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const Address = item.address ? `"${item.address}"` : '';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, Address, DateTime, assignedTutor, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "On Demo") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = onDemo.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? `"${item.address}"` : '-';

        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, assignedTutor, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "Demo Done") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = DemoDone.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? `"${item.address}"` : '-';

        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Feedback = item &&
          item.processStatus === 7 ? 'Approve' :
          item.processStatus === 8 ? 'Change Tutor Request' :
            item.processStatus === 6 ? 'Pending for Student' :
              null
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, assignedTutor, Feedback, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "on-hold") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = onHold.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const address = item.address ? `"${item.address}"` : '-';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const tutionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const tutorFee = item?.tutorFee ? item?.tutorFee : '-';
        const Company = item?.companyShare ? item?.companyShare : '-';
        const holdOn = item?.companyShare ? item?.companyShare : '-';
        const Remark = item.reason ? item.reason : '-';
        const row = [parentName, mobNo, address, city, tutionFee, Reference, tutorFee, Company, holdOn, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "completed") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = completed.join(',') + '\r\n';
      array?.forEach(item => {

        const parentName = item?.parentId?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const address = item.address ? `"${item.address}"` : '-';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const tutionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const tutorFee = item?.tutorFee ? item?.tutorFee : '-';
        const Company = item?.companyShare ? item?.companyShare : '-';
        const Remark = item.reason ? item.reason : '-';

        const row = [parentName, mobNo, address, city, tutionFee, Reference, tutorFee, Company, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv;
    }

    if (active === "rejected") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = rejected.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? `"${item.address}"` : '-';

        const DateTime = item?.demoTime
        const Gender = item.requiredGender ? item.requiredGender : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, Gender, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }

    ;
  };

  useEffect(() => {
    dispatch(cityAction.cityGet({ isManagement: true }))
  }, [])

  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Tuition Management"} />
          <div className="flex items-center gap-6">
            <SearchIconComponent handleSearch={handleSearch} searchText={searchText} />
            <div className="flex relative  city_select cursor-pointer items-center">
              <select onChange={(e) => handleCity(e)} className="flex items-center capitalize	 justify-center bg-white border-2 border-black text-black rounded-lg w-full lg:w-36 px-4 h-9" value={headerCity}
                disabled={roleId !== 1 ? true : false}
                >
                <option className="text-black align-middle" value={""}>
                  {" "}
                  <option value=''>All Cities</option>  <FaAngleDown className="ml-4" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city._id} key={id}>
                    {" "}
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              {
                active === "on-going-tuition" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black gap-2  flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(onGoingTuitionData, active))}>
                    <span>Export</span>
                    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "demo-request" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black gap-2 flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "tutor-assigned" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "on-demo" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "demo-done" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "on-hold" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "completed" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : active === "rejected" ? (
                  <button className="bg-white px-3 py-1.5 border-2 border-black flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(InActiveData, active))}>
                    <span>Export</span>    <img src="/export 1.png" alt='' />
                  </button>
                ) : null
              }

            </div>
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default TuitionHeader
