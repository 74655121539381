import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MdOutlineKeyboardArrowLeft, MdSupportAgent } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';

const ButtonFour = ({ setNewStateHandle, newTicketList, totalPages4 }) => {

  const selector = useSelector(state => state);
  const [currentPage, setCurrentPage] = useState(1);
  const nevigate = useNavigate()
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages4) setCurrentPage(currentPage + 1);
  };

  return (
    <div className=''>
      <Loader loading={selector?.Dashboard?.loading} />
      <section className='lg:block hidden'>
        <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
          <thead class=" text-[#313131]  font-bold  border-b-2 border-[#D1D1D1]">
            <tr className="border-b">
              <th className="px-6 py-4 whitespace-nowrap">
                User Name
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                City
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Subject
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Message
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody className=''>
            {
              newTicketList && newTicketList.length > 0 ? (newTicketList?.map((item, id) => (
                <tr className="bg-white capitalize" key={id}>
                  <td className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                    {item?.userId?.name ?? 'No Data'} <br></br> {item?.userId?.mobNo ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item?.cityId?.name ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item?.title ?? 'No Title'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item?.msg ?? 'No Message'}
                  </td>
                  <td className="px-6 py-3 text-center" onClick={() => nevigate('/app/help&support')}>
                    <MdSupportAgent size={30} />
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
          <button onClick={() => nevigate("/app/help&support")} >
            View all

          </button>
        </div>
      </section>

      <section className="space-y-5 py-10 lg:hidden block">
        {newTicketList && newTicketList.length > 0 ? (
          newTicketList?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	User Name </p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.userId?.name ?? 'No Data'}</p>
                    <p className='text-[12px]'>{item?.userId?.mobNo ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	City</p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.cityId?.name ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Subject
                    </p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.title ?? 'No Title'}</p>
                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Message
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.msg ?? 'No Message'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p onClick={() => nevigate('/app/help&support')}><MdSupportAgent size={30} /></p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>

      {/* {totalPages4 > 1 && (
				<div className="flex justify-end items-center py-2 px-4">
					<button
						className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
							}`}
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
					>
						<FaChevronLeft />
					</button>
					<span className="text-gray-700 mx-1">
						<span className="border px-4 py-2 text-black  rounded">
							{currentPage}
						</span>{" "}
						of{" "}
						<span className="border px-4 py-2 text-black  rounded">
							{totalPages4}
						</span>
					</span>
					<button
						className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages4
							? "opacity-50 cursor-not-allowed"
							: ""
							}`}
						onClick={handleNextPage}
						disabled={currentPage === totalPages4}
					>
						<FaChevronRight />
					</button>
				</div>
			)} */}
    </div>
  )
}

export default ButtonFour
