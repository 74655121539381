import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, bannerActions } from "../../_actions";
import { uploadImageAction } from "../../_actions";
import { MdOutlineAdd, } from "react-icons/md";
import "./Banner.css";
import Loader from "../../components/Loader";
import { confirmAlert } from "react-confirm-alert";
import BannerHeader from "./BannerHeader";
import AddBanner from "./models/AddBanner";
import EditBanner from "./models/EditBanner";
import TableBanner from "./tables/FullTable";
import MobileTable from "./tables/MobileTable";
import 'react-confirm-alert/src/react-confirm-alert.css';


const Banner = () => {

  const [adduser, setAddUser] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  // const [currentPage, setCurrentPage] = useState(1);
  const [addBanner, setAddBanner] = useState({});
  const [currentPages, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  const dispatch = useDispatch();
  const bannersItem = useSelector((state) => state);
  const { banner } = bannersItem;





  const addUser = () => {
    setAddUser(true);
  };

  const closeModal = () => {
    setAddUser(false);
    setAddBanner({})
    setError('')
    setErrorData({})
  };

  const handelOnChangeInput = (e) => {
    const { name, value } = e.target
    setAddBanner(() => {
      return {
        ...addBanner,
        [name]: value
      }
    })
  }
  const [errorData, setErrorData] = useState({})
  const handleValidation = () => {
    const errors = {};
    const { ImageUpload } = bannersItem;
    if (!addBanner.name) {
      errors["name"] = "Banner Name is required";
    }
    if (!addBanner.userType) {
      errors["userType"] = "User Type is required";
    }

    if (!ImageUpload?.fileDetails?.imageUrl) {
      errors["image"] = "Banner Image is required";
    }
    // Add more validation checks as needed

    setErrorData(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return
    }
    const { ImageUpload } = bannersItem;
    if (addBanner.userType === "BOTH") {
      const bannerSet = {
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        pageNo: currentPages,
        size: pageSize,
      };
      const bannerPromises = [
        bannerActions.bannerCreateByPromise({
          name: addBanner.name,
          image: ImageUpload?.fileDetails?.imageUrl,
          userType: "TUTOR"
        }),
        bannerActions.bannerCreateByPromise({
          name: addBanner.name,
          image: ImageUpload?.fileDetails?.imageUrl,
          userType: "PARENT"
        })
      ];

      Promise.all(bannerPromises)
        .then(responses => {
          dispatch(alertActions.success("Banners created"));
          dispatch(bannerActions.BannerGet(bannerSet));
        })
        .catch(err => {
          // Handle any error from either promise
          dispatch(alertActions.error("Please try again later."));
          dispatch(bannerActions.BannerGet(bannerSet));

        });
    } else {
      const formData = {
        name: addBanner.name,
        image: ImageUpload?.fileDetails?.imageUrl,
        userType: addBanner.userType
      };
      const bannerSet = {
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        pageNo: currentPages,
        size: pageSize,
      };

      dispatch(bannerActions.Bannercreate(formData, bannerSet));

    }
    setAddBanner({});
    setAddUser(false);
    setError('')
    setErrorData({})


  };


  useEffect(() => {
    const bannerSet = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPages,
      size: pageSize,
    };
    dispatch(bannerActions.BannerGet(bannerSet));
  }, [currentPages]);

  useEffect(() => {
    if (bannersItem?.banner?.bannerGetTotal) {
      setTotalPages(Math.ceil(bannersItem?.banner?.bannerGetTotal / pageSize));
    }
  }, [bannersItem]);

  const handlePreviousPage = () => {
    if (currentPages > 1) setCurrentPages(currentPages - 1);
  };

  const handleNextPage = () => {
    if (currentPages < totalPages) setCurrentPages(currentPages + 1);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setEdit(true);
  };



  const [error, setError] = useState("")
  const uploadEditImage = (e) => {
    const file = e.target.files[0];
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
    if (file && !validFileTypes.includes(file.type)) {
      setError('Invalid file type. Only PNG, JPEG, JPG, and SVG images are allowed.');
      e.target.value = null; // Clear the selected file
    } else {
      dispatch(uploadImageAction.UploadImage(file));
    }
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    setIsLoading(true);
    try {
      const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
      if (file && !validFileTypes.includes(file.type)) {
        setError('Invalid file type. Only PNG, JPEG, JPG, and SVG images are allowed.');
        e.target.value = null; // Clear the selected file
      } else {
        dispatch(uploadImageAction.UploadImage(file));
      }
    } catch (error) {
      return error
    } finally {
      setIsLoading(false);
    }
  };

  const inputUpdate = (e) => {
    const { name, value } = e.target;
    setEditItem({ ...editItem, [name]: value });
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    const { ImageUpload } = bannersItem;
    const formUpdateData = {
      id: editItem._id,
      image: ImageUpload?.fileDetails?.imageUrl || editItem.image,
      name: editItem.name,
      userType: editItem.userType
    };
    const bannerSet = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPages,
      size: pageSize,
    };

    dispatch(bannerActions.BannerUpdate(formUpdateData, bannerSet));
    setEdit(false);
    setError('')
  };

  const deleteUser = (id) => {
    const bannerSet = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPages,
      size: pageSize,
    };

    const obj = { id: id };


    confirmAlert({
      title: 'Confirm to Delete Banner?',
      message: ``,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(bannerActions.BannerDelete(obj, bannerSet))

        },
        {
          label: 'No'
        }
      ]
    });
  };

  const handleDelete = () => {
    setAddUser(false);
    setEdit(false);
    setEditItem({})
    setError('')
  };


  const handleChange = (id) => {
    const bannerSet = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    const actionData = {
      id
    };
    dispatch(bannerActions.bannerEnaDis(actionData, bannerSet));
  }


  const handleSearch = (e) => {
    setCurrentPages(1);
    setSearchText(e.target.value);
    const bannerSet = {
      keyWord: e.target.value,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPages,
      size: pageSize,
    };
    setTimeout(() => {
      dispatch(bannerActions.BannerGet(bannerSet));

    }, 500)
  };

  return (
    <>
      <BannerHeader
        handleSearch={handleSearch}
        searchText={searchText}
      />

      <section className="overflow-hidden  overflow-y-auto">
        <Loader loading={banner?.loading} />
        <Loader loading={bannersItem?.ImageUpload?.loading} />
        <div className="m-5">
          <section className="flex justify-end ">
            <div
              className="flex bg-white justify-center place-items-center text-[#C60E6B] rounded-lg border border-[#D9D9D9]  px-3 py-2 cursor-pointer"
              onClick={addUser}
            >
              <span>
                <MdOutlineAdd className="text-xl mx-1 cursor-pointer" />
              </span>
              Add Banner
            </div>
          </section>


          <TableBanner
            banner={banner}
            handleChange={(e) => handleChange(e)}
            handleEdit={(e) => handleEdit(e)}
            deleteUser={(e) => deleteUser(e)}
            totalPages={totalPages}
            currentPage={currentPages}
            handlePreviousPage={(e) => handlePreviousPage(e)}
            handleNextPage={(e) => handleNextPage(e)}
          />
          <AddBanner
            adduser={adduser}
            closeModal={(e) => closeModal(e)}
            handleSubmit={(e) => handleSubmit(e)}
            addBanner={addBanner}
            handelOnChangeInput={(e) => handelOnChangeInput(e)}
            uploadImage={(e) => uploadImage(e)}
            error={error}
            errorData={errorData}
          />

          <EditBanner
            edit={edit}
            handleDelete={(e) => handleDelete(e)}
            handleSubmitUpdate={(e) => handleSubmitUpdate(e)}
            editItem={editItem}
            inputUpdate={(e) => inputUpdate(e)}
            uploadEditImage={(e) => uploadEditImage(e)}
            error={error}
          />

          <MobileTable
            banner={banner}
            handleChange={(e) => handleChange(e)}
            deleteUser={(e) => deleteUser(e)}
            handleEdit={(e) => handleEdit(e)}
          />
        </div>
      </section>
    </>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return (
//     dispatch
//   );
// };

// const mapStateToProps = (state) => ({

// });

export default Banner;

