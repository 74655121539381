import React, { useState } from "react";
import Button from "./Button";
import InActiveTable from "./InActiveTable";
import ActiveTable from "./ActiveTable";
import './tution.css';
import { tutionAction } from "../../../_actions";
import { useDispatch } from "react-redux";
import { CiCirclePlus } from 'react-icons/ci';
import TuitionFilters from "./TuitionFilters";

const ButtonSecondTabel = ({ searchText, newStateStatus, headerCity }) => {
  const [filters, setFilters] = useState({
    tuitionFee: "",
    tutorFee: "",
    preferredTeachMode: "",
    tutorId: "",
    classId: "",
    subjectId: "",
    classReference: "",
    demoDate: "",
  })
  const [changeOnClick, setChangeOnClick] = useState("Leads");
  const handleOnChange = (data) => {
    if (data === "New Leads") {
      handleCreateNewLead();
    } else {
      setFilters({
        tuitionFee: "",
        tutorFee: "",
        preferredTeachMode: "",
        tutorId: "",
        classId: "",
        subjectId: "",
        classReference: "",
        demoDate: "",
      })
      setChangeOnClick(data);
    }
  };

  const dispatch = useDispatch();

  const [createModal, setCreateLeadModal] = useState(false);


  const handleCreateNewLead = () => {
    setCreateLeadModal(true);
    dispatch(tutionAction.getAllCityList());
    dispatch(tutionAction.gitAllClassListByAdmin());
    dispatch(tutionAction.getAllBoardByAdmin());
  };

  const ActiveInactiveButton = [
    { name: "Leads" },
    { name: "New Leads", icon: <CiCirclePlus className="text-[#C60E6B] bg-white rounded-full text-2xl " /> },
    { name: "Active on App" }
  ];
  const ActiveInactiveButton2 = [
    { name: "Leads" },
    { name: "Active on App" }
  ];

  return (
    <div className="">
      <TuitionFilters filters={filters} setFilters={setFilters} />
      <section className="flex flex-wrap gap-2 w-full text-[#C60E6B]   md:space-y-0 pt-4">
        {(changeOnClick === "Leads" ? ActiveInactiveButton : ActiveInactiveButton2).map((item, index) => (
          <div key={index} onClick={() => handleOnChange(item.name)} className={`text-base py-2 font-bold ${changeOnClick === item.name ? "rounded-tr-[6px] rounded-tl-[px] text-white bg-[#023565]" : ""}`}>
            {item.name === "New Leads" ? (
              <button className="flex items-center">{item.icon}</button>
            ) : (
              <Button key={index} name={item.name} icon={item.icon} />
            )}
          </div>
        ))}
      </section>

      <section>
        {changeOnClick === "Leads" && (
          <InActiveTable
            createModal={createModal}
            setCreateLeadModal={setCreateLeadModal}
            searchText={searchText}
            newStateStatus={newStateStatus}
            filters={filters}
            headerCity={headerCity}
          />
        )}
        {changeOnClick === "Active on App" && (
          <ActiveTable newStateStatus={newStateStatus} filters={filters} headerCity={headerCity} searchText={searchText}
          />
        )}
      </section>
    </div>
  );
};

export default ButtonSecondTabel;
