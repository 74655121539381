import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myprofileAction } from "../../_actions/myprofile.action";
import moment from "moment/moment";
import { alertActions, uploadImageAction } from "../../_actions";
import { MdEdit } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import Loader from "../../components/Loader";
import MyProfileHeader from "./MyProfileHeader";
import { LuEye } from "react-icons/lu";
import { FaRegEyeSlash } from "react-icons/fa";




const MyProfile = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const [updateProfileModal, setUpdateProfileModal] = useState(false);
    const [viewImageModal, setViewImageModal] = useState(false)
    const [error, setError] = useState('');

    let [adminProfileData, setAdminProfileData] = useState({})

    let [UploadImage, setUploadImage] = useState({})
    const { loading } = selector?.ImageUpload ? selector?.ImageUpload : {}




    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);


    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };

    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);


    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };



    const [updateInfo, setUpdateInfo] = useState({});
    const [passWord, setPassWord] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const { currentPassword, newPassword, confirmPassword } = passWord;
        setIsFormValid(
            currentPassword.trim() !== '' &&
            newPassword.trim() !== '' &&
            confirmPassword.trim() !== ''
        );
    }, [passWord]);

    const handleOnChangePassword = (e) => {
        const { name, value } = e.target

        setPassWord(() => {
            return {
                ...passWord,
                [name]: value
            }
        })
    }

    useEffect(() => {
        dispatch(myprofileAction.getAdminProfile());
    }, [dispatch]);

    useEffect(() => {
        setAdminProfileData(selector?.MyProfile?.getAdminProfile?.data)
        setUploadImage(selector?.ImageUpload?.fileDetails?.imageUrl)
    }, [selector]);


    const handleUpdateProfile = () => {
        setUpdateProfileModal(true);
    };


    const uploadImage = (e) => {
        const file = e.target.files[0];
        const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
        if (file && !validFileTypes.includes(file.type)) {
            setError('Invalid file type. Only PNG, JPEG, JPG, and SVG images are allowed.');
            e.target.value = null; // Clear the selected file for new push
        } else {
            setError('');
            dispatch(uploadImageAction.UploadImage(file));
        }
    };


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUpdateInfo({
            ...updateInfo,
            [name]: value,
        });
    };

    const handleProfileSubmit = (e) => {
        e.preventDefault();
        const updateProfile = {
            userName: updateInfo.userName,
            image: UploadImage,
        };
        dispatch(myprofileAction.updateAdminProfile(updateProfile));
        setUpdateProfileModal(false);
    };

    const handlePasswordUpdate = (e) => {
        e.preventDefault();
        const updatePassword = {
            currentPassword: passWord.currentPassword,
            newPassword: passWord.newPassword,
            confirmPassword: passWord.confirmPassword,
        };

        if (passWord.newPassword !== passWord.confirmPassword) {
            return dispatch(alertActions.error("New password and confirm password do not match"))
        }
        dispatch(myprofileAction.updatePassword(updatePassword));
        setPassWord({
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
    };
    const viewImage = () => {
        setViewImageModal(true)
    }
    return (
        <div className="overflow-hidden overflow-y-auto">
            <Loader loading={selector?.MyProfile?.loading || loading} />
            <>
                <MyProfileHeader />

                <div className="overflow-hidden overflow-y-auto">
                    <Loader loading={selector?.MyProfile?.loading} />

                    <div className="grid lg:grid-cols-2 m-5 gap-4 py-10">
                        <div className=" bg-white p-8 rounded-md shadow-md">
                            <h2 className="text-2xl font-semibold mb-6">Profile</h2>
                            <div className="flex items-center mb-6">
                                <div className="w-24 h-24 rounded-md overflow-hidden ring-1 ring-[#023565]  shadow-lg" onClick={() => viewImage()}>
                                    <img
                                        src={adminProfileData?.image || "https://cdn.vectorstock.com/i/500p/64/20/beautiful-admin-roles-line-icon-vector-28576420.jpg"}
                                        alt="Profile"
                                        className="w-full h-full object-cover "
                                    />
                                </div>
                                <button className="inline-flex items-center gap-2 ml-4 px-2 py-2 bg-[#023565] text-white rounded-md" onClick={handleUpdateProfile}>
                                    <span><MdEdit /></span> Edit Profile
                                </button>
                            </div>
                            <div className="space-y-2">
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">Name</label>
                                    <input
                                        type="text"
                                        value={adminProfileData?.userName || ""}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        readOnly
                                        disabled
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">Create Date</label>
                                    <input
                                        type="text"
                                        value={moment(adminProfileData?.createdAt).format("MMMM Do, YYYY")}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        readOnly
                                        disabled
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">Status</label>
                                    <input
                                        type="text"
                                        value={adminProfileData?.isDisable === false ? "Active" : "Inactive"}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white p-8 rounded-md shadow-md">
                            <h2 className="text-2xl font-semibold mb-6">Change Password</h2>
                            <form onSubmit={handlePasswordUpdate}>
                                <div className="mb-4">
                                    <div className="space-y-2">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Current Password</label>
                                        <div className="relative">
                                            <input
                                                type={isPasswordVisible ? "text" : "password"}
                                                value={passWord.currentPassword}
                                                name="currentPassword"
                                                onChange={handleOnChangePassword}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Enter Current Password"
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 cursor-pointer" onClick={togglePasswordVisibility}>
                                                {isPasswordVisible ? <LuEye className="h-5 w-5" /> : <FaRegEyeSlash className="h-5 w-5" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">New Password</label>
                                        <div className="relative">
                                            <input
                                                type={isNewPasswordVisible ? "text" : "password"}
                                                value={passWord.newPassword}
                                                name="newPassword"
                                                onChange={handleOnChangePassword}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Enter New Password"
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 cursor-pointer" onClick={toggleNewPasswordVisibility}>
                                                {isNewPasswordVisible ? <LuEye className="h-5 w-5" /> : <FaRegEyeSlash className="h-5 w-5" />}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
                                        <div className="relative">
                                            <input
                                                type={isConfirmPasswordVisible ? "text" : "password"}
                                                value={passWord.confirmPassword}
                                                name="confirmPassword"
                                                onChange={handleOnChangePassword}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Confirm New Password"
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                                                {isConfirmPasswordVisible ? <LuEye className="h-5 w-5" /> : <FaRegEyeSlash className="h-5 w-5" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center gap-4">
                                    <button
                                        type="submit"
                                        className={`bg-[#023565] text-white font-bold px-3 py-2 rounded-full mt-4 w-64 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={!isFormValid}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {updateProfileModal && (
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                            <div className="relative w-11/12 mx-auto lg:w-[600px]">
                                <button
                                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                                    onClick={() => setUpdateProfileModal(false)}
                                >
                                    <span><MdOutlineClose /></span> Close
                                </button>
                                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h2 className="text-lg font-semibold">Update Profile </h2>
                                    </div>
                                    <div className="relative lg:h-[300px] overflow-y-auto">
                                        <form onSubmit={handleProfileSubmit} className="space-y-2 p-4">
                                            <div className="space-y-2 w-full">
                                                <label className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                                <input
                                                    type="text"
                                                    name="userName"
                                                    value={adminProfileData.userName || ""}
                                                    onChange={handleOnChange}
                                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <div className="space-y-2 w-full">
                                                <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">Profile Image</label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    required
                                                    onChange={uploadImage}
                                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                                    accept=".png, .svg, image/png, image/svg+xml"
                                                />

                                                {error && <p className="text-red-500 mt-2">{error}</p>}
                                            </div>
                                            <div className="flex justify-center items-center pt-2">
                                                <button
                                                    type="submit"
                                                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}

                    {viewImageModal && (

                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                            <div className="relative w-11/12 mx-auto lg:w-[600px]">
                                <button
                                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                                    onClick={() => setViewImageModal(false)}
                                >
                                    <span><MdOutlineClose /></span> Close
                                </button>
                                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h2 className="text-lg font-semibold">Image</h2>
                                    </div>
                                    <div className="relative lg:h-[400px] h-[320px] py-4 overflow-y-auto">
                                        <img className="w-[500px] h-[400px] mx-auto"
                                            src={
                                                adminProfileData?.image ??
                                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                                            }
                                            alt=""
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        </div>
    );
};

export default MyProfile;
