import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutortoliveAction, userListAction } from "../../_actions";
import { FaChevronCircleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { cityAction } from "../../_actions/city.action";
import { pincodeAction } from "../../_actions/pincode.action";
import moment from "moment/moment";
import "./Tutor.css";
import { MdOutlineClose } from "react-icons/md";

import { confirmAlert } from "react-confirm-alert";
import UpdateTutor from "./Model/UpdateTutor";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import { teachModeViewData } from "../../_helpers";
import Loader from "../../components/Loader";

const InActiveTutor = ({ searchText }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  // let InactiveTutorList = selector?.userList?.userList?.data?.demoList;
  const [inactiveTutorList,setInactiveTutorList] = useState([])
  const [historyModal, setHistoryModal] = useState(null);
  const [appliedTutionlistModal, setAppliedTutionListModal] = useState(false);
  const [action, setAction] = useState(null);
  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState('');
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState);
  const [selectedTeachMode, setSelectedTeachMode] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState([]);
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cityData,setCityData] = useState([])
  const pageSize = 10;
  const menuRef = useRef(null)

  useEffect(() => {
    let inactiveTutorData = {
      status: "INACTIVE",
      userType: "TUTOR",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize
    };
    let cityDetails = {
      isManagement: false
    };
    
    dispatch(userListAction.getUserList(inactiveTutorData));
    dispatch(cityAction.cityGet(cityDetails));
  }, [currentPage]);

  useEffect(() => {
    setCityData(() => selector?.Citys?.cityGetList);
    setInactiveTutorList(selector?.userList?.userList?.data?.demoList || [])
    if (selector?.userList?.userList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.userList?.userList?.data?.total / pageSize)
      );
    }
  }, [selector]);

 

  const handleFullDetails = (data) => {
    setfullDetailsModal(true)
    setfullDetailsModalData([data])
  }

  const handleAction = (resDataId) => {
    setAction((prevresDataId) =>
      prevresDataId === resDataId ? null : resDataId
    );
    setHistoryModal(false);
  };

  const toggleHistoryModal = (id) => {
    setHistoryModal((prevId) => (prevId === id ? null : id));
  };

  const handleAppliedTution = (data) => {
    setAppliedTutionListModal(true);
    let appliedData = {
      tutorId: data._id,
    };
    dispatch(userListAction.getAppliedTutionList(appliedData));
  };

  const handleDemoDone = (data) => {
    let demoDoneData = {
      tutorId: data._id,
    };
    dispatch(userListAction.getDemoDoneList(demoDoneData));
  };
  const handleCompleteTution = (data) => {
    let completeDemoData = {
      tutorId: data._id,
    };
    dispatch(userListAction.completeTutionList(completeDemoData));
  };

  const handleKycReminder = (data) => {
    let kycDetails = {
      userId: data._id,
    };

    dispatch(userListAction.kycReminder(kycDetails));
  };

  const handlePackageReminder = (data) => {
    let packageReminderDetails = {
      userId: data._id,
    };
    dispatch(userListAction.packageReminder(packageReminderDetails));
  };



  const handleUpdateTutor = async (user) => {
    tutortoliveAction.getTutorByPromiseId({
      id: user._id
    }).then(async (data) => {
      let user = data.data
      setNewAcceptData({
        _id: user._id,
        name: user?.name,
        email: user?.email,
        whatsappNo: user?.whatsappNo,
        address: user?.address,
        city: user?.city,
        pinCode: user?.pinCode,
        dob: user?.tutorInfoId?.dob,
        gender: user?.tutorInfoId?.gender,
        schoolName: user?.tutorInfoId?.schoolName,
        schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
        ugCourse: user?.tutorInfoId?.ugCourse,
        ugCollegeName: user?.tutorInfoId?.ugCollegeName,
        ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
        pgCourse: user?.tutorInfoId?.pgCourse,
        pgCollegeName: user?.tutorInfoId?.pgCollegeName,
        pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
        teachingExp: user?.tutorInfoId?.teachingExp,
      })
      setSelectedTeachMode(user?.tutorInfoId?.teachingMode.map((ele) => ({ name: teachModeViewData(ele), _id: ele })))
      setSelectedPinCode(user?.tutorInfoId?.pinCodeYouCanGo?.map((ele) => ({ name: ele, _id: ele })) || [])
      setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
      setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
      setAcceptModal((prev) => !prev);
    })

  }
 
  const handleBlockTutor = (data) => {
    let resData = {
      status: "INACTIVE",
      userType: "TUTOR",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };
    let blockData = {
      status: "BLOCK",
      id: data._id,
    };
    confirmAlert({
      title: "Confirm to Block?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(userListAction.blockByAdmin(blockData, resData))

        },
        {
          label: "No",
        },
      ],
    })
  };
  const closeModal = () => {
    setAcceptModal(false);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAction(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Loader loading={selector?.userList?.loading} />
      <section className="lg:block hidden py-10">
        <div className=" border border-[#D3D3D3] rounded-xl overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Tutor Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Signup Date
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Inactive Reason
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  History
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {inactiveTutorList && inactiveTutorList.length ?
                inactiveTutorList?.map((itm, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className="flex items-center justify-center gap-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            itm?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt="User"
                        />
                        <div className="w-24">
                          <p className="font-medium whitespace-nowrap">{itm?.name ?? "No Data"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{itm?.mobNo ?? "No Contact"}</p>
                        </div>
                      </div>
                      <FaChevronCircleRight
                        className="text-[#023565] text-lg cursor-pointer"
                        onClick={() => handleFullDetails(itm)}
                      />
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {itm?.city?.name ?? "No City"}
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {moment(itm?.createdAt).format("MMMM Do, YYYY")}
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      KYC Not Done
                    </td>
                    <td className="px-6 py-4  relative text-gray-900">
                      <button
                        className="underline"
                        onClick={() => toggleHistoryModal(itm._id)}
                      >
                        View
                      </button>
                      {historyModal && historyModal === itm._id && (
                        <div className="absolute top-14 right-1 forfontsmall rounded-sm bg-white w-44 border shadow-lg z-10">
                          <ul className="bg-white text-sm font-bold underline">
                            <li
                              className="px-4 py-2 text-blue-600 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleAppliedTution(itm)}
                            >
                              Applied Tuitions
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleDemoDone(itm)}
                            >
                              Demo Done
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCompleteTution(itm)}
                            >
                              Completed Tuitions
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                    
                    <td className="px-6 py-4 relative">
                      <button className="  font-bold underline ">
                        <BsThreeDotsVertical
                          className="text-2xl text-[#C60E6B]"
                          onClick={() => handleAction(itm._id)}
                        />
                      </button>

                    </td>
                    {action && action === itm._id && (
                      <div className="absolute right-0 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                        <ul className="divide-y divide-gray-300">
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-t-lg"
                          >
                            <span
                              className="text-[#C60E6B] text-sm font-semibold"
                              onClick={() => handleKycReminder(itm)}
                            >
                              KYC Reminder
                            </span>
                            <span className="ml-auto font-semibold text-[#C60E6B]">
                              &gt;
                            </span>
                          </li>
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3"
                            onClick={() => handlePackageReminder(itm)}
                          >
                            <span className="text-[#023565] text-sm font-semibold">
                              Package Reminder
                            </span>
                            <span className="ml-auto text-blue-600">&gt;</span>
                          </li>
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-b-lg" onClick={() => handleUpdateTutor(itm)} >
                            <span className="text-[#023565] text-sm font-semibold">
                              View & Update Info
                            </span>
                            <span className="ml-auto text-blue-600">&gt;</span>
                          </li>
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-b-lg" onClick={() => handleBlockTutor(itm)} >
                            <span className="text-[#023565] text-sm font-semibold">
                              Block Tutor
                            </span>
                            <span className="ml-auto text-blue-600">&gt;</span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </tr>
                )) : <td>Data not found.</td>}
            </tbody>
          </table>

        </div>
        
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      {
        fullDetailsModal && (
          <>
            
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60">
              <div className="relative w-11/12 mx-auto lg:w-[800px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setfullDetailsModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold"> Tutor Details </h2>
                  </div>
                  <div className="relative h-[310px] overflow-y-auto">
                    {fullDetailsModalData && fullDetailsModalData?.map((itm, id) => (
                      <div key={id} className="grid lg:grid-cols-3 gap-4 p-4">
                        <div className="lg:col-span-1 w-full text-center">
                          <img
                            src={itm?.image ?? 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'}
                            className="w-56 h-56 mx-auto "
                            alt="Document Selfie"
                          />
                        </div>
                        <div className="lg:col-span-2 w-full capitalize space-y-2">
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Name</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.name}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Mobile No</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.mobNo}</p>
                          </div>
                          <div className="w-full flex justify-between items-start ">
                            <p className="font-semibold w-[40%]">Email</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%] break-all">{itm?.email}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Address</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.address}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">D O B</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{moment(itm?.tutorInfoId?.dob).format('MMMM DD, YYYY')}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">School Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.schoolPassOutYear}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">UG Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.ugPassOutYear}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">PG Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.pgPassOutYear}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      <UpdateTutor
        closeModal={closeModal}
        newAcceptData={newAcceptData}
        acceptModal={acceptModal}
        setNewAcceptData={setNewAcceptData}
        currentPage={currentPage}
        pageSize={pageSize}
        selectedTeachMode={selectedTeachMode}
        setSelectedTeachMode={setSelectedTeachMode}
        selectedPinCode={selectedPinCode}
        setSelectedPinCode={setSelectedPinCode}
        subjectYouCanTeach={subjectYouCanTeach}
        setSubjectYouCanTeach={setSubjectYouCanTeach}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        status={"INACTIVE"}
      />
      {/* <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[1024px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setUpdateModal(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Update Tutor</h2>
                </div>
                <div className="relative lg:h-[550px] h-[400px] overflow-y-auto">
                  <div className="p-4 gap-3 lg:grid grid-cols-3">
                    <div className="space-y-1">
                      <label for="">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={selectedTutorName.name}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="Name"
                      />
                    </div>
                    <div className="space-y-1">
                      <label for="">WhatsApp Number</label>
                      <input
                        type="text"
                        id="whatsappNo"
                        name="whatsappNo"
                        value={selectedTutorName.whatsappNo}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="WhatsApp Number"
                      />
                    </div>

                    <div className="space-y-1">
                      <label for="">Gender</label>
                      <select value={selectedTutorName?.tutorInfoId?.gender} onChange={handleOnChange} name="gender" className="mt-1 p-2 border block w-full border-gray-300 rounded-md">
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>

                      </select>
                    </div>
                    <div className="space-y-1">
                      <label for="">Email</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={selectedTutorName.email}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="Email"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">Address</label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={selectedTutorName.address}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="Address"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">Date of Birth</label>
                      <input
                        type="text"
                        id="dob"
                        name="dob"
                        value={selectedTutorName?.tutorInfoId?.dob}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="Date of Birth"
                      />
                    </div>

                    <div className="space-y-1">
                      <label for="">  PG Pass Out Year</label>
                      <input
                        type="text"
                        id="pgPassOutYear"
                        name="pgPassOutYear"
                        value={selectedTutorName?.tutorInfoId?.ugPassOutYear}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="PG Pass Out Year"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">UG Pass Out Year</label>
                      <input
                        type="text"
                        id="ugPassOutYear"
                        name="ugPassOutYear"
                        value={selectedTutorName?.tutorInfoId?.ugPassOutYear}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="UG Pass Out Year"
                      />
                    </div>
                    <div className="space-y-1">
                      <label for=""> UG Course</label>
                      <input
                        type="text"
                        id="ugCourse"
                        name="ugCourse"
                        value={selectedTutorName?.tutorInfoId?.ugCourse}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="UG Course"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">  PG Course</label>
                      <input
                        type="text"
                        id="pgCourse"
                        name="pgCourse"
                        value={selectedTutorName?.tutorInfoId?.pgCourse}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="PG Course"
                      />
                    </div>
                    <div className="space-y-1">
                      <label for="">  School Name</label>
                      <input
                        type="text"
                        id="schoolName"
                        name="schoolName"
                        value={selectedTutorName?.tutorInfoId?.schoolName}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="School Name"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">  UG College Name</label>
                      <input
                        type="text"
                        id="ugCollegeName"
                        name="ugCollegeName"
                        value={selectedTutorName?.tutorInfoId?.ugCollegeName}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="UG College Name"
                      />

                    </div>
                    <div className="space-y-1">
                      <label for="">  PG College Name</label>
                      <input
                        type="text"
                        id="pgCollegeName"
                        name="pgCollegeName"
                        value={selectedTutorName?.tutorInfoId?.pgCollegeName}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300rounded-md"
                        placeholder="PG College Name"
                      />
                    </div>

                    <div className="space-y-1">
                      <label for="">Teaching Experience</label>
                      <input
                        type="text"
                        id="teachingExp"
                        name="teachingExp"
                        value={selectedTutorName?.tutorInfoId?.teachingExp}
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        placeholder="Teaching Experience"
                      />



                    </div>
                    <div className="space-y-1">
                      <label for=""> Teaching Mode</label>
                      <select value={selectedTutorName?.tutorInfoId?.teachingMode} onChange={handleOnChange} name="teachingMode" className="mt-1 p-2 border block w-full border-gray-300 rounded-md">
                        <option value="HOME_TUITION">HOME_TUITION</option>
                        <option value="ONLINE_TUITION">ONLINE_TUITION</option>
                      </select>
                    </div>
                    <div className="space-y-1">
                      <label for=""> City</label>
                      <select
                        id="countries"
                        name="updatedCity"
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      >
                        <option selected="">Choose a City</option>
                        {cityData.length > 0 &&
                          cityData?.map((city) => {
                            return <option value={city?._id}>{city?.name}</option>;
                          })}
                      </select>

                    </div>
                    <div className="space-y-1">
                      <label for="">Pincode</label>
                      <select
                        id="countries"
                        name="updatedPinCode"
                        onChange={handleOnChange}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      >
                        <option selected="">Choose a Pincode</option>
                        {pinCodeData.length > 0 &&
                          pinCodeData?.map((pin) => {
                            return (
                              <option value={pin?._id} className="text-black">
                                {pin?.pinNo}
                              </option>
                            );
                          })}
                      </select>

                    </div>

                  </div>

                  <div className="flex justify-center items-center py-6">
                    <button
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full"
                      onClick={handleUpdateSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}



      <section className="space-y-5 py-10 lg:hidden block">
        {inactiveTutorList && inactiveTutorList.length > 0 ? (
          inactiveTutorList?.map((itm, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Tutor Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          itm?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{itm?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{itm?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(itm)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' >{itm?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Signup Date</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {moment(itm?.createdAt).format("MMMM Do, YYYY")}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Inactive Reason</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' > {itm?.reason ?? 'N/A'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>History</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      <button
                        className="underline"
                        onClick={() => toggleHistoryModal(itm._id)}
                      >
                        View
                      </button>
                    </p>

                    {historyModal && historyModal === itm._id && (
                      <div
                        className="absolute top-14 right-1 forfontsmall  w-44  shadow-lg z-10"
                      // ref={viewRef}
                      >
                        <ul className="bg-white text-sm font-bold rounded-xl border text-left">
                          <li
                            className="px-4 py-2  text-[#C60E6B] cursor-pointer hover:bg-gray-100"
                            onClick={() => handleAppliedTution(itm)}
                          >
                            Applied Tuitions
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer text-[#023565]  hover:bg-gray-100"
                            onClick={() => handleDemoDone(itm)}
                          >
                            Demo Done
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
                            onClick={() => handleCompleteTution(itm)}
                          >
                            Completed Tuitions
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="mx-auto text-[#C60E6B] text-2xl cursor-pointer"
                        onClick={() => handleAction(itm._id)}
                      />

                      {action && action === itm._id && (
                        <div
                          className="absolute right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                        >
                          <ul className=" text-sm font-bold">
                            <li
                              className="px-4 py-2  text-[#C60E6B] cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                              onClick={() => handleKycReminder(itm)}
                            >
                              <i className="fas fa-bell mr-2 "></i> KYC Reminder
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                              onClick={() => handlePackageReminder(itm)}
                            >
                              <i className="fas fa-gift mr-2"></i> Package
                              Reminder
                            </li>
                            <li
                              className="px-4 py-2 text-green-500 cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                              onClick={() => handleUpdateTutor(itm)}
                            >
                              <i className="fas fa-eye mr-2"></i> View & Update
                              Info
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleBlockTutor(itm)}
                            >
                              <i className="fas fa-ban mr-2"></i> Block Tutor
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
    </>
  );
};

export default InActiveTutor;
