/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parentAction, userActions } from "../../_actions";
import { FaAngleRight, FaChevronCircleRight, FaChevronLeft } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { tutionAction } from "../../_actions";
import Loader from "../../components/Loader";
import BlockPopUp from "../Tutor-Profile/Model/BlockPopUp";
import UpdateParent from "./model/UpdateParent";
import ImagePreviewModal from "../Tutor-Profile/Model/ImagePreview";
import ViewUpdate from "./model/ViewUpdate";
import StudentDetail from "./model/StudentDetail";
import { FaEye } from "react-icons/fa";
import { FaFilterCircleXmark } from "react-icons/fa6";

const InActiveParent = ({ headerCity, searchText, handleReset }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const selector = useSelector((state) => state);

  const [inactiveParentUserList, setinactiveParentUserList] = useState()
  let [studentListdata, setStudentListData] = useState();

  const [action, setAction] = useState(null);
  const [name, setName] = useState("");
  const [blockedData, setNewBlockedData] = useState({});
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [blockedPoup, setBlockPouUp] = useState(false);
  const [fullDetails, setFullDetails] = useState(false);
  const [DetailsData, setFullDetailsData] = useState();
  let [allClassListByAdminData, setAllClassListByAdminData] = useState([]);
  const [nameBlock, setNameBlock] = useState("")
  const [initialParentData, setInitialParentData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10

  const menuRef = useRef(null);


  const mobileRef = useRef(null)
  const [pinCode, setPinCode] = useState("")



  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setAction(null);
    }

    if (mobileRef.current && !mobileRef.current.contains(event.target)) {
      setAction(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [parentUpdateModal, setParentUpdateModal] = useState(false);
  useEffect(() => {
    let inactiveParentDetails = {
      status: "INACTIVE",
      userType: "PARENT",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : [],
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode

    };

    dispatch(parentAction.getUserList(inactiveParentDetails));
    dispatch(tutionAction.getAllCityList())
    dispatch(tutionAction.gitAllClassListByAdmin());

  }, [currentPage, headerCity, searchText, pinCode]);

  useEffect(() => {
    if (selector?.Parent?.parentuserList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Parent?.parentuserList?.data?.total / pageSize));
    }
    setinactiveParentUserList(() => selector?.Parent?.parentuserList?.data?.demoList);
    setAllClassListByAdminData(selector?.tution?.getAllClassList?.data);

  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const [allPincodeList, setAllPincodeList] = useState("");


  useEffect(() => {
    setAllCityListData(selector?.tution?.getAllCityList?.data);
  }, [selector]);



  const handleAction = (id) => {
    setAction(action === id ? null : id);
  };

  const handleBlockParent = (data) => {
    setBlockPouUp(true);

    let blockedData = {
      status: "BLOCK",
      id: data._id,
    };
    setNameBlock(data.name)
    setNewBlockedData(blockedData);
  };

  const handleSubmit = () => {
    let resData = {
      status: "INACTIVE",
      userType: "PARENT",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : [],
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode

    };
    let data = {
      ...blockedData,
      reason: name,
    };

    dispatch(parentAction.blockByAdmin(data, resData));
    setBlockPouUp(false);
    setAction(null);
    setName("");
  };

  const handleActiveParent = (data) => {
    let resData = {
      status: "INACTIVE",
      userType: "PARENT",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : [],
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode

    };
    let activeId = {
      id: data._id,
      status: "ACTIVE",
    };
    dispatch(parentAction.blockByAdmin(activeId, resData));
  };

  const handleStudent = async (data) => {
    let studentListDetails = {
      parentId: data._id,
    };
    parentAction.getStudentListByParentIdPromise(studentListDetails).then((resData) => {
      setStudentListData(resData.data)
      setViewStudentModal(true);
    }).catch((err) => {
    });
  };

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data._id }).then((res) => {
      setFullDetails(true);
      setFullDetailsData(res?.data);
    })
  };

  const handleParentUpdate = (data) => {
    setParentUpdateModal(true)
    setInitialParentData({ ...data, city: data?.city?.id })
  };


  const [allCityListData, setAllCityListData] = useState();


  const handleViewHistory = (data) => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }




  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };


  const handleResetFilters = () => {
    handleReset();
    setPinCode("")
  }

  
  return (
    <section>
      <Loader loading={selector?.Parent?.loading} />


      <div className="lg:flex justify-end lg:-mt-9 mt-6">
        <div className="filter flex items-center space-x-4">
          <select onChange={(e) => setPinCode(e.target.value)} className="block lg:w-40 w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
            value={pinCode}>
            <option value=''>PinCode</option>
            {headerCity &&
              selector?.Citys?.cityGetList
                .filter(city => city._id === headerCity)?.[0]?.pinCode
                ?.map(pin => (
                  <option value={pin} key={pin}>
                    {pin}
                  </option>
                ))
            }
          </select>
          <button className="text-center font-bold" onClick={() => handleResetFilters()}>
            <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
          </button>
        </div>
      </div>
      <section className="lg:block hidden">
        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Parent Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Student Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">City</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Signup Date</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Action</th>
              </tr>
            </thead>
            <tbody>
              {inactiveParentUserList && inactiveParentUserList.length > 0 ? (
                inactiveParentUserList?.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="px-4 py-4 flex items-center justify-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span onClick={item?.image ? () => handleCloseFunctionImageModel(item?.image) : null}>
                          {item?.image && item?.image ?
                            <img className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                              src={
                                item?.image}
                              alt="User"
                            /> : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.name ? (item.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">{item?.name ?? "N/A"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "N/A"}</p>
                          <div className="flex items-center gap-1 cursor-pointer pt-1" onClick={() => handleFullDetails(item)}>
                            <p className="text-base font-normal cursor-pointer">More</p>
                            <FaChevronCircleRight
                              className="text-[#023565] text-base cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">
                      <span className="hover:text-[#CD0C5C] text-center flex items-center justify-center" onClick={() => handleStudent(item)}>  <FaEye size={24} /></span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center capitalize">{item?.city?.name ?? "No city"}</td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center capitalize"> {item?.createdAt ? moment(item.createdAt).format("DD-MMM-YYYY") : "No Date"}</td>

                    <td className="px-4 py-4 text-gray-900 relative">
                      <span className="flex justify-center items-center">
                        <BsThreeDotsVertical onClick={() => handleAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                      </span>
                      {action === item._id && (
                        <div className="absolute right-28 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                          <div className="py-1 font-semibold text-[#023565] ">
                            <p
                              href="#view-update-info"
                              className=" px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F]  text-sm  hover:bg-gray-100" onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                            <a
                              href="#-parent"
                              className=" px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F]   text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </a>
                            {item.userStatus === 1 ? 
                            <p

                              className=" px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F]   text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleActiveParent(item)}
                            >
                              Active Parent
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                            : null}
                            <div
                              className=" px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F]   text-sm text-[#023565] hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )
                : (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </section>


      <BlockPopUp
        blockedPoup={blockedPoup} setBlockpouUp={setBlockPouUp} name={name} setName={setName} handleSubmit={handleSubmit} nameBlock={nameBlock}
      />


      <ViewUpdate
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />

      <StudentDetail
        setViewStudentModal={setViewStudentModal} viewStudentModal={viewStudentModal} setStudentListData={setStudentListData} studentListdata={studentListdata}
      />

      <UpdateParent
        setParentUpdateModal={setParentUpdateModal}
        parentUpdateModal={parentUpdateModal}
        initialParentData={initialParentData}
        currentPage={currentPage}
        pageSize={pageSize}
        status={"INACTIVE"}
      />
      <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />

      <section className="space-y-5 py-6 lg:hidden block">
        {inactiveParentUserList && inactiveParentUserList.length > 0 ? (
          inactiveParentUserList?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name	</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student Name</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleStudent(item)}>view</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Signup Date</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "No Date"}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Inactive Reason</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.reason ?? "No Resion"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] '><BsThreeDotsVertical size={24}
                      className="text-[#C60E6B] cursor-pointer relative"
                      onClick={() => handleAction(item._id)}
                    />  {action === item._id && (
                      <div className="absolute right-50  w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40" ref={mobileRef}>
                        <div className="py-1 font-semibold">
                          <a
                            href="#view-update-info"
                            className="block px-4 py-2 text-sm text-pink-600 hover:bg-gray-100" onClick={() => handleParentUpdate(item)}
                          >
                            View & Update Info
                          </a>
                          <a
                            href="#block-parent"
                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleBlockParent(item)}
                          >
                            Block Parent
                          </a>
                          <a
                            href="#block-parent"
                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleActiveParent(item)}
                          >
                            Active Parent
                          </a>
                          <button

                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleViewHistory(item)}
                          >
                            View History
                          </button>
                        </div>
                      </div>
                    )}</p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </section>
  );
};

export default InActiveParent;
