import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { timeZoneReturnDateType } from '../../../_helpers'

function FullTable({ currentPage, NotificationData, list, total, totalPages, pageSize, handlePreviousPage, handleNextPage, viewImage }) {
  // list={list}
  //     total={total}
  const Selector = useSelector(state => state)
  const cityData = Selector?.Citys?.cityGetList || []


  return (
    <section className="bg-white border-2 border-[#D3D3D3] rounded-lg lg:block hidden">

      <div className="relative overflow-x-auto  overflow-y-auto sm:rounded-2xl">
        <table className="w-full text-sm ">
          <thead className="text-[#313131] text-start">
            <tr className="border-b tableHead">
              <th className="px-2 py-3 text-left w-[5%]">
                S.No
              </th>
              <th className="px-6 py-3 text-left w-[30%]">
                City
              </th>
              <th className="px-6 py-3 text-left w-[15%]">
                Title
              </th>
              <th className="px-6 py-3 text-left w-[30%]">
                Notification Message
              </th>
              <th className="px-6 py-3 text-left w-[10%]">
                Image
              </th>
              <th className="px-6 py-3 text-left w-[10%]">
                Create Date
              </th>
            </tr>
          </thead>
          <tbody>
            {NotificationData && NotificationData.length > 0 ? (
              NotificationData.map((item, id) => (<>
                <tr
                  key={id}
                  className="bg-white  border-b dark:border-gray-700 capitalize"
                >
                  <th
                    scope="row"
                    className="px-2 py-4 text-left font-bold text-[#313131] whitespace-nowrap w-[5%]"
                  >
                    {(currentPage - 1) * pageSize + id + 1}
                  </th>
                  <td className="px-6 py-4 text-[#313131] w-[30%] ">
                    <div className="flex gap-1 justify-cente flex-wrap ">
                      {item.cityId.length === cityData.length ? <p className="border border-[#023565]  text-[#023565] px-1.5 py-0.5 text-[12px] rounded-full"> Select All</p> : item?.cityId?.map((cty, id) => (
                        <p className="border border-[#023565]  text-[#023565] px-1.5 py-0.5 text-[12px] rounded-full" key={id}>{cty?.name ? cty?.name : "No City"}</p>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-[#313131] w-[15%]">{item?.name}</td>
                  <td className="px-6 py-4 w-[30%]">{item.msg}</td>
                  <td className={`px-6 py-4 w-[10%] ${item.image ? "text-[#00AFE2]" : "text-neutral-900"} cursor-pointer`} onClick={item.image ? () => viewImage(item) : null}>
                    {item.image ? "View" : "N/A"}
                  </td>
                  <td className="px-6 py-4 text-[#313131] w-[10%]">
                    {timeZoneReturnDateType(item?.createdAt)}
                  </td>
                </tr>
              </>

              ))

            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                  Data Not Available
                </td>
              </tr>
            )
            }
          </tbody>
        </table>
      </div>
      <div>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
            </span>
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>

    </section>
  )
}

export default FullTable