import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action";
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { tutionAction, userListAction } from "../../_actions";
import { FaChevronCircleRight } from "react-icons/fa";
import moment from "moment/moment";
import Loader from "../../components/Loader";

const ButtonSecond = ({
  setNewStateHandle,
  fullData,
  DemoRequestListData,
  totalPages2,
  tutorData,
  suggestedTutor,
  allTutorDataList,
}) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [open, setOpen] = useState(false);

  const [activeateModal, setActiveateModal] = useState(false);
  const [activateData, setActivateData] = useState("");

  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [assignTutorList, setAssignTutorList] = useState("");
  const [demoIdForAssign, setDemoIdForAssign] = useState({});
  const menuRef = useRef(null);
  const mobileRef = useRef(null);
  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");
  const [inputChangeField, setInputChangeField] = useState({});

  const [errorInputChangeField, setErrorInputChangeField] = useState({});

  let suggestTutorBtn = ["Applicants", "Suggested Tutors", "All Tutors"];

  const [newtutorDetailsData, setNewTutorDetailsData] = useState({});

  const [tutorDetails, setTutorDetails] = useState(false);

  const pageSize = 10;


  useEffect(() => {
    dispatch(dashboardAction.getDashboardCount());
  }, [])
  // const mobileRef = useRef(null)

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);

    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
      status: ""
    };

    dispatch(dashboardAction.getLatestDemoRequestList(demoRequestDetails));

  };

  const handleNextPage = () => {
    if (currentPage < totalPages2) setCurrentPage(currentPage + 1);

    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage + 1,
      size: pageSize,
    };

    dispatch(dashboardAction.getLatestDemoRequestList(demoRequestDetails));

  };

  const openAction = (itemId) => {
    setOpen((prevItemId) => (prevItemId === itemId ? null : itemId));

  };



  /// Handle Active=============>
  const handleActivate = (data) => {
    setActiveateModal(true);
    setActivateData(data);
    setOpen(false);
  };
  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode == 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    // dispatch(tutionAction.getAllTutorList({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query));

    setAssignTutorModal(true);
    setAssignTutorList(data);
    setDemoIdForAssign(data._id);
    setOpen(false);
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };

  const handleAssign = (data) => {
    let demoData = {
      status: [0, 1],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };


    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
      // status:[3]
    };



    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, demoRequestDetails));

    const alertBoxassign = document.getElementById("alert-box-assign");
    alertBoxassign.style.display = "block";
    setTimeout(() => {
      alertBoxassign.style.display = "none";
    }, 2000);

    setOpen(false);
    setAssignTutorModal(false);
  };

  const handleTutorDetails = (data) => {
    setTutorDetails(true);
    setNewTutorDetailsData(data);
    let tutorId = {
      id: data._id,
    };
    dispatch(userListAction.getTutorById(tutorId));
  };

  const ApproveHandle = (data) => {
    if (data && data.status && data.status === "APPROVED") {
      if (handleValidate()) {
        const resData = {
          status: data && data.status,
          demoId: activateData && activateData._id ? activateData._id : null,
          tuitionFee:
            inputChangeField && inputChangeField.tuitionFee
              ? inputChangeField.tuitionFee
              : 0,
          inHandFees:
            inputChangeField && inputChangeField.tutorFee
              ? inputChangeField.tutorFee
              : 0,
          numberOfClasses:
            inputChangeField && inputChangeField.numberOfClass
              ? inputChangeField.numberOfClass
              : 0,
          teachingLocation:
            inputChangeField && inputChangeField.teachingLocation
              ? inputChangeField.teachingLocation
              : null,
          remark:
            inputChangeField && inputChangeField.remark
              ? inputChangeField.remark
              : null,
        };

        let demoRequestDetails = {
          cityId: "",
          keyWord: "",
          pageNo: 1,
          size: pageSize,
        };



        dispatch(
          tutionAction.updateStatusDemoApproveOrReject(resData, demoRequestDetails)
        );
        setInputChangeField({
          teachingLocation: "",
          numberOfClass: "",
          tuitionFee: "",
          tutorFee: "",
          remark: "",
        });
        setErrorInputChangeField({});
        // dispatch(tutionAction.getTuitionCount());
        dispatch(tutionAction.getTuitionCount());
        setActiveateModal(false);

        const alertBox0 = document.getElementById("alert-box-Activated");
        alertBox0.style.display = "block";
        // setTimeout(() => {
        //   alertBox0.style.display = "none";
        // }, 2000);
      }
    }
  };

  const handleValidate = () => {
    // let inputChangeField = inputChangeField
    let valid = true;
    let errorInputChangeField = {};
    if (inputChangeField && !inputChangeField.tuitionFee) {
      errorInputChangeField["tuitionFee"] = `can't be empty tution fee.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.tuitionFee) {
      errorInputChangeField["tuitionFee"] = `can't be empty in hand fee.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.numberOfClass) {
      errorInputChangeField[
        "numberOfClass"
      ] = `can't be empty number of classes.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.teachingLocation) {
      errorInputChangeField[
        "teachingLocation"
      ] = `can't be empty teaching location.`;
      valid = false;
    }
    setErrorInputChangeField(errorInputChangeField);
    return valid;
  };
  /////Reject mdal State===========================>>>>>>>>>>

  const [rejectResionModal, setRejectResionModal] = useState(false);
  const [rejectResion, setRejectResion] = useState("");
  const [rejectData, setRejectData] = useState({});

  ///======handle Reject=================>




  const handleRejectMsg = (data) => {
    setRejectResionModal(true);
    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };
    setRejectData(acceptedData);
  };

  const handleRejectResion = (e) => {
    e.preventDefault();

    let data = {
      ...rejectData,
      remark: rejectResion,
    };
    let demoReq = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    dispatch(tutionAction.updateStatusDemoApproveOrReject1(data, demoReq));
    setRejectResionModal(false);
    setRejectResion("");
    const alertBox3 = document.getElementById("alert-box-rejected");
    alertBox3.style.display = "block";
    setTimeout(() => {
      alertBox3.style.display = "none";
    }, 2000);

    setOpen(false);
  };

  const handlerejctCloseModal = () => {
    setRejectResionModal(false);
    setRejectResion("");
  };


  /////Reject All Function======================>>>>>>>>>>>>>>>

  const handleTabChangeInput = (event) => {
    const { value, name } = event.target;
    setInputChangeField((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrorInputChangeField((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  /// View history==============>
  const viewHistory = (data) => {
    navigate(`/app/viewhistory/${data?._id}`);
    setOpen(false);
  };

  const [studentModal, setStudentModal] = useState(false);
  const [studentData, setStudentData] = useState("");
  const [subjClassModal, setSubjClassModal] = useState(false);
  const [subjClassModalData, setSubjClassModalData] = useState("");

  const handleStudentName = (data) => {
    setStudentModal(true);
    setStudentData(data);
  };
  const handleClassSubj = (data) => {
    setSubjClassModal(true);
    setSubjClassModalData(data);
  };

  const handleActiveClose = () => {
    setActiveateModal(false);
    setInputChangeField({
      teachingLocation: "",
      numberOfClass: "",
      tuitionFee: "",
      tutorFee: "",
      remark: "",
    });
    setErrorInputChangeField({});
  };

  return (
    <div className="overflow-hidden overflow-y-auto">
      <Loader loading={selector?.Dashboard?.loading} />
      <div className="lg:block hidden">
        <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
          <thead class=" text-[#313131]  font-bold  border-b-2 border-[#D1D1D1]">
            <tr className="border-b">
              <th class="px-6 py-4 whitespace-nowrap">Parent</th>
              <th class="px-6 py-4 whitespace-nowrap">Student</th>
              <th class="px-6 py-4 whitespace-nowrap">City</th>
              <th class="px-6 py-4 whitespace-nowrap">Demo Date</th>
              <th class="px-6 py-4 whitespace-nowrap">Action</th>
            </tr>
          </thead>
          <tbody className="text-">
            {DemoRequestListData && DemoRequestListData.length > 0 ? (
              DemoRequestListData.map((item, id) => (
                <tr className="bg-white capitalize" key={id}>
                  <td className="font-[400] text-[12px]  text-black py-3 px-2  gap-2">
                    <span>{item?.parentId?.name ?? "No Details"}</span> <br />
                    <span>{item?.parentId?.mobNo ?? "No Contact"}</span>
                  </td>
                  <td class=" px-6 py-3 text-[12px]">
                    <button onClick={() => handleStudentName(item)}>
                      View
                    </button>
                  </td>
                  <td className=" px-6 py-3 text-[12px]">
                    {item?.cityId?.name ? item?.cityId?.name : "N/A"}
                  </td>
                  <td className=" px-6 py-3 text-[12px]">
                    {moment(item?.createdAt).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td className=" px-6 py-3 text-[12px] relative">
                    <BsThreeDotsVertical
                      onClick={() => openAction(item._id)}
                      className="text-2xl cursor-pointer text-[#E4006F]"
                    />

                    {open === item._id && (
                      <div
                        className="absolute right-0 bg-white border rounded-md text-[#023565] font-semibold w-48  mt-2 py-2 text-start px-2 text-sm shadow-lg z-10"
                        ref={menuRef}
                      >
                        <div
                          className="py-2 px-3 hover:bg-gray-100 hover:text-[#E4006F] cursor-pointer"
                          onClick={() => handleActivate(item)}
                        >
                          <p className="flex justify-between items-center">
                            Activate{" "}
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </p>
                        </div>
                        <div
                          className="py-2 px-3 hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer"
                          onClick={() => assignTutor(item)}
                        >
                          <p className="flex justify-between items-center">
                            Assign Tutor{" "}
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </p>
                        </div>

                        <div
                          className="py-2 px-3 hover:bg-gray-100 hover:text-[#E4006F] cursor-pointer flex justify-between"
                          onClick={() => {
                            handleRejectMsg(item);
                          }}
                        >
                          <button className="flex justify-between items-center">
                            Reject
                          </button>
                          <div>
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </div>
                        </div>
                        <div
                          className="py-2 px-3 hover:bg-gray-100 hover:text-[#E4006F] cursor-pointer relative flex justify-between"
                          onClick={() => viewHistory(item)}
                        >
                          <button className="flex justify-between items-center">
                            View History
                          </button>
                          <div>
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
          <button onClick={() => navigate("/app/tution?tab=demo-request&button=demo")} >
            View all

          </button>
        </div>

      </div>

      {activeateModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <div className="flex justify-end ">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={handleActiveClose}
              >
                <span>
                  <MdOutlineClose />
                </span>
                close
              </button>
            </div>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Class Activate</h2>
              </div>
              <div className="">
                {
                  activateData && (
                    // activateData?.map((itm, id) => (
                    <>
                      <div className="space-y-4 relative lg:h-[450px] overflow-y-auto">
                        <ul className="grid md:grid-cols gap-4 p-4 ">
                          <li className="flex flex-col ">
                            <span className=" ">Teaching Location:</span>

                            <input
                              type="text"
                              name="teachingLocation"
                              id="teachingLocation"
                              value={
                                inputChangeField &&
                                  inputChangeField.teachingLocation
                                  ? inputChangeField.teachingLocation
                                  : ""
                              }
                              onChange={(e) => handleTabChangeInput(e)}
                              placeholder="Enter teaching location"
                              className="border border-gray-300 p-2 rounded-lg flex-grow"
                            />
                            {errorInputChangeField &&
                              errorInputChangeField["teachingLocation"] ? (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["teachingLocation"]}
                              </div>
                            ) : null}
                          </li>
                          <li className="flex flex-col">
                            <span className="">Number of Classes:</span>

                            <input
                              type="number"
                              name="numberOfClass"
                              id="numberOfClass"
                              value={
                                inputChangeField &&
                                  inputChangeField.numberOfClass
                                  ? inputChangeField.numberOfClass
                                  : ""
                              }
                              onChange={(e) => handleTabChangeInput(e)}
                              placeholder="Enter number of classes"
                              className="border border-gray-300 p-2 rounded-lg flex-grow"
                            />
                            {errorInputChangeField &&
                              errorInputChangeField["numberOfClass"] ? (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["numberOfClass"]}
                              </div>
                            ) : null}
                          </li>
                          <li className="flex flex-col">
                            <span className="font- px-">Tuition Fee:</span>

                            <input
                              type="number"
                              name="tuitionFee"
                              id="tuitionFee"
                              value={
                                inputChangeField && inputChangeField.tuitionFee
                                  ? inputChangeField.tuitionFee
                                  : ""
                              }
                              onChange={(e) => handleTabChangeInput(e)}
                              placeholder="Enter tution fee"
                              className="border border-gray-300 p-2 rounded-lg flex-grow"
                            />
                            {errorInputChangeField &&
                              errorInputChangeField["tuitionFee"] ? (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["tuitionFee"]}
                              </div>
                            ) : null}
                          </li>
                          <li className="flex flex-col items-">
                            <span className="font -2">Tutor Fee:</span>

                            <input
                              type="number"
                              name="tutorFee"
                              id="tutorFee"
                              value={
                                inputChangeField && inputChangeField.tutorFee
                                  ? inputChangeField.tutorFee
                                  : ""
                              }
                              onChange={(e) => handleTabChangeInput(e)}
                              placeholder="Enter tutor fee"
                              className="border border-gray-300 p-2 rounded-lg flex-grow"
                            />
                            {errorInputChangeField &&
                              errorInputChangeField["tutorFee"] ? (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["tutorFee"]}
                              </div>
                            ) : null}
                          </li>
                          <li className="flex flex-col items-">
                            <span className="font- px-">Remark:</span>
                            <input
                              type="text"
                              name="remark"
                              id="remark"
                              value={
                                inputChangeField && inputChangeField.remark
                                  ? inputChangeField.remark
                                  : ""
                              }
                              onChange={(e) => handleTabChangeInput(e)}
                              placeholder="Enter remark"
                              className="border border-gray-300 p-2 rounded-lg flex-grow"
                            />
                          </li>
                        </ul>
                      </div>
                      <div
                        className="flex justify-center items-center py-4"

                      >
                        <button onClick={() => ApproveHandle({ status: "APPROVED" })} className="text-white  border rounded-full px-6 py-2 bg-[#023565] hover:bg-[#4e7aa3]">
                          Submit
                        </button>
                      </div>
                    </>
                  )
                  // ))
                }
              </div>
            </div>
          </div>
        </div>
      )}

      {assignTutorModal && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[900px] sm:w-full sm:px-2">
            <div className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setAssignTutorModal(false)}
              >
                <span>
                  <MdOutlineClose />
                </span>{" "}
                Close
              </button>
            </div>

            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Assign Tutor</h2>
              </div>
              <section className="grid md:grid-cols-3 grid-cols-1   w-full">
                {suggestTutorBtn.map((itm, id) => (
                  <div key={id} className="w-full">
                    <button
                      className={`px-4 py-2 w-full text-sm font-bold ${assignTutorTab === itm
                        ? "bg-[white] text-[#023565] border border-[#023565]"
                        : "bg-[#D3D3D3]"
                        } hover:bg-gray-300`}
                      onClick={() => handleAssignTutor(itm)}
                    >
                      {itm}
                    </button>
                  </div>
                ))}
              </section>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div>
                  <section className="p-2">
                    {assignTutorTab === "Applicants" && (
                      <div>
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">
                          <table className="min-w-full shadow-md rounded-md overflow-hidden">
                            <thead>
                              {assignTutorList?.tutorAppliedList?.map(
                                (ele, index) => (
                                  <div
                                    key={index}
                                    className="border rounded-xl p-6"
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="flex items-center space-x-6">
                                        <div className="flex items-center space-x-3">
                                          <div>
                                            <img
                                              src={ele?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                              alt="Tutor"
                                              className="w-10 h-10 rounded-full"
                                              onError={(e) => {
                                                e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p>{ele?.name}</p>
                                            <p>{ele?.mobNo}</p>
                                          </div>
                                        </div>
                                        <div>
                                          <FaChevronCircleRight
                                            size={20}
                                            className="cursor-pointer relative text-[#023565]"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                          onClick={() => handleAssign(ele?._id)}
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </thead>
                          </table>
                        </div>
                      </div>
                    )}
                  </section>
                  <section className="p-2">
                    {assignTutorTab === "Suggested Tutors" && (
                      <div>
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">
                          <table className="min-w-full shadow-md rounded-md overflow-hidden">
                            <tbody className="text-gray-600 text-sm font-light">
                              {suggestedTutor ? (
                                suggestedTutor.map((ele, index) => (
                                  <div
                                    key={index}
                                    className="border rounded-xl p-6"
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="flex items-center space-x-6">
                                        <div className="flex items-center space-x-3">
                                          <div>
                                            <img
                                              src={ele?.userDetails?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                              alt="Tutor"
                                              className="w-10 h-10 rounded-full"
                                              onError={(e) => {
                                                e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p>{ele?.userDetails?.name}</p>
                                            <p>{ele?.userDetails?.mobNo}</p>
                                          </div>
                                        </div>
                                        <div>
                                          <FaChevronCircleRight
                                            size={20}
                                            className="cursor-pointer relative text-[#023565]"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                          onClick={() =>
                                            handleAssign(ele?.userDetails?._id)
                                          }
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="text-black">No Data</div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </section>
                  <section className="p-4">
                    {assignTutorTab === "All Tutors" && (
                      <div>
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">
                          <table className="min-w-full shadow-md rounded-md overflow-hidden">
                            <thead>
                              {allTutorDataList ? (
                                allTutorDataList.map((ele, index) => (
                                  <div
                                    key={index}
                                    className="border rounded-xl p-2"
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="flex items-center space-x-6">
                                        <div className="flex items-center space-x-3">
                                          <div>
                                            <img
                                              src={ele?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                              alt="Tutor"
                                              className="w-10 h-10 rounded-full"
                                              onError={(e) => {
                                                e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p>{ele?.name}</p>
                                            <p>{ele?.mobNo}</p>
                                          </div>
                                        </div>
                                        <div>
                                          <FaChevronCircleRight
                                            size={20}
                                            className="cursor-pointer relative text-[#023565]"
                                            onClick={() =>
                                              handleTutorDetails(ele)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                          onClick={() => handleAssign(ele?._id)}
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="text-black">No Data</div>
                              )}
                            </thead>
                          </table>
                        </div>

                      </div>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {tutorDetails && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[800px]">
            <div className="flex justify-end">
              <button
                onClick={() => setTutorDetails(false)}
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              >
                <MdOutlineClose /> Close
              </button>
            </div>

            <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Tutor Details </h2>
              </div>

              <div className="grid md:grid-cols-2 gap-4 p-2">
                <div className="w-full text-center">
                  <img
                    src={tutorData?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                    alt="Tutor"
                    className="w-72 h-72 mx-auto"
                    onError={(e) => {
                      e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                    }}
                  />
                </div>
                <div className="w-full capitalize space-y-2 h-[300px] overflow-hidden overflow-y-auto">
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Name</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{fullData?.name ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Gender</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.gender ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Teaching Exp</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.teachingExp ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Address</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{fullData?.address ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Mobile No</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{fullData?.mobNo ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Email</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{fullData?.email ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">School name</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.schoolName ?? "N/A"}
                    </p>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">ug Course</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.ugCourse ?? "N/A"}
                    </p>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">pgCourse</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.pgCourse ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%] capitalize">
                      Classes
                    </p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      <p>
                        {fullData?.tutorInfoId?.classYouCanTeach
                          ?.map((ele) => ele?.name)
                          .join(", ") ?? "N/A"}
                      </p>
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%] capitalize">
                      Subjects
                    </p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">
                      {fullData?.tutorInfoId?.subYouCanTeach
                        ?.map((ele) => ele?.name)
                        .join(", ") ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectResionModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={handlerejctCloseModal}
              >
                <span>
                  <MdOutlineClose />
                </span>{" "}
                Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Reason</h2>
                </div>
                <div className="relative  overflow-y-auto">
                  <form onSubmit={handleRejectResion} className="p-4">
                    <div className="mb-4">
                      <label
                        htmlFor="skipResion"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Reason for Reject
                      </label>
                      <input
                        type="text"
                        id="skipResion"
                        value={rejectResion}
                        onChange={(e) => setRejectResion(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Enter reason"
                      />
                    </div>
                    <div className="flex justify-center space-x-2">
                      <button
                        type="submit"
                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {studentModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative  mx-auto lg:w-auto">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setStudentModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>
              Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Students</h2>
              </div>
              <div className="relative h-[400px] overflow-y-auto">
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-900 uppercase bg-gray-50 border-b">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Class
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Subject
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentData?.bookFreeDemo?.map((ele, index) => (
                        <tr className="bg-white border-b " key={index}>
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {ele?.studentId?.studentFullName}
                          </th>
                          <td className="px-6 py-4">
                            {ele?.classId?.name}
                          </td>
                          <td className="px-6 py-4">
                            {ele?.subjectId?.map((e, idx) => (
                              <p key={idx}>{e?.name}</p>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

      {subjClassModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative  mx-auto lg:w-auto">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setSubjClassModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{" "}
              Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Class & Subjects: </h2>
              </div>
              <div className="relative lg:h-[200px] overflow-y-auto">
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Class
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Subject
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjClassModalData?.bookFreeDemo?.map((ele, index) => (
                        <tr
                          key={index}
                          className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${index % 2 === 0 ? "bg-white" : "dark:bg-gray-800"
                            }`}
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
                          >
                            {ele?.classId?.name}
                          </th>
                          <td className="px-6 py-4">
                            {ele?.subjectId?.map((subject, subIndex) => (
                              <span key={subIndex}>
                                {subject?.name}
                                {subIndex < ele.subjectId.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="space-y-5 py-10 lg:hidden block overflow-hidden overflow-y-auto">
        {DemoRequestListData && DemoRequestListData.length > 0 ? (
          DemoRequestListData?.map((item, id) => (
            <div className=" bg-white p-4 rounded-[10px] border-b my-2">
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Parent Name
                  </p>
                </div>
                <div className="w-full">
                  <div className=" items-center  gap-2 capitalize">
                    <span>{item?.parentId?.name ?? "No User"}</span> <br></br>{" "}
                    <span>{item?.parentId?.mobNo ?? "No Contact"}</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Student Name
                  </p>
                </div>
                <div className="w-full">
                  <button onClick={() => handleStudentName(item)}>View</button>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Class/Subjects
                  </p>
                </div>
                <div className="w-full">
                  <button onClick={() => handleClassSubj(item)}>View</button>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  City
                  <p className="text-[16px] font-[700] text-left"></p>
                </div>
                <div className="w-full">
                  {item?.cityId?.name ? item?.cityId?.name : "N/A"}
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">Tutor Name</p>
                </div>
                <div className="w-full">
                  <p className="text-[14px] font-[400]">
                    {item?.tutorId?.name ?? "No tutor"}
                  </p>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">Action</p>
                </div>
                <div className="w-full relative z-10">
                  <BsThreeDotsVertical
                    onClick={() => openAction(item._id)}
                    className="text-2xl  text-[#C60E6B] relative cursor-pointer"
                  />

                  <div className=" absolute top-7">
                    {open === item._id && (
                      <div
                        className="  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                        ref={mobileRef}
                      >
                        <div className="py-1 font-semibold text-start ">
                          <ul>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100"
                              onClick={() => handleActivate(item)}
                            >
                              <button className="block px-3 py-2 text-sm text-pink-600  ">
                                Activate
                              </button>
                              <img src="/Group 1000001172.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100 "
                              onClick={() => assignTutor(item)}
                            >
                              <button className="block px-3 py-2  text-sm text-[#023565] ">
                                Assign Tutor
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between items-center hover:bg-gray-100"
                              onClick={() => handleRejectMsg(item)}
                            >
                              <button className="block text-left px-2 py-2  text-sm text-[#023565]  ">
                                Reject
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100 "
                              onClick={() => viewHistory(item)}
                            >
                              <button className="block px-3 py-2  text-sm text-[#023565] ">
                                View History
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>

                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">Data Not Found</p>
        )}
      </section>


      {/* {totalPages2 > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">
              {currentPage}
            </span>{" "}
            of{" "}
            <span className="border px-4 py-2 text-black  rounded">
              {totalPages2}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages2
              ? "opacity-50 cursor-not-allowed"
              : ""
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages2}
          >
            <FaChevronRight />
          </button>
        </div>
      )} */}


      <div
        id="alert-box-rejected"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Rejected!
      </div>

      <div
        id="alert-box-assign"
        className="z-[100]"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Assign!
      </div>
      <div
        id="alert-box-Activated"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Activated!
      </div>
    </div>
  );
};

export default ButtonSecond;
