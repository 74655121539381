import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReferralComponent from "./Referral";
import AppSettingComponent from "./AppSetting";
import Button from './Button'
import ReferalAppSettingsHeader from "./ReferalAppSettingsHeader";
import { appSettingAction, referralAction } from "../../_actions";

const ReferralAppSetting = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [active, setActive] = useState("Referrals")
  const buttonName = ["Referrals", "APP Settings"]
  const handleClick = (data) => {
    setActive(data)
  }

  const [searchText, setSearchText] = useState('')

  const handleSearch = (e) => {
    setSearchText(e.target.value)
    // let keyWord = e.target.value
    // if (active === 'Referral') {
    //   let boardData = {
    //     keyWord: keyWord,
    //     pageNo: 1,
    //     size: 10000000,
    //   };
    //   dispatch(referralAction.referralGet(boardData))
    // } else if (active === 'APP Setting') {
    //   const AppSettingList = {
    //     keyWord: keyWord,
    //     fromDate: "",
    //     toDate: "",
    //     sortOrder: "desc",
    //     sortBy: "createdAt",
    //     pageNo: 1,
    //     size: 10000,
    //   };
    //   dispatch(appSettingAction.appSettingGet(AppSettingList))
    // }
  }
  return (
    <>
      <ReferalAppSettingsHeader handleSearch={handleSearch} searchText={searchText} />
      <section className="overflow-hidden overflow-y-auto">
        <section className="m-5 flex gap-6">
          {
            buttonName.map((btnName, index) => {
              return (
                <div key={index} className={`flex font-semibold text-white px-3 lg:px-10 py-2 rounded-full ${active === btnName ? 'bg-[#023565]' : 'bg-[#9C9EAB]'}`} onClick={() => handleClick(btnName)}>
                  <Button name={btnName} />
                </div>
              )
            })
          }
        </section>
        <section className="">
          {active === "Referrals" && <ReferralComponent searchText={searchText} />}
          {active === "APP Settings" && <AppSettingComponent searchText={searchText} />}
        </section>
      </section>

    </>
  );
};

export default ReferralAppSetting;
