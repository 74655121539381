import React, { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { tutionAction } from '../../../_actions';

const customStyles = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #000',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #000',
    },
    borderRadius: '5px',
  }),
  menu: (provided) => ({
    ...provided,

  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#000 !important',
  }),
};

const AddEditStudentModel = ({ isView, data, handleChange, handleCloseModal,handleSubmitModal }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [errors, setErrors] = useState({})

  useEffect(() => {

    dispatch(tutionAction.gitAllClassListByAdmin());
    dispatch(tutionAction.getAllBoardByAdmin());

  }, [])


  const validateForm = () => {
    const newErrors = {};

    if (!data.studentFullName) {
      newErrors.studentFullName = "Student full name is required.";
    }
    if (!data.dob) {
      newErrors.dob = "Date of birth is required.";
    }
    if (data.grade.value === "") {
      newErrors.grade = "Grade is required.";
    }
    if (!data.schoolName) {
      newErrors.schoolName = "School name is required.";
    }
    if (data.educationBoard.value === "") {
      newErrors.educationBoard = "Education board is required.";
    }
    if (!data.medium) {
      newErrors.medium = "Medium is required.";
    }
    if (!data.gender) {
      newErrors.gender = "Gender is required.";
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const closeModal = () => {
    setErrors({})
    handleCloseModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmitModal()
    }
  };

  return isView && (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
      <div className="relative w-11/12 mx-auto lg:w-[1024px]">
        <button
          className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
          onClick={closeModal}
        >
          <span><MdOutlineClose /></span> Close
        </button>
        <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h2 className="text-lg font-semibold">{data.studentId ? "Update" : "Add"} Student </h2>
          </div>
          <div className="relative lg:h-[400px] overflow-y-auto">
            <div className='grid grid-cols-2 gap-6 p-6'>
              <div className="">
                <label htmlFor="studentFullName">Student Full Name</label>
                <input
                  type="text"
                  name="studentFullName"
                  id="studentFullName"
                  value={data?.studentFullName ?? ''}
                  onChange={(e) => handleChange(e)}
                  className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                />
                {errors.studentFullName && (
                  <span className="text-red-500 text-sm">
                    {errors.studentFullName}
                  </span>
                )}
              </div>
              <div className="">
                <label htmlFor="dob">Date Of Birth</label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={data?.dob ?? ''}
                  onChange={handleChange}
                  className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                />
                {errors.dob && (
                  <span className="text-red-500 text-sm">
                    {errors.dob}
                  </span>
                )}
              </div>
              <div>
                <label>Grade</label>
                <Select
                  // id="tuitionFee"
                  name="grade"
                  className="capitalize "
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={data.grade}
                  options={[
                    { label: "Select Grade", value: "" },
                    ...(selector?.tution?.getAllClassList?.data?.map((classItem) => ({
                      label: classItem.name,
                      value: classItem._id,
                    })) || []),
                  ]}
                  onChange={(selectedOption, actionMeta) => {
                    handleChange({ target: { name: "grade", value: selectedOption } })
                  }}
                />
                {errors.grade && (
                  <span className="text-red-500 text-sm">
                    {errors.grade}
                  </span>
                )}
              </div>
              <div className="">
                <label htmlFor="schoolName">School Name</label>
                <input
                  type="text"
                  name="schoolName"
                  id="schoolName"
                  value={data?.schoolName ?? ''}
                  onChange={handleChange}
                  className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                />
                {errors.schoolName && (
                  <span className="text-red-500 text-sm">
                    {errors.schoolName}
                  </span>
                )}
              </div>
              <div>
                <label>Education Board</label>
                <Select
                  // id="tuitionFee"
                  name="educationBoard"
                  className="capitalize "
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={data.educationBoard}
                  options={[{ label: 'Select Board', value: '' }, ...(selector?.tution?.getAllBoardByAdminList?.data?.map((boardItem) => ({
                    label: boardItem.name,
                    value: boardItem._id,
                  })) || [])]}
                  onChange={(selectedOption, actionMeta) => {
                    handleChange({ target: { name: "educationBoard", value: selectedOption } })
                  }}
                />
                {errors.educationBoard && (
                  <span className="text-red-500 text-sm">
                    {errors.educationBoard}
                  </span>
                )}
              </div>
              <div className="">
                <label htmlFor="subjectName">Medium Of Schooling</label>
                <select
                  name="medium"
                  id="medium"
                  value={data?.medium ?? ''}
                  onChange={handleChange}
                  className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                >
                  <option value="">Medium</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                </select>
                {errors.medium && (
                  <span className="text-red-500 text-sm">
                    {errors.medium}
                  </span>
                )}
              </div>
              <div className="">
                <label htmlFor="subjectName">Gender</label>
                <select
                  name="gender"
                  id="gender"
                  value={data?.gender ?? ''}
                  onChange={handleChange}
                  className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                >
                  <option value="">Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </select>
                {errors.gender && (
                  <span className="text-red-500 text-sm">
                    {errors.gender}
                  </span>
                )}
              </div>

            </div>
            <div className="flex justify-center py-6">
              <button
                type="button"
                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default AddEditStudentModel