import "./Earning.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const EarningTable = ({ feeData }) => {

  const navigate = useNavigate();
  const handleFeeRecived = (data) => {
    navigate(`/app/feerecevied`, { state: { feeData: data } });
  };
  const handleReferencePaid = (data) => {
    navigate(`/app/referralpaid`, { state: { feeReferralData: data } });
  };
  const handleReferralPaid = (data) => {
    navigate(`/app/referencepaid`, { state: { feeReferenceData: data } });
  };
  const handleFeeRefund = (data) => {
    navigate(`/app/feerefund`, { state: { feeRefundData: data } });
  };
  const handleNoFee = (data) => {
    navigate(`/app/NoFee`, { state: { feeData: data } });
  };
  const handletutorFeePaid = (data) => {
    navigate(`/app/tutorfeepaid`, { state: { tutorfeepaidData: data } });
  };
  const handletutorFeePending = (data) => {
    navigate(`/app/tutorfeepending`, { state: { tutorfeependingData: data } });
  };
  return (
    <>
      <div className="overflow-x-auto overflow-y-auto overflow-hidden">
        <table className=" table-auto min-w-full text-center h-4/5 tableForMobile  bg-white rounded-lg  ">
          <thead className="border-b ">
            <tr className="text-sm">
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Month-Year</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Tuition Fee</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Tutor Fee</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Reference Fee</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Refund Tuition <br /> Fee</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Refund From <br /> Tutor</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Refund From  <br /> Reference</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">No Fee</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Fee Received</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Fee Paid</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Fee Pending</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Referral Paid</th>
              <th className="py-4 px-6 whitespace-nowrap table_head text-[#313131]">Revenue</th>
            </tr>
          </thead>
          <tbody>
            {feeData && feeData.length > 0 ? (
              feeData?.map((item, index) => (
                <tr key={index} className="my-2  border-b border-[#D3D3D3] ">
                  <td>{moment(item.month).format("MMMM - YYYY ")}</td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.tuitionFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.tutorFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.referenceFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.refundTuitionFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.refundTutorFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.refundReferenceFee}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                  <td className="py-5">
                    <div className="flex items-center justify-center space-x-2">
                      <div onClick={() => handleNoFee(item)} className="forfontsmall text-[#00AFE2] underline cursor-pointer">
                        {item?.noTuitionFee}{" "}
                      </div>
                      {/* <div className="underline text-[#00AFE2]">
                        <button className="underline" href="#" >View</button>
                      </div> */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center space-x-2">
                      <div onClick={() => handleFeeRecived(item)} className="forfontsmall text-[#00AFE2] underline cursor-pointer">
                        {" "}
                        {item?.feeReceived}{" "}
                      </div>
                      {/* <div className="underline text-[#00AFE2]">
                        <button className="underline" href="#" >View</button>
                      </div> */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center space-x-2">
                      <div onClick={() => handletutorFeePaid(item)} className="forfontsmall text-[#00AFE2] underline cursor-pointer">
                        {" "}
                        {item.feePaid}{" "}
                      </div>
                      {/* <div className="underline ttext-[#00AFE2] text-[#00AFE2]">
                        <button className="underline" href="#" >View</button>
                      </div> */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center space-x-2">
                      <div onClick={() => handletutorFeePending(item)} className="forfontsmall text-[#00AFE2] underline cursor-pointer">
                        {" "}
                        {item?.feePending}{" "}
                      </div>
                      {/* <div className="underline text-[#00AFE2]">
                        <button className="underline" >View</button>
                      </div> */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center space-x-2">
                      <div onClick={() => handleReferencePaid(item)} className="forfontsmall text-[#00AFE2] underline cursor-pointer">
                        {" "}{item?.referralPaid}
                      </div>
                      {/* <div className="underline text-[#00AFE2]">
                        <button className="underline" >View</button>
                      </div> */}
                    </div>
                  </td>
                  <td className="py-5">
                    <div className="forfontsmall text-[#313131]">
                      {item?.Revenue}{" "}
                    </div>
                    <div className="underline text-[#00AFE2]"></div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={14} className="text-center text-[#313131] py-5">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="formobile_viewonly">
          {feeData &&
            feeData?.map((item, id) => {
              return (
                <>
                  <table key={id} className="w-full  my-4  bg-white rounded-[10px]">
                    <tr className="border-b  border-gray-300">
                      <th className="text-left px-4 py-4">
                        <span className="font-[500]">Month-Year</span>
                      </th>
                      <td className="text-auto  py-4 px-4">
                        <p className="font-[400] text-[14px]">{moment(item.month).format("MMMM - YYYY")}</p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Tuition Fee</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.tuitionFee}
                        </p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Tutor Fee	</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.tutorFee}
                        </p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Reference Fee	</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.referenceFee}
                        </p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Refund Tuition Fee</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.refundTuitionFee}
                        </p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Refund From Tutor</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.refundTutorFee}
                        </p>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Refund From Reference</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.refundReferenceFee}
                        </p>
                      </td>
                    </tr>


                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">No Fee</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px] underline decoration-solid decoration-[#C60E6B] text-[#00AFE2]" onClick={() => handleNoFee(item)}>
                          {item?.noTuitionFee}
                        </p>
                        {/* <div className="text-[#00AFE2] font-semibold hover:underline" onClick={() => handleNoFee(item)}>
                          View
                        </div> */}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Fee Received</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px] underline decoration-solid decoration-[#C60E6B] text-[#00AFE2]" onClick={() => handleFeeRecived(item)}>
                          {item?.feeReceived}
                        </p>
                        {/* <div className="text-[#00AFE2] font-semibold hover:underline" onClick={() => handleFeeRecived(item)}>
                          View
                        </div> */}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Fee Paid</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px] underline decoration-solid decoration-[#C60E6B] text-[#00AFE2]" onClick={() => handletutorFeePaid(item)}>
                          {item?.feePaid}
                        </p>
                        {/* <div className="text-[#00AFE2] font-semibold hover:underline" onClick={() => handletutorFeePaid(item)}>
                          View
                        </div> */}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Fee Pending</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px] underline decoration-solid decoration-[#C60E6B] text-[#00AFE2]" onClick={() => handletutorFeePending(item)}>
                          {item?.feePending}
                        </p>
                        {/* <div className="text-[#00AFE2] font-semibold hover:underline" onClick={() => handletutorFeePending(item)}>
                          View
                        </div> */}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Referral Paid</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px] underline decoration-solid decoration-[#C60E6B] text-[#00AFE2]" onClick={() => handleReferencePaid(item)}>
                          {item?.referralPaid}
                        </p>
                        {/* <div className="text-[#00AFE2] font-semibold hover:underline" onClick={() => handleReferencePaid(item)}>
                          View
                        </div> */}
                      </td>
                    </tr>
                    <tr className="">
                      <th className="text-left py-4 px-4">
                        <span className="font-[500]">Revenue</span>
                      </th>
                      <td className="text-auto py-4  px-4 gap-2">
                        <p className="font-[400] text-[14px]">
                          {item?.Revenue}
                        </p>
                      </td>
                    </tr>

                  </table>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default EarningTable;
