import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action";
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Dashboard.css";
import { FaAngleRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { tutionAction, userListAction } from "../../_actions";
import Loader from "../../components/Loader";

const ButtonFirst = ({ setNewStateHandle, demoDoneListData }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [assignTutorList, setAssignTutorList] = useState("");
  const [demoIdForAssign, setDemoIdForAssign] = useState({});
  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");
  const [approvePupup, setApprovePopup] = useState(false);
  const [classApproveId, setClassApproveId] = useState("");
  const [onDemoListData, setOnDemoListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [inputData, setInputData] = useState({});
  let suggestTutorBtn = ["Applicants", "Suggested Tutors", "All Tutors"];
  const [newtutorDetailsData, setNewTutorDetailsData] = useState({});
  const [tutorDetails, setTutorDetails] = useState(false);
  const [suggestedTutor, setSuggestedTutor] = useState({});
  let [allTutorDataList, setAllTutorDataList] = useState();
  let [tutorData, setTutorData] = useState({});
  const [fullData, setFullData] = useState("");
  const [rejectResionModal, setRejectResionModal] = useState(false);
  const [rejectResion, setRejectResion] = useState("");
  const [rejectData, setRejectData] = useState({});

  const menuRef = useRef(null);

  const mobileRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    dispatch(dashboardAction.getLatestDemoRequestList(demoRequestDetails));
  }, [currentPage]);

  useEffect(() => {
    if (selector?.Dashboard?.getLatestDemoRequestList?.data?.total) {
      setTotalPages(
        Math.ceil(
          selector?.Dashboard?.getLatestDemodDoneList?.data?.total / pageSize
        )
      );
    }
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data);
    setAllTutorDataList(
      selector?.tution &&
      selector?.tution?.getAllTutorList &&
      selector?.tution?.getAllTutorList &&
      selector?.tution?.getAllTutorList?.data
    );
    setTutorData(selector?.tution && selector?.tution?.getTutorDetails?.data);
    setFullData(() => selector?.userList?.getTutorUpdateById?.data);
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const openAction = (id) => {
    setOpen(id === open ? null : id);
  };

  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode == 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query));

    setAssignTutorModal(true);
    setAssignTutorList(data);
    setDemoIdForAssign(data._id);
    setOpen(false);
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };
  const handleAssign = (data) => {
    let Demodata = {
      status: 0,
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };

    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    dispatch(
      tutionAction.changeTutorByAdmin(
        assignTutorInfo,
        Demodata,
        demoRequestDetails
      )
    );
    dispatch(tutionAction.getTuitionCount());
    setOpen(false);
    setAssignTutorModal(false);

    const alertBox3 = document.getElementById("alert-box-assign");
    alertBox3.style.display = "block";
    setTimeout(() => {
      alertBox3.style.display = "none";
    }, 2000);

    setOpen(false);
  };

  const handleClassApprove = (data) => {
    setApprovePopup(true);
    setOnDemoListData(data);
    setClassApproveId(data?._id);
    setInputData(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "numberOfClasses") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    if (name === "paymentMode") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    if (name === "startTime") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    if (name === "dueDate" && value > 30) {
      setInputData((prevData) => ({
        ...prevData,
        [name]: 31,
      }));
    } else setInputData({ ...inputData, [name]: value });
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    if (!inputData.paymentMode)
      errors.paymentMode = "Payment mode is required.";
    if (!inputData.numberOfClasses || inputData.numberOfClasses <= 0)
      errors.numberOfClasses = "Number of classes must be a positive number.";
    if (!inputData.dueDate || inputData.dueDate <= 0 || inputData.dueDate > 31)
      errors.dueDate = "Due date must be between 1 and 31.";
    if (!inputData.startTime) errors.startTime = "Start date is required.";
    if (inputData.tuitionFee <= 0)
      errors.tuitionFee = "Tuition fee must be a positive number.";
    if (inputData.referenceFee < 0)
      errors.referenceFee = "Reference fee cannot be negative.";
    if (inputData.tutorFee < 0)
      errors.tutorFee = "Tutor fee cannot be negative.";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitTuition = () => {
    if (!validateForm()) return;

    const companyShare =
      Number(inputData.tuitionFee || 0) -
      Number(inputData.referenceFee || 0) -
      Number(inputData.tutorFee || 0);

    let demoData = {
      status: [6, 7, 8],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    const resData = {
      demoId: classApproveId || null,
      paymentMode: inputData.paymentMode || null,
      numberOfClasses: inputData.numberOfClasses || null,
      dueDate: inputData.dueDate || null,
      startTime: inputData.startTime || null,
      tuitionFee: inputData.tuitionFee || 0,
      referenceFee: inputData.referenceFee || 0,
      tutorFee: inputData.tutorFee || 0,
      companyShare: companyShare || 0,
    };

    dispatch(tutionAction.createTuition(resData, demoData, demoRequestDetails));
    dispatch(tutionAction.getTuitionCount());

    setApprovePopup(false);
    const alertBoxap = document.getElementById("alert-box-approve");
    alertBoxap.style.display = "block";
    setTimeout(() => {
      alertBoxap.style.display = "none";
    }, 2000);

    setOpen(false);
  };

  const companyShare =
    Number(inputData && inputData.tuitionFee ? inputData.tuitionFee : 0) -
    Number(inputData && inputData.referenceFee ? inputData.referenceFee : 0) -
    Number(inputData && inputData.tutorFee ? inputData.tutorFee : 0);
  const handleReject = (data) => {
    setRejectResionModal(true);
    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };
    setRejectData(acceptedData);
  };

  const handleRejectResion = (e) => {
    e.preventDefault();

    let demoData = {
      status: [0],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };

    let data = {
      ...rejectData,
      remark: rejectResion,
    };

    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    dispatch(
      tutionAction.updateStatusDemoApproveOrReject(
        data,
        demoData,
        demoRequestDetails
      )
    );
    setRejectResionModal(false);
    setRejectResion("");

    const alertBox4 = document.getElementById("alert-box-reject");
    alertBox4.style.display = "block";
    setTimeout(() => {
      alertBox4.style.display = "none";
    }, 2000);

    setOpen(false);
  };

  const handleSendToDemoRequest = (data) => {
    let listData = {
      status: [6, 7, 8],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let demorequestData = {
      demoId: data._id,
    };
    let demoRequestDetails = {
      cityId: "",
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };

    dispatch(
      tutionAction.revertDemoClass(
        demorequestData,
        listData,
        demoRequestDetails
      )
    );
    dispatch(tutionAction.getTuitionCount());

    const alertBoxdemo4 = document.getElementById("alert-box-demodone");
    alertBoxdemo4.style.display = "block";
    setTimeout(() => {
      alertBoxdemo4.style.display = "none";
    }, 2000);

    setOpen(false);
  };

  const handleViewHistory = (data) => {
    navigate(`/app/viewhistory/${data?._id}`);
    setOpen(false);
  };

  const handleTutorDetails = (data) => {
    setTutorDetails(true);
    setNewTutorDetailsData(data);
    let tutorId = {
      id: data._id,
    };
    dispatch(userListAction.getTutorById(tutorId));
  };
  const [studentModal, setStudentModal] = useState(false);
  const [studentData, setStudentData] = useState("");
  const [subjClassModal, setSubjClassModal] = useState(false);
  const [subjClassModalData, setSubjClassModalData] = useState("");

  const handleStudentName = (data) => {
    setStudentModal(true);
    setStudentData(data);
  };
  const handleClassSubj = (data) => {
    setSubjClassModal(true);
    setSubjClassModalData(data);
  };

  const handleApproveClose = () => {
    setApprovePopup(false);
    setInputData({
      paymentMode: "",
      numberOfClasses: "",
      dueDate: "",
      startTime: "",
      tuitionFee: "",
      referenceFee: "",
      tutorFee: "",
    });
  };

  const handlerejctCloseModal = () => {
    setRejectResionModal(false);
    setRejectResion("");
  };

  return (
    <>
      <section className="overflow-hidden overflow-y-auto">
        <Loader loading={selector?.tution?.loading} />

        <div className="lg:block hidden">
          <div className="relative overflow-x-auto bg-white rounded-lg">
            <table class="w-full  text-left  bg-white text-[#313131]">
              <thead class="">
                <tr className="capitalize border-b">
                  <th class="px-6 py-3 whitespace-nowrap">S.No</th>
                  <th class="px-6 py-3 whitespace-nowrap">Parent</th>
                  <th class="px-6 py-3 whitespace-nowrap">Student Details</th>
                  <th class="px-6 py-3 whitespace-nowrap">City</th>
                  <th class="px-6 py-3 whitespace-nowrap">Tutor Name</th>
                  <th class="px-6 py-3 whitespace-nowrap">Action</th>
                </tr>
              </thead>
              <tbody className="">
                {demoDoneListData && demoDoneListData.length > 0 ? (
                  demoDoneListData?.map((item, id) => (
                    <tr class="bg-white capitalize" key={id}>
                      <td class=" px-6 py-3 text-[12px]">{id + 1}</td>
                      <td class=" font-[400] text-[12px]  text-black py-3 px-6  gap-2">
                        <span>{item?.parentId?.name ?? "No User"}</span>
                        <br></br>
                        <span>{item?.parentId?.mobNo ?? "No Contact"}</span>
                      </td>

                      <td class=" px-6 py-3 text-[12px]" onClick={() => handleStudentName(item)}>
                        View
                      </td>


                      <td class="px-6 py-3 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                        {item?.cityId?.name ? item?.cityId?.name : "N/A"}
                      </td>
                      <td class="px-6 py-3 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                        {item?.tutorId?.name ?? "No tutor"}<br></br>
                        {item?.tutorId?.mobNo ?? "No tutor"}
                      </td>
                      <td className=" text-black py-3 px-6 cursor-pointer text-2xl relative">
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          className="text-[#C60E6B]"
                        />

                        {open === item._id && (
                          <div className="fixed -right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-50" ref={menuRef}>
                            <ul className="cursor-pointer text-sm font-semibold">
                              <li
                                className="flex items-center cursor-pointer hover:bg-gray-100 hover:text-[#C60E6B] p-3 rounded-t-lg text-[#023565]"
                                onClick={() => assignTutor(item)}
                              >
                                Change Tutor
                                <span className="ml-auto font-semibold text-[#023565]">
                                  <FaAngleRight className="hover:text-[#C60E6B]" />

                                </span>
                              </li>
                              <li
                                className="flex items-center cursor-pointer hover:bg-gray-100 hover:text-[#C60E6B] p-3 rounded-t-lg text-[#023565]"
                                onClick={() => handleClassApprove(item)}
                              >
                                Class Approved
                                <span className="ml-auto font-semibold ">
                                  <FaAngleRight className="hover:text-[#C60E6B]" />

                                </span>
                              </li>
                              <li
                                className="flex items-center cursor-pointer hover:bg-gray-100 hover:text-[#C60E6B] p-3 rounded-t-lg text-[#023565]"
                                onClick={() => handleSendToDemoRequest(item)}
                              >
                                Send to Demo Request
                                <span className="ml-auto font-semibold ]">
                                  <FaAngleRight className="hover:text-[#C60E6B]" />

                                </span>
                              </li>
                              <li
                                className="flex items-center cursor-pointer hover:bg-gray-100 hover:text-[#C60E6B] p-3 rounded-t-lg text-[#023565]"
                                onClick={() => handleReject(item)}
                              >
                                Reject
                                <span className="ml-auto font-semibold ">
                                  <FaAngleRight className="hover:text-[#C60E6B]" />

                                </span>
                              </li>
                              <li
                                className="flex items-center cursor-pointer hover:bg-gray-100 hover:text-[#C60E6B] p-3 rounded-t-lg text-[#023565]"
                                onClick={() => handleViewHistory(item)}
                              >
                                View History
                                <span className="ml-auto font-semibold">
                                  <FaAngleRight className="hover:text-[#C60E6B]" />
                                </span>
                              </li>

                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
              <button onClick={() => navigate("/app/tution?tab=demo-done&button=demo")} >View all</button>
            </div>
          </div>

        </div>
      </section>
      {approvePupup && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px] ">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={handleApproveClose}
            >
              <span>
                <MdOutlineClose />
              </span>
              Close
            </button>

            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Class Approve</h2>
              </div>
              <div className="relative h-[420px] overflow-y-auto">
                <div className="grid md:grid-cols-2 gap-4 p-4">
                  <div>
                    <label
                      htmlFor="paymentMode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Payment Mode
                    </label>
                    <select
                      id="paymentMode"
                      name="paymentMode"
                      className="mt-1 p-2.5 border block w-full border-gray-300 rounded-md bg-white"
                      value={inputData.paymentMode || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="ONLINE">ONLINE</option>
                      <option value="CASH">CASH</option>
                    </select>
                    {validationErrors.paymentMode && (
                      <div className="text-xs text-red-600">
                        {validationErrors.paymentMode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="numberOfClasses"
                      className="block text-sm font-medium text-gray-700"
                    >
                      No Of Classes
                    </label>
                    <input
                      type="number"
                      id="numberOfClasses"
                      name="numberOfClasses"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.numberOfClasses || ""}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.numberOfClasses && (
                      <div className="text-xs text-red-600">
                        {validationErrors.numberOfClasses}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="dueDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Due Date
                    </label>
                    <input
                      type="number"
                      id="dueDate"
                      name="dueDate"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.dueDate || ""}
                      onChange={handleInputChange}
                      required
                      max={31}
                    />
                    {validationErrors.dueDate && (
                      <div className="text-xs text-red-600">
                        {validationErrors.dueDate}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="startTime"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Start Date
                    </label>

                    <input
                      type="number"
                      id="startTime"
                      name="startTime"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.startTime || ""}
                      onChange={handleInputChange}
                      required
                      max={31}
                    />
                    {validationErrors.startTime && (
                      <div className="text-xs text-red-600">
                        {validationErrors.startTime}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="tuitionFee"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tuition Fee
                    </label>
                    <input
                      type="number"
                      id="tuitionFee"
                      name="tuitionFee"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.tuitionFee || ""}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.tuitionFee && (
                      <div className="text-xs text-red-600">
                        {validationErrors.tuitionFee}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="referenceFee"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Reference Fee
                    </label>
                    <input
                      type="number"
                      id="referenceFee"
                      name="referenceFee"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.referenceFee || ""}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.referenceFee && (
                      <div className="text-xs text-red-600">
                        {validationErrors.referenceFee}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="tutorFee"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tutor Fee
                    </label>
                    <input
                      type="number"
                      id="tutorFee"
                      name="tutorFee"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={inputData.tutorFee || ""}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.tutorFee && (
                      <div className="text-xs text-red-600">
                        {validationErrors.tutorFee}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="companyShare"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Share
                    </label>
                    <input
                      type="number"
                      id="companyShare"
                      name="companyShare"
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      value={companyShare || 0}
                      readOnly
                    />
                  </div>
                </div>

                <div className="flex justify-center items-center py-2">
                  <button
                    onClick={submitTuition}
                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      <div
        id="alert-box-demodone"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Send To Demo Request!
      </div>
      <div
        id="alert-box-reject"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition rejected!
      </div>

      <div
        id="alert-box-assign"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Assign!
      </div>
      <div
        id="alert-box-approve"
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "yellow",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Tuition Approve!
      </div>

      {assignTutorModal && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[900px] sm:w-full sm:px-2">
            <div className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setAssignTutorModal(false)}
              >
                <span>
                  <MdOutlineClose />
                </span>
                Close
              </button>
            </div>

            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Assign Tutor</h2>
              </div>
              <section className="grid md:grid-cols-3 grid-cols-1   w-full">
                {suggestTutorBtn.map((itm, id) => (
                  <div key={id} className="w-full">
                    <button
                      className={`px-4 py-2 w-full text-sm font-bold ${assignTutorTab === itm
                        ? "bg-[white] text-[#023565] border border-[#023565]"
                        : "bg-[#D3D3D3]"
                        } hover:bg-gray-300`}
                      onClick={() => handleAssignTutor(itm)}
                    >
                      {itm}
                    </button>
                  </div>
                ))}
              </section>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div>
                  <section className="p-2">
                    {assignTutorTab === "Applicants" && (
                      <div className="h-[25vh] overflow-y-auto">
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <table className="min-w-full shadow-md rounded-md overflow-hidden">
                          <thead>
                            {assignTutorList?.tutorAppliedList?.map(
                              (ele, index) => (
                                <div
                                  key={index}
                                  className="border rounded-xl p-6"
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="flex items-center space-x-6">
                                      <div className="flex items-center space-x-3">
                                        <div>
                                          <img
                                            src={ele?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                            alt="Tutor"
                                            className="w-10 h-10 rounded-full"
                                            onError={(e) => {
                                              e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <p>{ele?.name}</p>
                                          <p>{ele?.mobNo}</p>
                                        </div>
                                      </div>
                                      <div>
                                        <FaChevronCircleRight
                                          size={20}
                                          className="cursor-pointer relative text-[#023565]"
                                          onClick={() =>
                                            handleTutorDetails(ele)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                        onClick={() => handleAssign(ele?._id)}
                                      >
                                        Assign
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </thead>
                        </table>
                      </div>
                    )}
                  </section>
                  <section className="p-2">
                    {assignTutorTab === "Suggested Tutors" && (
                      <div className="h-[25vh] overflow-y-auto">
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <div className="">
                          <table className="min-w-full shadow-md rounded-md overflow-hidden">
                            <tbody className="text-gray-600 text-sm font-light">
                              {suggestedTutor ? (
                                suggestedTutor.map((ele, index) => (
                                  <div
                                    key={index}
                                    className="border rounded-xl p-6"
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="flex items-center space-x-6">
                                        <div className="flex items-center space-x-3">
                                          <div>
                                            <img
                                              src={ele?.userDetails?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                              alt="Tutor"
                                              className="w-10 h-10 rounded-full"
                                              onError={(e) => {
                                                e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p>{ele?.userDetails?.name}</p>
                                            <p>{ele?.userDetails?.mobNo}</p>
                                          </div>
                                        </div>
                                        <div>
                                          <FaChevronCircleRight
                                            size={20}
                                            className="cursor-pointer relative text-[#023565]"
                                            onClick={() =>
                                              handleTutorDetails(ele)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                          onClick={() =>
                                            handleAssign(ele?.userDetails?._id)
                                          }
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="text-black">No Data</div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </section>
                  <section className="p-4">
                    {assignTutorTab === "All Tutors" && (
                      <div className="h-[25vh] overflow-hidden overflow-y-auto">
                        <div>
                          <form className="flex items-center max-w-sm mx-auto">
                            <label htmlFor="simple-search" className="sr-only">
                              Search
                            </label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Search..."
                              />
                            </div>
                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                        </div>
                        <table className="min-w-full shadow-md rounded-md overflow-hidden">
                          <thead>
                            {allTutorDataList ? (
                              allTutorDataList.map((ele, index) => (
                                <div
                                  key={index}
                                  className="border rounded-xl p-2"
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="flex items-center space-x-6">
                                      <div className="flex items-center space-x-3">
                                        <div>
                                          <img
                                            src={ele?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                                            alt="Tutor"
                                            className="w-10 h-10 rounded-full"
                                            onError={(e) => {
                                              e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                                            }}
                                          />

                                        </div>
                                        <div>
                                          <p>{ele?.name}</p>
                                          <p>{ele?.mobNo}</p>
                                        </div>
                                      </div>
                                      <div>
                                        <FaChevronCircleRight
                                          size={20}
                                          className="cursor-pointer relative text-[#023565]"
                                          onClick={() =>
                                            handleTutorDetails(ele)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                        onClick={() => handleAssign(ele?._id)}
                                      >
                                        Assign
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="text-black">No Data</div>
                            )}
                          </thead>
                        </table>
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {tutorDetails && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[800px]">
            <div className="flex justify-end">
              <button
                onClick={() => setTutorDetails(false)}
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              >
                <MdOutlineClose /> Close
              </button>
            </div>

            <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Tutor Details </h2>
              </div>

              <div className="grid md:grid-cols-2 gap-4 p-2">
                <div className="w-full text-center">
                  <img
                    src={tutorData?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                    alt="Tutor"
                    className="w-72 h-72 mx-auto"
                    onError={(e) => {
                      e.target.src = "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                    }}
                  />
                </div>
                <div className="w-full capitalize space-y-2 h-[300px] overflow-hidden overflow-y-auto">
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Name</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">{fullData?.name ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Gender</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.gender ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Teaching Exp</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.teachingExp ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Address</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">{fullData?.address ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Mobile No</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">{fullData?.mobNo ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Email</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">{fullData?.email ?? "N/A"}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">School name</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.schoolName ?? "N/A"}
                    </p>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">ug Course</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.ugCourse ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">pg Course</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.pgCourse ?? "N/A"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Classes</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      <p>
                        {fullData?.tutorInfoId?.classYouCanTeach
                          ?.map((ele) => ele?.name)
                          .join(", ") ?? "N/A"}
                      </p>
                    </p>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%] capitalize">
                      Subjects
                    </p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%] break-all">
                      {fullData?.tutorInfoId?.subYouCanTeach
                        ?.map((ele) => ele?.name)
                        .join(", ") ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {studentModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative  mx-auto lg:w-auto w-11/12">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setStudentModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>
              Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Students</h2>
              </div>
              <div className="relative h-[400px] overflow-y-auto">
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-900 uppercase bg-gray-50 border-b">
                      <tr>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                          Class
                        </th>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                          Subject
                        </th>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                          Board
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentData?.bookFreeDemo?.map((ele, index) => (
                        <tr className="bg-white border-b " key={index}>
                          <th scope="row" className="px-6 py-4 font-medium capitalize whitespace-nowrap">
                            {ele?.studentId?.studentFullName}
                          </th>
                          <td className="px-6 py-4  capitalize whitespace-nowrap">
                            {ele?.classId?.name}
                          </td>
                          <td className="px-6 py-4 break-all capitalize">
                            {ele?.subjectId?.map((e, idx) => (
                              <p key={idx}>{e?.name}</p>
                            ))}
                          </td>
                          <td className="px-6 py-4  capitalize whitespace-nowrap">
                            {ele?.board?.name ?? 'N/A'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

      {subjClassModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative  mx-auto lg:w-auto">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setSubjClassModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{" "}
              Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> Class & Subjects: </h2>
              </div>
              <div className="relative lg:h-[200px] overflow-y-auto">
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Class
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Subject
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjClassModalData?.bookFreeDemo?.map((ele, index) => (
                        <tr
                          key={index}
                          className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${index % 2 === 0 ? "bg-white" : "dark:bg-gray-800"
                            }`}
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
                          >
                            {ele?.classId?.name}
                          </th>
                          <td className="px-6 py-4">
                            {ele?.subjectId?.map((subject, subIndex) => (
                              <span key={subIndex}>
                                {subject?.name}
                                {subIndex < ele.subjectId.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectResionModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={handlerejctCloseModal}
              >
                <span>
                  <MdOutlineClose />
                </span>{" "}
                Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Reason</h2>
                </div>
                <div className="relative  overflow-y-auto">
                  <form onSubmit={handleRejectResion} className="p-4">
                    <div className="mb-4">
                      <label
                        htmlFor="skipResion"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Reason for Reject
                      </label>
                      <input
                        type="text"
                        id="skipResion"
                        value={rejectResion}
                        onChange={(e) => setRejectResion(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Enter reason"
                      />
                    </div>
                    <div className="flex justify-center space-x-2">
                      <button
                        type="submit"
                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <section className="space-y-5 py-10 lg:hidden block">
        {demoDoneListData && demoDoneListData.length > 0 ? (
          demoDoneListData?.map((item, id) => (
            <div className="bg-white border p-4 rounded-[10px]">

              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Parent Name
                  </p>
                </div>
                <div className="w-full">
                  <div className=" items-center  gap-2 capitalize">
                    <span>{item?.parentId?.name ?? "No User"}</span> <br></br>{" "}
                    <span className="text-[12px]">{item?.parentId?.mobNo ?? "No Contact"}</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Student Name
                  </p>
                </div>
                <div className="w-full">
                  <button onClick={() => handleStudentName(item)}>
                    View
                  </button>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  City
                  <p className="text-[16px] font-[700] text-left"></p>
                </div>
                <div className="w-full">
                  {item?.cityId?.name ? item?.cityId?.name : "N/A"}
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">
                    Tutor Name
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-[14px] font-[400]">
                    {item?.tutorId?.name ?? "No tutor"}
                  </p>
                </div>
              </div>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[16px] font-[700] text-left">Action</p>
                </div>
                <div className="w-full relative z-10">
                  <BsThreeDotsVertical
                    onClick={() => openAction(item._id)}
                    className="text-2xl  text-[#C60E6B] relative cursor-pointer"
                  />

                  <div className=" absolute top-7">
                    {open === item._id && (
                      <div
                        className="  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                        ref={mobileRef}
                      >
                        <div className="py-1 font-semibold text-start ">
                          <ul>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100"
                              onClick={() => assignTutor(item)}
                            >
                              <button className="block px-3 py-2 text-sm text-pink-600  ">
                                Change Tutor
                              </button>
                              <img src="/Group 1000001172.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100 "
                              onClick={() => handleClassApprove(item)}
                            >
                              <button className="block px-3 py-2  text-sm text-[#023565] ">
                                Class Approved
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between items-center hover:bg-gray-100"
                              onClick={() => handleSendToDemoRequest(item)}
                            >
                              <button className="block text-left px-2 py-2  text-sm text-[#023565]  ">
                                Send to Demo Request
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>
                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100 "
                              onClick={() => handleReject(item)}
                            >
                              <button className="block px-3 py-2  text-sm text-[#023565] ">
                                Reject
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>

                            <li
                              className="flex justify-between place-items-center hover:bg-gray-100"
                              onClick={() => handleViewHistory(item)}
                            >
                              <button className="block px-3 py-2  text-sm text-[#023565]  ">
                                View History
                              </button>
                              <img src="/Group 1000001230.png" alt="" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          ))
        ) : (
          <p className="text-center">Data Not Found</p>
        )}
      </section>


      {/* {totalPages > 1 && (
				<div className="flex justify-end items-center py-2 px-4">
					<button
						className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
							}`}
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
					>
						<FaChevronLeft />
					</button>
					<span className="text-gray-700 mx-1">
						<span className="border px-4 py-2 text-black  rounded">
							{currentPage}
						</span>{" "}
						of{" "}
						<span className="border px-4 py-2 text-black  rounded">
							{totalPages}
						</span>
					</span>
					<button
						className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
							? "opacity-50 cursor-not-allowed"
							: ""
							}`}
						onClick={handleNextPage}
						disabled={currentPage === totalPages}
					>
						<FaChevronRight />
					</button>
				</div>
			)} */}
    </>
  );
};
export default ButtonFirst;
