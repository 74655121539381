
import React from 'react'

export default function ButtonCom({ name, onClick, value }) {
  return (
    <div>
      {
        value === 0 ? <button className='' onClick={onClick}>{name} ({value})</button> : <button className='' onClick={onClick}>{name} ({value ?? 0})</button>
      }

    </div>
  )
}
