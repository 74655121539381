/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticktAction, userListAction } from "../../_actions";
import { FaRocketchat } from "react-icons/fa";
import './Helpsupport.css'
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Loader from "../../components/Loader";
import HelpAndSupportHeader from "./HelpAndSupportHeader";
import { MdOutlineClose, MdRefresh } from "react-icons/md";
import { timeZoneReturnDateType } from "../../_helpers";
import { cityAction } from "../../_actions/city.action";
import { FaFilterCircleXmark } from 'react-icons/fa6';
import Select from "react-select";
import DateRangePicker from "../../components/Atoms/DateRangePicker";

const HelpAndSupport = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [ticketData, setTicketData] = useState([]);
  let [ticketReply, setTicketReply] = useState();
  const [msgModal, setMsgModal] = useState(false);
  const [msgData, setMstData] = useState([]);
  const [reply, setReply] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  let endmsgUseRef = useRef(null)
  const [serachText, setSearchText] = useState('')
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  const [userId, setUserId] = useState({ label: "Select User", value: "" })

  const [dates, setDates] = useState({ fromDate: '', toDate: '' });
  const [status, setStatus] = useState("");

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };


  useEffect(() => {
    if (endmsgUseRef.current) {
      endmsgUseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticketReply]);
  useEffect(() => {
    let details = {
      "cityId": "",
      "isDisable": false
    }
    let cityDetails = {
      isManagement: true
    };
    dispatch(userListAction.getUserTypeList(details))
    dispatch(cityAction.cityGet(cityDetails));
  }, []);

  const options = selector?.userList?.getUserListForSearch?.data ? selector?.userList?.getUserListForSearch?.data.map((ele) => ({
    value: ele?._id,
    label: `${ele?.name} - ${ele?.mobNo}`
  })) : [];

  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };



  useEffect(() => {
    let ticketData = {
      keyWord: serachText,
      sortOrder: "desc",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      cityId: headerCity != "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      ...dates,
      status : status
    };

    dispatch(ticktAction.ticketGet(ticketData));
  }, [currentPage, serachText, headerCity, userId, dates,status]);

  useEffect(() => {
    if (selector?.Ticket?.getTicketList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Ticket?.getTicketList?.data?.total / pageSize));
    }
    setTicketData(selector?.Ticket?.getTicketList?.data?.list)
    setTicketReply(selector?.Ticket?.ticketReplyList?.data)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleChats = (data) => {
    setMstData([data]);
    handelOnClickChatIcon(data?._id);
    setMsgModal(true);
  };

  const handleSubmit = (e, data) => {
    e.preventDefault();
    let replyData = {
      ticketId: data._id,
      msg: reply,
    };
    dispatch(ticktAction.ticketReply(replyData));
    setReply("");
  };

  const handelOnClickChatIcon = (id) => {
    dispatch(ticktAction.getMsgListTicketById({ ticketId: id }));
  };

  const handleAction = (data) => {
    let ticketData = {
      keyWord: serachText,
      ...dates,
      sortOrder: "desc",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      cityId: headerCity != "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      status : status

    };
    let actionId = {
      id: data,
    };
    dispatch(ticktAction.resovleTicket(actionId, ticketData));

  };


  const refreshChat = (id) => {
    dispatch(ticktAction.getMsgListTicketById({ ticketId: id }));
  }


  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleCitySearch = (e) => {
    setHeaderCity(e.target.value)
  }



  return (

    <>
      <HelpAndSupportHeader handleSearch={handleSearch} serachText={serachText} headerCity={headerCity} handleCitySearch={handleCitySearch} roleId={roleId} />
      <Loader loading={selector?.Ticket?.loading} />
      <div className="flex justify-end items-center gap-5 mx-9 mt-2">
        <DateRangePicker
          fromDate={dates.fromDate}
          toDate={dates.toDate}
          handleChange={handleChange}
        />
        <Select
          id="userId"
          name="userId"
          value={userId}
          options={[{ label: "Select User", value: "" }, ...options]}
          className="capitalize text-black "
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={(selectedOption, actionMeta) => {
            setUserId(selectedOption)
          }}
        />
        <select className="flex items-center justify-center bg-white border-2 text-[#000] rounded-lg py-2.5 px-3"
          value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="">Select Status</option>
          <option value="PENDING">Pending</option>
          <option value="RESOLVED">Resolved</option>
        </select>
        <button className="text-center font-bold">
          <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" onClick={() => {
            setUserId({ label: "Select User", value: "" }); setDates({ fromDate: '', toDate: '' });setStatus("")
          }} />
        </button>
      </div>

      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5" >
          <section className="tickect border border-[#D3D3D3] bg-white rounded-lg lg:block hidden">
            <div className="relative overflow-x-auto  mt-4">
              <table className="w-full text-sm text-left rtl:text-right text-black shadow-md">
                <thead className="bg-white">
                  <tr className="border-b-2 table_head capitalize" >
                    <th scope="col" className="px-6 py-3 w-[10%]" >
                      S.No
                    </th>
                    <th scope="col" className="px-6 py-3 " >
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 " >
                      Profile
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      Subject
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      City
                    </th>
                    <th scope="col" className="px-8 py-3 ">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      Chat
                    </th>
                    <th scope="col" className="px-8 py-3  ">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ticketData && ticketData.length > 0 ? (ticketData?.map((ticketData, id) => (
                    <tr
                      className=" border-b capitalize forfontsmall"
                      key={id}
                    >
                      <th
                        scope="row"
                        className="px-8 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {(currentPage - 1) * pageSize + id + 1}
                      </th>
                     
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <tr className="text-[#023565] font-semibold">{ticketData && ticketData?.userId && ticketData?.userId?.name ? ticketData?.userId?.name : '-'}</tr>
                        <tr>{ticketData && ticketData?.userId && ticketData?.userId?.mobNo ? ticketData?.userId?.mobNo : ''}</tr>
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {ticketData && ticketData?.userId && ticketData?.userId?.userType ? ticketData?.userId?.userType === "PARENT" ? "Parent" : "Tutor" : '-'}
                      </th>
                      <td className="px-6 py-4">{ticketData?.title ?? '-'}</td>
                      <td className="px-6 py-4">{ticketData?.cityId?.name ? ticketData.cityId.name : '-'}</td>
                      <td className="px-6 py-4">{timeZoneReturnDateType(ticketData?.createdAt)}</td>

                      <td className="px-8 py-4  text-center">
                        <FaRocketchat size={30}
                          className="text-blue-500 cursor-pointer "
                          onClick={() => handleChats(ticketData)}
                        />
                      </td>
                      <td
                        className="px-6 py-4 font-semibold"

                      >
                        <button
                          className={
                            ticketData.status === 0 ? "bg-[#9C9EAB] cursor-pointer  text-red-700 px-3 py-1 rounded-full" : "bg-[#023565] text-white px-3 py-1 rounded-full"
                          }
                          disabled={ticketData.status !== 0}
                          onClick={() => handleAction(ticketData._id)}
                        >
                          {ticketData.status === 0 ? "Pending" : "Resolved"}
                        </button>

                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5" className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                        Data Not Available
                      </td>
                    </tr>
                  )

                  }
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2 px-4">
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                </span>
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </section>
          <div id="alert-box" style={{ display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "yellow", padding: "10px", borderRadius: "10px" }}>
            Status Updated!
          </div>
          {msgModal && (
            // <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            //   <div className="xl:w-2/6 w-11/12 h-auto mx-auto border-2 shadow-lg bg-white p-1 rounded-lg">
            //     <div className="flex gap-1 justify-end">
            //       <p
            //         className="cursor-pointer text-end flex justify-end text-2xl"
            //         onClick={() => setMsgModal(false)}
            //       >
            //         <FaWindowClose className="text-end text-[#023565] " />
            //       </p>
            //     </div>
            //     <div className="flex justify-start place-items-center">
            //       <p className="px-10 text-[#023565] font-semibold">Chat</p>
            //     </div>
            //     <div className="modal_body py-10 rounded relative">
            //       {msgData?.map((item, id) => (
            //         <div key={id} className="flex flex-col gap-4 mb-2 relative">
            //           <div className="border p-3 h-80 overflow-y-auto rounded-md">
            //             <div className="absolute w-30  -top-3 ">
            //               <span className="font-semibold  pr-2 table_head">
            //                 Subject: {item?.title}
            //               </span>
            //               <div className="flex justify-end ">
            //                 <p className="cursor-pointer absolute text-end -top-[4.7rem] left-[32rem] flex justify-end text-2xl" onClick={() => refreshChat(item._id)}>
            //                   <RiRefreshFill color="#023565" size={26} />
            //                 </p>
            //               </div>

            //             </div>

            //             <div className="py-6">

            //               <div className="flex flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl dark:bg-gray-700">
            //                 <div className="flex justify-start space-x-2  rtl:space-x-reverse">
            //                   <span className="text-sm font-semibold  text-[#313131] dark:text-white">
            //                     {item?.userId?.name}

            //                   </span>

            //                 </div>
            //                 <div className="">
            //                   <p className="text-sm text-start font-normal text-[#313131] dark:text-white">
            //                     {item?.msg}
            //                   </p>
            //                   <span className="flex justify-end text-sm font-normal text-white">
            //                     {moment(item?.createdAt).format('DD-MM-YYYY hh:mm a')}
            //                   </span>
            //                 </div>

            //               </div>

            //               <div className="flex flex-col">
            //                 <div className="w-3/6 flex flex-col  text-white ">
            //                   {ticketReply &&
            //                     ticketReply.map((reply, id) => {

            //                       return String(reply.isAdmin) === "1" ? (
            //                         <div className="flex  justify-start flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl dark:bg-gray-700" key={id}>
            //                           <div className="flex justify-start space-x-2  rtl:space-x-reverse">
            //                             <span className="text-sm font-semibold  text-[#313131] dark:text-white">
            //                               {reply?.userId?.name}

            //                             </span>

            //                           </div>
            //                           <div className="">
            //                             <p className="text-sm text-start font-normal text-[#313131] dark:text-white">
            //                               {reply?.msg}
            //                             </p>
            //                             <span className="flex justify-end text-black">
            //                               {reply?.createdAt ? moment(reply?.createdAt).format('DD-MM-YYYY hh:mm a') : "N/A"}
            //                             </span>
            //                           </div>

            //                         </div>
            //                       ) :
            //                         (<div
            //                           key={id}
            //                           className="flex border-2 justify-end ms-[100%] flex-col w-full p-4 mt-1 bg-[#023565] rounded-xl dark:bg-gray-700"
            //                         >
            //                           <span className="font-bold text-green-600 table_head">
            //                             Admin
            //                           </span>
            //                           <div>
            //                             <p className="text-sm font-normal text-white dark:text-white break-all">
            //                               {reply?.msg}
            //                               <span className="flex justify-end">
            //                                 {moment(reply?.createdAt).format('DD-MM-YYYY h:mm a')}

            //                               </span>
            //                               <span ref={endmsgUseRef} />
            //                             </p>
            //                           </div>

            //                         </div>)
            //                     })}
            //                 </div>
            //               </div>
            //             </div>
            //           </div>

            //           <div className="mt-0">
            //             <form
            //               className="flex items-center justify-center max-w-lg mx-auto"
            //               onSubmit={(e) => handleSubmit(e, item)}
            //             >
            //               <div className="relative w-full">
            //                 <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            //                 </div>
            //                 <input
            //                   type="text"
            //                   value={reply}
            //                   className="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            //                   placeholder="Reply..."
            //                   onChange={(e) => setReply(e.target.value)}
            //                   style={{ height: 'auto', minHeight: '2.5rem' }}
            //                   required
            //                 />
            //                 <button
            //                   type="button"
            //                   className="absolute inset-y-0 right-0 flex items-center pr-3"
            //                 >

            //                 </button>
            //               </div>
            //               <button
            //                 type="submit"
            //                 className="inline-flex items-center py-2.5 px-6 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            //               >
            //                 Send
            //               </button>
            //             </form>
            //           </div>
            //         </div>
            //       ))}
            //     </div>
            //   </div>
            // </div>

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setMsgModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {msgData?.map((item, id) => (
                    <>
                      <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h2 className="text-lg font-semibold">Chat</h2>
                        <p className="cursor-pointer" onClick={() => refreshChat(item._id)}>
                          <MdRefresh color="#023565" size={26} />
                        </p>
                      </div>
                      <div className="relative overflow-y-auto h-[400px] p-4 border-b">
                        <div className="flex flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl bg-gray-200">
                          <div className="flex justify-start space-x-2  rtl:space-x-reverse">
                            <span className="text-sm font-semibold  text-[#023565] capitalize">
                              {item?.userId?.name}

                            </span>
                          </div>
                          <div className="">
                            <p className="text-sm text-start font-normal text-[#023565]">
                              {item?.msg}
                            </p>
                            <span className="flex justify-end text-sm font-normal text-[#023565]">
                              {timeZoneReturnDateType(item?.createdAt)}
                            </span>
                          </div>

                        </div>

                        <div className="flex flex-col">
                          <div className="w-3/6 flex flex-col space-y-2">
                            {ticketReply &&
                              ticketReply.map((reply, id) => {
                                return String(reply.isAdmin) === "1" ? (
                                  <div className="flex  justify-start flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl bg-gray-200" key={id}>
                                    <div className="flex justify-start space-x-2  rtl:space-x-reverse">
                                      <span className="text-sm font-semibold  text-[#023565]">
                                        {reply?.userId?.name}

                                      </span>

                                    </div>
                                    <div className="">
                                      <p className="text-sm font-semibold  text-[#65798b]">
                                        {reply?.msg}
                                      </p>
                                      <span className="flex justify-end text-[#023565]">
                                        {timeZoneReturnDateType(reply?.createdAt)}
                                      </span>
                                    </div>

                                  </div>
                                ) :
                                  (<div
                                    key={id}
                                    className="flex border-2 justify-end ms-[100%] flex-col w-full max-w-[320px] p-4 mt-1 bg-[#023565] rounded-xl"
                                  >
                                    <span className="font-bold text-[#fff] table_head">
                                      Admin
                                    </span>
                                    <div>
                                      <p className="text-sm font-normal text-white dark:text-white break-all">
                                        {reply?.msg}
                                        <span className="flex justify-end">
                                          {timeZoneReturnDateType(reply?.createdAt)}

                                        </span>
                                        <span ref={endmsgUseRef} />
                                      </p>
                                    </div>

                                  </div>)
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="m-4">
                        <form
                          className="flex items-center justify-center max-w-lg mx-auto"
                          onSubmit={(e) => handleSubmit(e, item)}
                        >
                          <div className="flex items-center gap-4 relative w-full">

                            <textarea
                              rows={1}
                              type="text"
                              value={reply}
                              className=" p-2 border block w-full border-gray-300 rounded-md"
                              placeholder="Reply..."
                              onChange={(e) => setReply(e.target.value)}
                              required
                            />
                            <button
                              type="submit"
                              className="w-24 mx-auto bg-[#023565] text-white font-semibold py-2 px-6 rounded-full "
                            >
                              Send
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}


          <section className="py-10 overflow-y-auto space-y-5 lg:hidden block">
            {ticketData && ticketData.length > 0 ? (ticketData?.map((ticketdata, id) => (
              <div className=' bg-white border-2 border-[#D3D3D3] rounded-lg'>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>S.No :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'>  {id + 1}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Name :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'> {ticketdata && ticketdata?.userId?.name}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Subject :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'>{ticketdata.title}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Date :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'>{timeZoneReturnDateType(ticketData?.createdAt)}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Chat :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'> <FaRocketchat size={24}
                      className="text-blue-500 cursor-pointer "
                      onClick={() => handleChats(ticketdata)}
                    /></p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Status :</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] text-left'>
                      <button
                        onClick={() => handleAction(ticketdata._id)}
                        className={
                          ticketdata.status === 0 ? "bg-[#9C9EAB] text-red-700 px-3 py-1 rounded-full" : "bg-[#023565] text-white px-3 py-1 rounded-full"
                        }
                        disabled={ticketdata.status !== 0}
                      >
                        {ticketdata.status === 0 ? "Pending" : "Resolve"}
                      </button>
                    </p>
                  </div>
                </div>

              </div>
            ))
            ) : (
              <div className="text-center">
                <p className="px-6 py-4 text-center">
                  Data Not Available
                </p>
              </div>
            )
            }
          </section>
        </div>
      </section>
    </>
  );
};

export default HelpAndSupport;
