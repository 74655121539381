import { tutortoliveConstant } from "../_constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers"


export const tutortoliveAction = {
    getNewlyJoinTutorList,
    getNewlyJoinTutorList2,
    getNewlyJoinTutorList3,
    interviewStatusUpdateByAdmin,
    getNewlyJoinTutorCount,
    removeProfile,
    updateTutorByAdmin,
    getTutorById,
    getTutorByPromiseId
}


async function getTutorByPromiseId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getTutorById "
    };
    return await APIcallFunction(credentials)
}

function getTutorById(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getTutorById "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.GET_TUTOR_BY_ID_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.GET_TUTOR_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.GET_TUTOR_BY_ID_FAILURE, error } }


};


function getNewlyJoinTutorList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getNewlyJoinTutorList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_FAILURE, error } }


};

function getNewlyJoinTutorList3(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getNewlyJoinTutorList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_FAILURE, error } }


};
function getNewlyJoinTutorList2(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getNewlyJoinTutorList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_FAILURE, error } }


};


function interviewStatusUpdateByAdmin(data, newJoinedTutorData, rejectData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/interviewStatusUpdateByAdmin"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getNewlyJoinTutorList3(newJoinedTutorData))
                dispatch(getNewlyJoinTutorList2(newJoinedTutorData))
                dispatch(getNewlyJoinTutorList(newJoinedTutorData))
                dispatch(getNewlyJoinTutorCount())
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.INTERVIEW_STATUS_UPDATE_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.INTERVIEW_STATUS_UPDATE_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.INTERVIEW_STATUS_UPDATE_FAILURE, error } }


};






function getNewlyJoinTutorCount(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getNewlyJoinTutorCount"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))


            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_FAILURE, error } }


};












function removeProfile(data, newJoinedTutorData, rejectData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/removeProfile"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getNewlyJoinTutorList3(rejectData))
                dispatch(getNewlyJoinTutorList(newJoinedTutorData))


            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.REMOVE_PROFILE_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.REMOVE_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.REMOVE_PROFILE_FAILURE, error } }


};





function updateTutorByAdmin(data, newJoinedTutorData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/updateTutorByAdmin "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getNewlyJoinTutorList(newJoinedTutorData))
                dispatch(getNewlyJoinTutorCount())

            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tutortoliveConstant.UPDATE_TUTOR_BYADMIN_REQUEST } }
    function success(users) { return { type: tutortoliveConstant.UPDATE_TUTOR_BYADMIN_SUCCESS, users } }
    function failure(error) { return { type: tutortoliveConstant.UPDATE_TUTOR_BYADMIN_FAILURE, error } }


};
