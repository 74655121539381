import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { MdSettingsOverscan } from 'react-icons/md'
import HeaderJson from "./Header.json"
import { useSelector } from 'react-redux'
import { logoutFunction } from '../../_helpers'



function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}


function HeaderProfileComp({ isOpen, setIsOpen }) {
    const selector = useSelector((state) => state);
    const menuRef = useRef()
    const [selectCity, setSelectCity] = useState("");
    const [userProfile, setUserProfile] = useState()

    useEffect(() => {
        setUserProfile(selector?.MyProfile?.getAdminProfile?.data)
    }, [selector])
    const toggleDropDown = () => {
        setIsOpen(!isOpen);
    };

    const handleCitySelect = (city) => {
        setSelectCity(city.name);
        setIsOpen(false);
    };

    const handleLogout = () => {
        logoutFunction();
    };

    const toggleSearch = () => {
        var element = document.getElementById("myDiv");
        if (element.style.display === "none") {
            element.style.display = "block";
        } else {
            element.style.display = "none";
        }
    };

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    };


    return (
        <div className="flex items-center gap-4">
            <div className="full_screen px-2 flex items-center gap-2">
                <button className="text-2xl" onClick={handleFullscreen}>
                    <MdSettingsOverscan />
                </button>
                <div className="font-md admin_name capitalize">
                    {userProfile?.userName || HeaderJson["Admin-name"]}
                </div>
            </div>
            <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                    <>
                        <div className="relative">
                            <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
                                <img
                                    src={userProfile?.image || `/${HeaderJson["Admin-image"]}`}
                                    alt=""
                                    className="rounded-full flex justify-center items-center w-10 h-10 ring-2"
                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <span
                                                onClick={handleLogout}
                                                className={classNames(
                                                    active ? "bg-shine text-gray-900" : "text-gray-900",
                                                    "transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium hover:bg-slate-600 hover:text-white"
                                                )}
                                            >
                                                Logout
                                            </span>
                                        )}
                                    </Menu.Item>
                                    <div>
                                        <ul>
                                            <li className="tab_view lg:hidden md:block xl:hidden text-center">
                                                <div className="flex relative d-block cursor-pointer items-center px-4">
                                                    <p className="flex justify-center" onClick={toggleDropDown}>
                                                        {selectCity || "All Cities"}
                                                        <FaAngleDown className="ml-3 mt-1 hi" />
                                                    </p>
                                                    {isOpen && (
                                                        <div ref={menuRef} className="absolute cursor-pointer city_name">
                                                            {Array.isArray(selector.Citys.cityGetList) &&
                                                                selector.Citys.cityGetList.length > 0 &&
                                                                selector.Citys.cityGetList.map((city, id) => (
                                                                    <p
                                                                        className="cursor-pointer capitalize text-black"
                                                                        key={id}
                                                                        onClick={() => handleCitySelect(city)}
                                                                    >
                                                                        {city.cityName}
                                                                    </p>
                                                                ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="lg:hidden md:block">
                                                <p className="search-input ps-4" onClick={toggleSearch}>
                                                    search
                                                </p>
                                                <div className="relative searchInput" hidden id="myDiv"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    )
}

export default HeaderProfileComp