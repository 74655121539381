/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoAdd, IoRemove } from "react-icons/io5";
import { tutionAction } from "../../../_actions";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
import moment from "moment";
import { MdOutlineClose } from "react-icons/md";
import './tution.css';

const ViewAndUpdate = (props) => {
  const dispatch = useDispatch();
  const { id, parentIds } = useParams();
  const selector = useSelector((state) => state);
  const [inputChangeField, setInputChangeField] = useState({});
  const [pinCodeField, setPinCodeField] = useState([]);
  const [fields, setFields] = useState([{ student: '', subject: [], class: '', board: '' }]);
  const [parentId, setParentId] = useState("");
  const [selectedStudent, setSelectedStudent] = useState({});

  const noOfClasses = ["Full Week", "Weekly 6 Days", "Weekly 5 Days", " Weekly 4 Days", "Weekly 3 Days", "Weekly 2 Days", "Once in a Week"]
  const handleAddField = () => {
    setFields([...fields, { student: '', subject: [], class: '', board: '' }]);
  };

  useEffect(() => {
    if (props?.isStateMangerResponse) {
      const mapData = props?.getDemoById?.data?.bookFreeDemo?.map((ele) => ({
        student: { value: ele?.studentId?._id ?? null, label: ele?.studentId?.studentFullName ?? null },
        subject: ele?.subjectId.map((element) => ({ value: element?._id ?? null, label: element?.name ?? null })) ?? null,
        board: { value: ele?.board?._id ?? null, label: ele?.board?.name ?? null },
        class: { value: ele?.classId?._id ?? null, label: ele?.classId?.name ?? null },
      }));
      setFields(mapData);
    }
    if (props?.isStateMangerRes) {
      setInputChangeField(props?.getDemoById?.data);
    }
    if (props?.pinCodeDataRes) {
      setPinCodeField(props?.pinCodeDataRes?.data);
    }
  }, [props]);

  useEffect(() => {
    let data = { demoId: id };
    let parent = { parentId: parentIds };
    dispatch(tutionAction.getDemoById(data));
    dispatch(tutionAction.getAllParentList());
    dispatch(tutionAction.getStudentListByParentIdForDemo(parent));
  }, [id, parentIds]);

  const handleBack = () => {
    window.history.back();
  };




  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputChangeField((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  let allParentList = selector?.tution?.getAllParentList?.data;
  let allStudentList = selector?.tution?.getStudentListByParentIdForDemo?.data;

  const handleOnchangeSelectParent = (e) => {
    let idforStudent = { parentId: e.target.value };
    setParentId(e.target.value);
    dispatch(tutionAction.getStudentListByParentIdForDemo(idforStudent));
    dispatch(tutionAction.getParentIdGetPinCode(idforStudent));
    setFields([{ student: '', subject: [], class: '', board: '' }]);
  };


  const [errorData, setErrorData] = useState({})
  const handleValidation = () => {
    const errors = {};
    if (!inputChangeField?.classDuration) {
      errors["classDuration"] = "Class Duration is required";
    }
    if (!inputChangeField?.tutorFee) {
      errors["tutorFee"] = "Tutor Fee is required";
    }
    if (!inputChangeField?.numberOfClasses) {
      errors["numberOfClasses"] = "Classes required";
    }
    if (!inputChangeField?.demoDate) {
      errors["demoDate"] = "Demo Date required";
    }
    if (!inputChangeField?.demoTime) {
      errors["demoTime"] = "Demo Time required";
    }
    if (!inputChangeField?.tuitionFee) {
      errors["tuitionFee"] = "tuition Fee required";
    }
    if (!inputChangeField?.requiredGender) {
      errors["requiredGender"] = "Gender required";
    }
    setErrorData(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmitUpdateInfo = () => {
    let bookFreeDemo = fields.map(item => ({
      studentId: item && item.student && item.student.value ? item.student.value : item && item.student ? item.student : null,
      subjectId: item?.subject?.map((ele) => ele.value || ele),
      board: item && item.board && item.board.value ? item.board.value : item && item.board ? item.board : null,
      classId: item && item.class && item.class.value ? item.class.value : item && item.class ? item.class : null,
    }));

    if (!handleValidation()) {
      return;
    }

    const resData = {
      demoId: id,
      parentId: parentId ? parentId : parentIds,
      bookFreeDemo: bookFreeDemo,
      demoDate: inputChangeField?.demoDate,
      demoTime: inputChangeField?.demoTime,
      pinCodeId: inputChangeField?.pinCodeId,
      address: inputChangeField?.address,
      requiredGender: inputChangeField?.requiredGender,
      preferredTeachMode: inputChangeField?.preferredTeachMode,
      numberOfClasses: inputChangeField?.numberOfClasses,
      teachingLocation: inputChangeField?.teachingLocation,
      tuitionFee: inputChangeField?.tuitionFee,
      tutorFee: inputChangeField?.tutorFee,
      classReference: inputChangeField?.classReference ?? "0",
      classDuration: inputChangeField?.classDuration ?? 0,
      remark: inputChangeField?.remark
    };

    let data = { demoId: id };
    dispatch(tutionAction.updateDemoInfoByAdmin(resData, data));
  };

  const onRemove2 = (selectedList, removedItem, index) => {
    const updatedModules = [...fields];
    updatedModules[index].subject = selectedList.map((item) => item);
    setFields(updatedModules);
  };

  const onSelect2 = (selectedList, selectedItem, index) => {
    const updatedModules = [...fields];
    updatedModules[index].subject = selectedList.map((item) => item);
    setFields(updatedModules);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-8/12">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={handleBack}
          >
            <span>
              <MdOutlineClose />
            </span>
            Close
          </button>

          <div className="form_class rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none border-2 h-[70vh] overflow-hidden overflow-y-auto Pokercardbar">
            <form>
              <div className="flex w-full">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full">
                  <h2 className="text-2xl font-semibold mb-6">Update Info</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Parent Name
                      </label>
                      <select
                        className="block w-full p-2 border border-gray-300 bg-white rounded-md"
                        onChange={handleOnchangeSelectParent}
                      >
                        <option value={inputChangeField?.parentId?._id ?? 'Select'}>{inputChangeField?.parentId?.name + " - " + inputChangeField?.parentId?.mobNo ?? 'Select'}</option>
                        {Array.isArray(allParentList) &&
                          allParentList.length > 0 &&
                          allParentList.map((ele, index) => (
                            <option className="capitalize" key={index} value={ele && ele._id ? ele._id : null}>
                              {ele && ele.name ? ele.name + " - " + ele.mobNo : null}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        name="demoDate"

                        value={inputChangeField?.parentId?.address}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Secondary Mobile Number
                      </label>
                      <input
                        type="text"
                        name="demoDate"

                        value={inputChangeField?.parentId?.address}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Address
                      </label>
                      <input
                        type="text"
                        name="demoDate"

                        value={inputChangeField?.parentId?.address}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                        type="text"
                        name="demoDate"

                        value={inputChangeField?.parentId?.address}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Pincode
                      </label>
                      <input
                        type="text"
                        name="demoDate"

                        value={inputChangeField?.parentId?.address}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="md:col-span-2">

                      {fields && Array.isArray(fields) && fields.length > 0 && fields?.map((field, index) => (
                        <div key={index} className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                              Select Student
                            </label>
                            <select
                              name={`student-${index}`}
                              className="block w-full p-2 border bg-white border-gray-300 rounded-md"
                              value={field?.student}
                              onChange={(e) => {
                                const selectedStudentId = e.target.value;
                                const selectedStudentData = allStudentList.find((student) => student._id === selectedStudentId);
                                setSelectedStudent(selectedStudentData);
                                const newFields = [...fields];
                                newFields[index].student = selectedStudentId;
                                newFields[index].board = selectedStudentData?.educationBoard;
                                newFields[index].class = selectedStudentData?.grade?._id;
                                setFields(newFields);
                              }}
                            >
                              <option value={field?.student?.value || ''}>{field?.student?.label || 'Choose a student'}</option>
                              {Array.isArray(allStudentList) &&
                                allStudentList.length > 0 &&
                                allStudentList.map((student, id) => (
                                  <option key={id} value={student._id}>
                                    {student.studentFullName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Subjects
                            </label>
                            <Multiselect
                              options={selectedStudent?.grade?.subjectId?.map(subject => ({ value: subject._id, label: subject.name }))}
                              selectedValues={field.subjeect || []}
                              onSelect={(selectedList, slectedItem) => onSelect2(selectedList, slectedItem, index)}
                              onRemove={(selectedList, removedItem) => onRemove2(selectedList, removedItem, index)}
                              displayValue="label"
                              className="border-gray-300 bg-white text-gray-900 text-sm rounded-lg block w-full"
                            />
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Class
                            </label>
                            <input
                              type="text"
                              name="demoDate"

                              value=""
                              onChange={handleOnChange}
                              className="block w-full p-1.5 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Board
                            </label>
                            <select
                              name=""
                              className="block w-full p-2 border bg-white border-gray-300 rounded-md"
                              value=""
                            >
                              <option value="">Select Board</option>
                              <option value="">CBSE</option>
                              <option value="">RBSC</option>
                              <option value="">Delhi Central Board</option>

                            </select>
                            <div className=" flex  items-center justify-end gap-3">
                              <label className="block  text-sm font-medium text-gray-900">
                                Add
                              </label>
                              <IoAdd
                                className="text-blue-500 cursor-pointer border rounded-full border-black"
                                onClick={handleAddField}
                              />
                              <IoRemove
                                className="text-red-500 cursor-pointer border rounded-full border-black"
                                onClick={() => {
                                  const values = [...fields];
                                  values.splice(index, 1);
                                  setFields(values);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Demo Date
                      </label>
                      <input
                        type="date"
                        name="demoDate"

                        value={inputChangeField.demoDate}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {/* // value={inputChangeField.demoDate ? moment(`${inputChangeField.demoDate} ${String(inputChangeField.demoTime).replaceAll(" ", "")}`, "DD-MM-YYYY").format('DD-MM-YYYY ') : null} */}
                      {errorData && errorData["demoDate"] ? <p className="text-red-500 mt-2">{errorData["demoDate"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Demo Time
                      </label>
                      <input
                        type="time"
                        name="demoTime"
                        value={inputChangeField.demoTime ? moment(`${inputChangeField.demoDate} ${String(inputChangeField.demoTime).replaceAll(" ", "")}`, "DD-MM-YYYY HH:mm A").format('HH:mm') : null}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData && errorData["demoTime"] ? <p className="text-red-500 mt-2">{errorData["demoTime"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Required Gender
                      </label>
                      <select
                        className="block w-full p-2 border border-gray-300 rounded-md"
                        name="requiredGender"
                        onChange={handleOnChange}
                        value={String(inputChangeField?.requiredGender).toUpperCase()}
                      >
                        <option value="NO_PREFERENCE">No preference</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                      {errorData && errorData["requiredGender"] ? <p className="text-red-500 mt-2">{errorData["requiredGender"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Preferred Teach Mode
                      </label>
                      <select
                        name="preferredTeachMode"
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                        value={inputChangeField?.preferredTeachMode}
                      >
                        <option value="">{"Select Teach mode"}</option>
                        <option value="HOME_TUITION">Home Tuition</option>
                        <option value="ONLINE_TUITION">Online Tuition</option>
                      </select>
                      {errorData && errorData["preferredTeachMode"] ? <p className="text-red-500 mt-2">{errorData["preferredTeachMode"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Session Details
                      </label>

                      <select id="countries" name="numberOfClasses" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleOnChange} value={inputChangeField.numberOfClasses}>
                        <option> Select No. Classes In Week</option>
                        {noOfClasses.map((ele, id) => {
                          return <option key={id} value={ele}>{ele}</option>
                        })}
                      </select>

                      {errorData && errorData["numberOfClasses"] ? <p className="text-red-500 mt-2">{errorData["numberOfClasses"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Teaching Location
                      </label>
                      <select
                        name="teachingLocation"
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">{inputChangeField?.teachingLocation ?? "Select Location"}</option>
                        {Array.isArray(pinCodeField) &&
                          pinCodeField.length > 0 &&
                          pinCodeField.map((pinCode, id) => (
                            <option key={id} value={pinCode?._id}>
                              {pinCode?.pinCode}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Class Duration (in min)
                      </label>
                      <input
                        type="number"
                        name="classDuration"
                        value={inputChangeField?.classDuration}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData && errorData["classDuration"] ? <p className="text-red-500 mt-2">{errorData["classDuration"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Tuition Fee (Optional)
                      </label>
                      <input
                        type="number"
                        name="tuitionFee"
                        value={inputChangeField?.tuitionFee}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData && errorData["tuitionFee"] ? <p className="text-red-500 mt-2">{errorData["tuitionFee"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Tutor Fee
                      </label>
                      <input
                        type="number"
                        name="tutorFee"
                        value={inputChangeField?.tutorFee}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData && errorData["tutorFee"] ? <p className="text-red-500 mt-2">{errorData["tutorFee"]}</p> : null}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Class Reference
                      </label>
                      <input
                        type="text"
                        name="classReference"
                        value={inputChangeField?.classReference}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center py-6">
                    <button
                      type="button"
                      onClick={handleSubmitUpdateInfo}
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isStateMangerResponse: state.tution.isStateMangerResponse,
  isStateMangerRes: state.tution.isStateMangerRes,
  getDemoById: state.tution.getDemoById,
  pinCodeDataRes: state.tution.pinCodeDataRes
});

export default connect(mapStateToProps)(ViewAndUpdate);
