import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { referralAction, userListAction } from "../../_actions";
import { MdOutlineKeyboardArrowLeft, MdPersonSearch } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import Loader from "../../components/Loader";
import Multiselect from "multiselect-react-dropdown";
import { timeZoneReturnDateType } from "../../_helpers";
import Select from "react-select";
import { FaFilterCircleXmark } from "react-icons/fa6";
import DateRangePicker from "../../components/Atoms/DateRangePicker";


const ReferralComponent = ({ searchText }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [boardTotal, setBoardTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [selectedCities, setSelectCity] = useState([])
  const [selectedData, setSelectedData] = useState('')
  const [selectedData2, setSelectedData2] = useState('')
  const [referedSelectedList, setReferedSelectedList] = useState({ label: "Referral To", value: "" })
  const [referredBy, setReferredBy] = useState({ label: "Referral By", value: "" })
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };

  const [dates, setDates] = useState({ fromDate: '', toDate: '' });

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };


  useEffect(() => {
    let boardData = {
      keyWord: searchText,
      pageNo: currentPage,
      size: pageSize,
      refFrom: referredBy.value != "" ? [referredBy.value] : null,
      userId: referedSelectedList.value != "" ? [referedSelectedList.value] : null,
      ...dates
    };
    let details = {
      "cityId": "",
      // "userType": "TUTOR",
      // "isDisable": false
      isManagement: true,
    }
    dispatch(referralAction.referralGet(boardData));
    dispatch(userListAction.getUserTypeList(details))
  }, [currentPage, searchText, referredBy, referedSelectedList, dates]);

  useEffect(() => {
    setBoardTotal(selector?.Referral?.referralList ?? []);
    if (selector?.Referral?.referralList) {
      setTotalPages(Math.ceil(selector?.Referral?.referralList.total / pageSize));
    }
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setSelectedData(() => selector?.userList?.getUserListForSearch?.data)
    setSelectedData2(() => selector?.userList?.getUserListForSearch2?.data)

  }, [selector])



  const options = selector?.userList?.getUserListForSearch?.data ? selector?.userList?.getUserListForSearch?.data.map((ele) => ({
    value: ele?._id,
    label: `${ele?.name} - ${ele?.mobNo}`
  })) : [];

  const searchHandle = () => {
    const userData = (selectedCities && selectedCities.length > 0)
      ? selectedCities.map((ele) => ele.id)
      : [];

    if (userData.length === 0) {
      return
    }

    if (userData) {
      let boardData = {
        keyWord: searchText,
        pageNo: currentPage,
        size: pageSize,
        userId: userData,

      };
      dispatch(referralAction.referralGet(boardData));
    }


  }

  const searchHandle2 = () => {
    const userData = (referedSelectedList && referedSelectedList.length > 0)
      ? referedSelectedList.map((ele) => ele.id)
      : [];

    if (userData.length === 0) {
      return
    }
    if (userData) {
      let boardData = {
        keyWord: searchText,
        pageNo: currentPage,
        size: pageSize,
        userId: userData,

      };
      dispatch(referralAction.referralGet(boardData));
    }
  }

  const handleResetFilters = () => {
    setReferredBy({ label: "Referral By", value: "" })
    setReferedSelectedList({ label: "Referral To", value: "" })
    setDates({ fromDate: '', toDate: '' })
  }

  return (
    <>
      <div className="flex flex-col md:flex-row justify-end lg:mx-9 lg:-mt-16 place-items-center gap-2">
        <DateRangePicker
          fromDate={dates.fromDate}
          toDate={dates.toDate}
          handleChange={handleChange}
        />

        <Select
          id="parentId"
          name="parentId"
          value={referredBy}
          options={[{ label: "Referral By", value: "" }, ...options]}
          className="capitalize text-black"
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={(selectedOption, actionMeta) => {
            setReferredBy(selectedOption)
          }}
        />
        <Select
          id="parentId"
          name="parentId"
          value={referedSelectedList}
          options={[{ label: "Referral To", value: "" }, ...options]}
          className="capitalize text-black"
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={(selectedOption, actionMeta) => {
            setReferedSelectedList(selectedOption)
          }}
        />
        <button className="text-center font-bold" onClick={() => handleResetFilters()}>
          <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
        </button>

      </div>
      <section className="overflow-hidden overflow-y-auto md:block hidden">
        <Loader loading={selector?.Referral?.loading} />
        <div className="m-5">

          <div className="border bg-white border-[#D3D3D3] sm:rounded-lg">
            <div className="overflow-x-auto ">
              <table className="w-full text-left text-gray-500 ">
                <thead className="text-[#313131] bg-white border-b">
                  <tr className="border-b">
                    <th className="px-4 py-2 sm:px-6 sm:py-4">S.No</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Referred By</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Referred To</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Type</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Reward Amount</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Create Date</th>
                    <th className="px-4 py-2 sm:px-6 sm:py-4">Status</th>
                  </tr>
                </thead>
                <tbody className="overflow-scroll">
                  {boardTotal && boardTotal.list && boardTotal.list.length > 0 ? (
                    boardTotal.list.map((item, id) => (
                      <tr key={id} className="border-b capitalize">
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          {(currentPage - 1) * pageSize + id + 1}
                        </td>
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          <p>{item?.refFrom?.name ?? null}</p>
                          <p>{item?.refFrom?.mobNo ?? null}</p>
                          <p>{item?.refFrom?.userType ? item.refFrom.userType === "TUTOR" ? "(Tutor)" : "(Parent)" : null}</p>
                        </td>
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          <p>{item?.userId?.name ?? null}</p>
                          <p>{item?.userId?.mobNo ?? null}</p>
                          <p>{item?.userId?.userType ? item.userId.userType === "TUTOR" ? "(Tutor)" : "(Parent)" : null}</p>

                        </td>
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          <p>{item?.type ?? null}</p>
                        </td>
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          <p>{item?.rewards ?? null}</p>
                        </td>
                        <td className="px-4 py-2 sm:px-6 sm:py-4">
                          {timeZoneReturnDateType(item?.createdAt)}
                        </td>
                        <td
                          className={`px-4 py-2 sm:px-6 sm:py-4 cursor-pointer font-semibold ${item.isDisable ? "text-red-600" : "text-[#00AFE2]"
                            }`}
                        >
                          {item.status == 1 ? "Done" : "Pending"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="border-b text-center">
                      <td colSpan="7">Data Not Available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2">
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black rounded">
                    {currentPage}
                  </span>{" "}
                  of {totalPages}
                </span>
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="py-10 overflow-y-auto space-y-4 md:hidden block">
        {boardTotal && boardTotal.length > 0 ? (
          boardTotal?.map((item, id) => (
            <div className=' bg-white border-2 border-[#D3D3D3] rounded-lg'>

              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>S No :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    {(currentPage - 1) * pageSize + id + 1}

                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>User Name :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    <p>{item?.refFrom?.name ?? null}</p>
                    <p>{item?.refFrom?.mobNo ?? null}</p>

                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Referred By :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left' >
                    <p>{item?.refFrom?.name ?? null}</p>
                    <p>{item?.refFrom?.mobNo ?? null}</p>
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Type :</p>
                </div>
                <div className='w-full'>
                  <p>{item?.type ?? null}</p>

                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>	Reward Amount :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    <p>{item?.rewards ?? null}</p>

                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Created at :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>  {timeZoneReturnDateType(item?.createdAt)}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Status :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    <span
                      className={`px-4 py-2 sm:px-6 sm:py-4 cursor-pointer font-semibold ${item.isDisable ? "text-red-600" : "text-[#00AFE2]"
                        }`}
                    >
                      {item.status == 1 ? "Done" : "Pending"}
                    </span>
                  </p>
                </div>
              </div>

            </div>
          ))
        ) : (
          <div>
            <td className="text-center px-6 py-4">
              Data Not Available
            </td>
          </div>
        )
        }
      </section>
    </>
  );
};

export default ReferralComponent;
