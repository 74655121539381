import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaAngleRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { timeZoneReturnDateType } from '../../../_helpers'

function FullTable({ getCityList, handleAction, actionCityId, handleEditCity, menuRef, totalPages, handleEnaDis, handlePreviousPage, currentPage, handleNextPage }) {
  return (
    <>
      <div className="border border-[#D3D3D3] lg:block hidden rounded-lg">
        <div className="overflow-x-auto overflow-y-auto overflow-hidden bg-white rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-black ">
            <thead className="">
              <tr className="border-b">
                <th className="px-6 py-3">S No </th>
                <th className="px-6 py-3">City Name </th>
                <th className="px-6 py-3">Work Mode</th>
                <th className="px-6 py-3">Platform Fee</th>
                <th className="px-6 py-3">Created Date</th>
                <th className="px-6 py-3">Status</th>
                <th className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {getCityList?.data &&
                getCityList?.data?.list.length > 0 ? (
                getCityList?.data?.list.sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, id) => (
                    <tr className=" border-b capitalize" key={id}>
                      <td
                        className="px-6 py-4 "
                      >
                        {id + 1}
                      </td>
                      <td
                        className="px-6 py-4 "
                      >
                        {item.name}
                      </td>
                      <td className="px-6 py-4">
                        {item.isManagement ? "Full Management" : "Credit Based"}
                      </td>
                      <td className="px-6 py-4">
                        <p>
                          {item && item?.platformFee ? item.platformFee : 0}
                        </p>
                      </td>
                      <td className="px-6 py-4 ">
                        {timeZoneReturnDateType(item?.createdAt)}
                      </td>
                      <td className="px-6 py-4 ">
                        <button
                          className="font-bold rounded-md"
                          style={{
                            color: item.isDisable ? "red" : "#00AFE2",
                          }}
                        >
                          {item.isDisable ? "Inactive" : "Active"}
                        </button>
                      </td>
                      <td className="px-6 py-4 ">
                        <div className="relative">
                          <BsThreeDotsVertical
                            onClick={() => handleAction(item._id)}
                            className="cursor-pointer text-xl  text-[#C60E6B]"
                          />
                        </div>
                        {actionCityId === item._id && (
                          <div ref={menuRef} className="absolute right-6 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-40">
                            <ul className="p-2 space-y-2 text-[#023565]">
                              <li
                                className="flex justify-between items-center cursor-pointer border-b hover:bg-gray-100 p-2 rounded font-semibold hover:text-[#E4006F] "
                                onClick={() => handleEnaDis(item)}
                              >
                                <span>
                                  {item.isDisable ? "Active" : "InActive"}
                                </span>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>

                              <li
                                className="flex justify-between border-b items-center cursor-pointer hover:bg-gray-100 p-2 rounded font-semibold thover:text-[#E4006F] "
                                onClick={() => handleEditCity(item)}
                              >
                                <span>Update</span>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>

        </div>

      </div>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </>
  )
}

export default FullTable
