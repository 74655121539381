import React from 'react'

function MobileSearchHeader({ handleSearch, searchText }) {
    return (
        <div className="flex justify-between items-center px-4">
            {/* <div></div> */}
            <div className="mobile_view_header_img flex justify-start items-start"></div>
            <div>
                <div>
                    <div class="search-container">
                        <form action="/search" method="get">
                            <input
                                class="search expandright placeholder-white"
                                id="searchright"
                                type="search"
                                name=""
                                onChange={handleSearch}
                                value={searchText}
                                placeholder="Search"
                            />
                            <label class="button-mob searchbutton" for="searchright">
                                <span class="mglass">&#9906;</span>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSearchHeader