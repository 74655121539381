import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { dashboardAction } from '../../_actions/dashboard.action';
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaAngleRight, FaChevronRight } from "react-icons/fa";
import { tutionAction } from "../../_actions/tution.actions";
import { FaChevronCircleRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";


import "./Dashboard.css";
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';

const LatestTutorChnage = ({ setNewStateHandle, totalPages5, tutorData, suggestedTutor, demoDoneListdata, allTutorDataList }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10; // Number of items per page

  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [assignTutorList, setAssignTutorList] = useState("");
  const [demoIdForAssign, setDemoIdForAssign] = useState({});

  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");

  const [open, setOpen] = useState(false)

  const menuRef = useRef(null)


  const mobileRef = useRef(null)


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setOpen(null);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);





  /// Asign Tutor==========>
  let suggestTutorBtn = ["Applicants", "Suggested Tutors", "All Tutors"];

  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode == 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    // dispatch(tutionAction.getAllTutorList({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query));

    setAssignTutorModal(true);
    setAssignTutorList(data);
    setDemoIdForAssign(data._id);
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };

  const handleAssign = (data) => {
    ;
    let demoData = {
      status: [0, 1],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };

    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, demoData));
    setAssignTutorModal(false)

  };






  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages5) setCurrentPage(currentPage + 1);
  };

  const handleAction = (ele) => {
    setOpen((prevele) => (prevele === ele ? null : ele));

  }

  const handleDenieTutor = (ele) => {
    let demoDoneListData = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    let idfordenie = {
      tuitionId: ele._id
    }
    dispatch(tutionAction.tuitionRequestDeniedByAdmin(idfordenie, demoDoneListData))
  }


  const [newtutorDetailsData, setNewTutorDetailsData] = useState({})
  const [tutorDetails, setTutorDetails] = useState(false);




  const handleTutorDetails = (data) => {
    setTutorDetails(true)
    setNewTutorDetailsData(data)
    let tutorId = {
      id: data._id
    }
    dispatch(tutionAction.getTutorById(tutorId))

  }


  return (
    <div>
      <div class="relative first_table overflow-x-auto bg-white">
        <Loader loading={selector?.Dashboard?.loading} />

        <div className='lg:block hidden'>
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
            <thead class=" text-[#313131]  font-bold  border-b-2 border-[#D1D1D1]">
              <tr className="border-b">
                <th scope="col" class="px-6 py-3 ">
                  Parent/Student
                </th>
                <th scope="col" class="px-6 py-3">
                  Class/Subjects
                </th>
                <th scope="col" class="px-6 py-3">
                  City
                </th>
                <th scope="col" class="px-6 py-3">
                  Tutor Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="">
              {
                demoDoneListdata && demoDoneListdata.length > 0 ? (demoDoneListdata?.map((item, id) => (
                  <tr class="bg-white capitalize" key={id}>
                    <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                      <span>{item?.parentId?.name ?? 'No User'}</span> <br></br> <span>{item?.parentId?.mobNo ?? 'No Contact'}</span>
                    </td>
                    <td className=" px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap cursor-pointer  ">
                      <span className="text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 cursor-pointer  group relative" >
                        View
                        <div role="tooltip" className="absolute left-[40%] -top-[20%] transform -translate-x-1/2 mt-2 w-max p-3 text-sm font-medium text-white bg-[#023565] rounded-lg shadow-sm opacity-0 transition-opacity duration-300 group-hover:opacity-100 z-10">
                          <div className="mb-2 border-b border-gray-500">
                            <strong>Classes:</strong> {item?.bookFreeDemo?.map((ele) => ele?.classId?.name).join(', ') || 'N/A'}
                          </div>
                          <div className="border-b border-gray-500">
                            <strong>Subjects:</strong> {item?.bookFreeDemo?.map((ele) => ele?.subjectId?.name).join(', ') || 'N/A'}
                          </div>
                        </div>
                      </span>
                    </td>
                    <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{item?.cityId?.name ? item?.cityId?.name : 'N/A'}</td>
                    <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{item?.tutorId?.name ?? 'No tutor'}</td>
                    <td className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap ">
                      <BsThreeDotsVertical
                        className="text-xl text-[#C60E6B] cursor-pointer"
                        onClick={() => handleAction(item)}
                      />
                      <div className={`fixed z-[100] ${open === item ? "block" : "hidden"}  w-40`}>
                        {open === item && (
                          <ul className="border rounded-md bg-white shadow-lg text-[#023565]">
                            <li className="flex justify-between items-center border-b px-3 py-1" ref={menuRef}>
                              <button
                                className="font-semibold text-[#023565] hover:text-[#E4006F]  rounded-full"
                                onClick={() => assignTutor(item)}
                              >
                                Change Tutor
                              </button>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li className="flex justify-between items-center px-3 py-1">
                              <button
                                className="text-[#023565] rounded-full font-semibold"
                                onClick={() => handleDenieTutor(item)}
                              >
                                Denie Tutor
                              </button>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                          </ul>
                        )}
                      </div>
                    </td>

                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>
                )
              }

            </tbody>
          </table>
          <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
            <button onClick={() => navigate("/app/tution?tab=Change-Tutor-Request&button=tuition")} >
              View all

            </button>
          </div>
        </div>



        {/* <div>
          {totalPages5 > 1 && (
            <div className="flex justify-end items-center py-2">
              <button
                className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                <MdOutlineKeyboardArrowLeft />
              </button>
              <span className="text-gray-700 mx-1">
                <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages5}
              </span>
              <button
                className={`px-4 py-2 border text-black rounded ${currentPage === totalPages5 ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages5}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div> */}

        {assignTutorModal && (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[900px] ">
              <div className=" z-50 p-1 ml-auto bg-transparent border-0  text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center">
                <button className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center" onClick={() => setAssignTutorModal(false)}>
                  <span><MdOutlineClose /></span> Close
                </button>
              </div>

              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">
                    {" "}
                    Assign Tutor
                  </h2>
                </div>
                <section className="grid md:grid-cols-3 grid-cols-1 w-full">
                  {suggestTutorBtn.map((itm, id) => (
                    <div key={id} className="">
                      <button
                        className={`px-20  text-sm py-2 w-full font-bold ${assignTutorTab === itm ? "bg-[white] text-[#023565] border border-[#023565]" : "bg-[#D3D3D3]"
                          } 
                      ${assignTutorTab === itm ? "hover" : "hover"}`}
                        onClick={() => handleAssignTutor(itm)}
                      >
                        {itm}
                      </button>
                    </div>
                  ))}
                </section>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div>
                    <section className="p-2">
                      {assignTutorTab === "Applicants" && (
                        <div>
                          <div>
                            <form class="flex items-center max-w-sm mx-auto">
                              <label for="simple-search" class="sr-only">
                                Search
                              </label>
                              <div class="relative w-full">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                                <input
                                  type="text"
                                  id="simple-search"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Search..."
                                />
                              </div>
                              <button
                                type="submit"
                                class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <svg
                                  class="w-4 h-4"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                                <span class="sr-only">Search</span>
                              </button>
                            </form>
                          </div>
                          <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">
                            <table className="min-w-full shadow-md rounded-md overflow-hidden">
                              <thead>
                                {assignTutorList &&
                                  assignTutorList.tutorAppliedList &&
                                  assignTutorList.tutorAppliedList.map((ele) => (
                                    <div className="border rounded-xl p-6">
                                      <div className="flex justify-between items-center">
                                        <div className="flex items-center space-x-6">
                                          <div className="flex items-center space-x-3">
                                            <div>
                                              <img
                                                src={
                                                  ele && ele.image
                                                    ? ele.image
                                                    : null
                                                }
                                                className="rounded-full w-10 h-10"
                                                alt=""
                                              />
                                            </div>
                                            <div>
                                              <p>
                                                {ele && ele.name ? ele.name : null}
                                              </p>
                                              <p>
                                                {ele && ele.mobNo
                                                  ? ele.mobNo
                                                  : null}
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            <FaChevronCircleRight
                                              size={20}
                                              className="cursor-pointer relative text-[#023565]"
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <button
                                            className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                            onClick={() => handleAssign(ele?._id)}
                                          >
                                            Assign
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </thead>
                            </table>
                          </div>
                        </div>
                      )}
                    </section>
                    <section className="p-2">
                      {assignTutorTab === "Suggested Tutors" && (
                        <div >
                          <div>
                            <form class="flex items-center max-w-sm mx-auto">
                              <label for="simple-search" class="sr-only">
                                Search
                              </label>
                              <div class="relative w-full">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                                <input
                                  type="text"
                                  id="simple-search"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Search..."
                                />
                              </div>
                              <button
                                type="submit"
                                class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <svg
                                  class="w-4 h-4"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                                <span class="sr-only">Search</span>
                              </button>
                            </form>
                          </div>
                          <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">
                            <table className="min-w-full  shadow-md  rounded-md overflow-hidden">
                              <tbody className="text-gray-600 text-sm font-light">
                                {suggestedTutor ? (
                                  suggestedTutor.map((ele) => (
                                    <div className="border rounded-xl p-6">
                                      <div className="flex justify-between items-center">
                                        <div className="flex items-center space-x-6">
                                          <div className="flex items-center space-x-3">
                                            <div>
                                              <img
                                                src={
                                                  ele &&
                                                    ele.userDetails &&
                                                    ele.userDetails.image
                                                    ? ele.userDetails.image
                                                    : null
                                                }
                                                alt=""
                                                className="rounded-full w-10 h-10"
                                              />
                                            </div>
                                            <div>
                                              <p>
                                                {ele &&
                                                  ele.userDetails &&
                                                  ele.userDetails.name
                                                  ? ele.userDetails.name
                                                  : null}
                                              </p>
                                              <p>
                                                {ele &&
                                                  ele.userDetails &&
                                                  ele.userDetails.mobNo
                                                  ? ele.userDetails.mobNo
                                                  : null}
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            <FaChevronCircleRight
                                              size={20}
                                              className="cursor-pointer relative text-[#023565]"
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <button
                                            className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                            onClick={() =>
                                              handleAssign(ele?.userDetails?._id)
                                            }
                                          >
                                            Assign
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-black">No Data</div>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </section>

                    <section className="p-4">
                      {assignTutorTab === "All Tutors" && (
                        <div>
                          <div className="">
                            <form class="flex items-center max-w-sm mx-auto">
                              <label for="simple-search" class="sr-only">
                                Search
                              </label>
                              <div class="relative w-full">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                                <input
                                  type="text"
                                  id="simple-search"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Search..."
                                />
                              </div>
                              <button
                                type="submit"
                                class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <svg
                                  class="w-4 h-4"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                                <span class="sr-only">Search</span>
                              </button>
                            </form>
                          </div>
                          <div className="h-[25vh] overflow-y-auto overflow-hidden py-6">

                            <table className="min-w-full shadow-md rounded-md overflow-hidden ">
                              <thead>
                                {allTutorDataList ? (
                                  allTutorDataList.map((ele) => (
                                    <div className="border rounded-xl p-2">
                                      <div className="flex justify-between items-center">
                                        <div className="flex items-center space-x-6">
                                          <div className="flex items-center space-x-3">
                                            <div>
                                              <img
                                                src={
                                                  ele && ele.image
                                                    ? ele.image
                                                    : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'

                                                }
                                                className="rounded-full w-10 h-10"
                                                alt=""
                                              />
                                            </div>
                                            <div>
                                              <p>
                                                {ele && ele.name ? ele.name : null}
                                              </p>
                                              <p>
                                                {ele && ele.mobNo
                                                  ? ele.mobNo
                                                  : null}
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            <FaChevronCircleRight
                                              size={20}
                                              className="cursor-pointer relative text-[#023565]"
                                              onClick={() =>
                                                handleTutorDetails(ele)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <button
                                            className="font-semibold px-5 rounded-full text-lg bg-[#C60E6B] text-white"
                                            onClick={() => handleAssign(ele?._id)}
                                          >
                                            Assign
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-black">No Data</div>
                                )}
                              </thead>
                            </table>
                          </div>
                        </div>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}



        {
          tutorDetails && (
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40"
            >
              <div className="relative w-11/12 mx-auto lg:w-[800px]">
                <div className="flex justify-end">
                  <button
                    onClick={() => setTutorDetails(false)}
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  >
                    <MdOutlineClose /> Close
                  </button>
                </div>

                <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Tutor Details </h2>
                  </div>

                  <div className="grid md:grid-cols-2 gap-4 p-2">
                    <div className="w-full text-center">

                      <img
                        src={tutorData?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                        alt="Tutor"
                        className="w-56 h-56 mx-auto "
                      />
                    </div>
                    <div className="w-full capitalize space-y-2">
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Name</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.name ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Gender</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.tutorInfoId?.gender ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Teaching Exp</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.tutorInfoId?.teachingExp ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Address</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.address ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Mobile No</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.mobNo ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Email</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.email ?? "N/A"}</p>
                      </div>


                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">ug Course</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">classYouCanTeach</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">
                          <p>
                            {tutorData?.tutorInfoId?.classYouCanTeach?.map((ele) => ele?.name).join(', ') ?? 'N/A'}
                          </p>
                        </p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">pg College Name</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{tutorData?.tutorInfoId?.pgCollegeName ?? "N/A"}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">subYouCanTeach</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">
                          {tutorData?.tutorInfoId?.subYouCanTeach?.map((ele) => ele?.name).join(', ') ?? "N/A"}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <section className="space-y-5 py-10 lg:hidden block">
        {demoDoneListdata && demoDoneListdata.length > 0 ? (
          demoDoneListdata?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                  </div>
                  <div className='w-full'>
                    <div className=" items-center  gap-2 capitalize">
                      <span>{item?.parentId?.name ?? "No User"}</span> <br></br>{" "}
                      <span>{item?.parentId?.mobNo ?? "No Contact"}</span>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student Name</p>
                  </div>
                  <div className='w-full'>
                    <span className="text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer  group relative" >
                      View
                      <div
                        role="tooltip"
                        className="absolute left-[40%] -top-[20%] transform -translate-x-1/2 mt-2 w-max p-3 text-sm font-medium text-white 
                      bg-[#023565] rounded-lg shadow-sm opacity-0 transition-opacity duration-300 group-hover:opacity-100 z-10"
                      >
                        <div className="mb-2 border-b border-gray-500">
                          <strong>Classes:</strong> {item?.bookFreeDemo?.map((ele) => ele?.classId?.name).join(', ') || 'N/A'}
                        </div>
                        <div className="border-b border-gray-500">
                          <strong>Subjects:</strong> {item?.bookFreeDemo?.map((ele) => ele?.subjectId?.name).join(', ') || 'N/A'}
                        </div>
                      </div>
                    </span>

                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      City
                    </p>
                  </div>
                  <div className='w-full'>
                    {item?.cityId?.name ? item?.cityId?.name : 'N/A'}

                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>

                    <p className='text-[16px] font-[700] text-left'>
                      Tutor Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'
                    >
                      {item?.tutorId?.name ?? "No tutor"}

                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full relative z-10'>

                    <BsThreeDotsVertical
                      className=" text-xl text-[#C60E6B] cursor-pointer"
                      onClick={() => handleAction(item)}
                    />
                    <div className={`absolute z-[100] ${open === item ? "block" : "hidden"}  w-40`}>
                      {open === item && (
                        <ul className="border rounded-md bg-white shadow-lg">
                          <li className="flex justify-between items-center border-b px-3 py-1" ref={mobileRef}>
                            <button
                              className="font-semibold text-[#C60E6B] rounded-full"
                              onClick={() => assignTutor(item)}
                            >
                              Change Tutor
                            </button>
                            <img src="/Group 1000001172.png" alt="Change Tutor" />
                          </li>
                          <li className="flex justify-between items-center px-3 py-1">
                            <button
                              className="text-[#023565] rounded-full font-semibold"
                              onClick={() => handleDenieTutor(item)}
                            >
                              Denie Tutor
                            </button>
                            <img src="/Group 1000001230.png" alt="Denie Tutor" />
                          </li>
                        </ul>
                      )}
                    </div>



                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>

    </div>
  )
}

export default LatestTutorChnage
