import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { packageConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const packageAction = {
    packageGet,
    packageCreate,
    deletePackage,
    updatePackage,
    enadisPackage,
    getAllPackageByPromise,

   
  };

  function getAllPackageByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/getAllPackageList"
    };
  
    return APIcallFunction(credentials)
  
  }


  function packageGet(data,packageList) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/getPackageList",
    };
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (users) => {
          dispatch(success(users));
          // dispatch(packageGet(packageList))
        },
        (error) => {
        //   dispatch(alertActions.failure(("fetch data failed")))
          dispatch(failure(error));
        }
      );
    };
  
    function request() {
      return { type: packageConstants.GET_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
      return { type: packageConstants.GET_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
      return { type: packageConstants.GET_PACKAGE_LIST_FAILURE, error };
    }
  };

  function packageCreate(data,packageList) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/create",
    };
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (users) => {
          dispatch(success(users));
          dispatch(packageGet(packageList))

        },
        (error) => {
        //   dispatch(alertActions.failure(("fetch data failed")))
          dispatch(failure(error));
        }
      );
    };
  
    function request() {
      return { type: packageConstants.CREATE_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
      return { type: packageConstants.CREATE_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
      return { type: packageConstants.CREATE_PACKAGE_LIST_FAILURE, error };
    }
  };

  function deletePackage(data,packageList) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/delete",
    };
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (users) => {
          dispatch(success(users));
          dispatch(packageGet(packageList))

        },
        (error) => {
        //   dispatch(alertActions.failure(("fetch data failed")))
          dispatch(failure(error));
        }
      );
    };
  
    function request() {
      return { type: packageConstants.DELETE_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
      return { type: packageConstants.DELETE_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
      return { type: packageConstants.DELETE_PACKAGE_LIST_FAILURE, error };
    }
  };

  function updatePackage(data,packageList) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/update",
    };
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (users) => {
          dispatch(success(users));
          dispatch(packageGet(packageList))
          dispatch(alertActions.success("Update Successfully"))
        },
        (error) => {
        //   dispatch(alertActions.failure(("fetch data failed")))
          dispatch(failure(error));
        }
      );
    };
  
    function request() {
      return { type: packageConstants.UPDATE_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
      return { type: packageConstants.UPDATE_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
      return { type: packageConstants.UPDATE_PACKAGE_LIST_FAILURE, error };
    }
  };

  function enadisPackage(data,packageList) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "package/enableDisable",
    };
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (users) => {
          dispatch(success(users));
          dispatch(packageGet(packageList))
        },
        (error) => {
        //   dispatch(alertActions.failure(("fetch data failed")))
          dispatch(failure(error));
        }
      );
    };
  
    function request() {
      return { type: packageConstants.ENA_DIS_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
      return { type: packageConstants.ENA_DIS_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
      return { type: packageConstants.ENA_DIS_PACKAGE_LIST_FAILURE, error };
    }
  };