import { attandanceConstant } from "../_constants";
import { headerForPrivateAPI, APIcallFunction } from "../_helpers";

export const attendanceAction = {
  attendanceGet,
  getAdminViewAttendanceByTuitionId,
  updateAttendanceByAdmin,
  getAbsentTodayByAdmin,
  checkInOutByAdmin
}

function attendanceGet(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "attendance/getAllAttendanceByAdmin"
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: attandanceConstant.GET_ATTENDANCE_LIST_REQUEST };
  }
  function success(user) {
    return { type: attandanceConstant.GET_ATTENDANCE_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: attandanceConstant.GET_ATTENDANCE_LIST_FAILURE, error };
  }
}

function getAdminViewAttendanceByTuitionId(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "attendance/getAdminViewAttendanceByTuitionId "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: attandanceConstant.GET_ADMIN_VIEWATTENDANCE_REQUEST };
  }
  function success(user) {
    return { type: attandanceConstant.GET_ADMIN_VIEWATTENDANCE_SUCCESS, user };
  }
  function failure(error) {
    return { type: attandanceConstant.GET_ADMIN_VIEWATTENDANCE_FAILURE, error };
  }
}

function updateAttendanceByAdmin(data, dataforadminview) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "attendance/updateAttendanceByAdmin  "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getAdminViewAttendanceByTuitionId(dataforadminview))

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_FAILURE, error };
  }
}

function getAbsentTodayByAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "attendance/getAbsentTodayByAdmin  "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_FAILURE, error };
  }
}


function checkInOutByAdmin(data, dataForAdminView, statusRes) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "attendance/checkInOutByAdmin  "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        if (statusRes) {
          dispatch(getAbsentTodayByAdmin(dataForAdminView))
        } else {
          dispatch(getAdminViewAttendanceByTuitionId(dataForAdminView))
        }


      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: attandanceConstant.CHECKIN_CHECKOUT_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: attandanceConstant.CHECKIN_CHECKOUT_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: attandanceConstant.CHECKIN_CHECKOUT_BYADMIN_FAILURE, error };
  }
}

