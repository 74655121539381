import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { feeListConstant } from "../_constants";
import { feeAction } from "./fee.action";
import { alertActions } from "./alert.actions";

export const feelistAction = {
  getFeeHistoryList,
  markPaidOrUnPaidByAdmin,
  feeReminder,
  getFeeHistoryListByAdmin,
  updateFeeDueDateByPromise,
  updateRefundFeeByPromise,
}

function updateRefundFeeByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/updateFeeRefundByAdmin"
  };

  return APIcallFunction(credentials)

}

function updateFeeDueDateByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateFeeDueDate"
  };

  return APIcallFunction(credentials)

}

function getFeeHistoryListByAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/getFeeHistoryListByAdmin",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_REQUEST };
  }
  function success(users) {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_FAILURE, error };
  }
}

function getFeeHistoryList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/getFeeHistoryList",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeListConstant.GET_FEE_HISTORY_LIST_FAILURE, error };
  }
}



function markPaidOrUnPaidByAdmin(data, feeListDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/markPaidOrUnPaidByAdmin",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        // dispatch(getFeeHistoryList(feeListDetails))
        dispatch(getFeeHistoryListByAdmin(feeListDetails))
        // dispatch(feeAction.getFeeHistoryCount())
        dispatch(alertActions.success("Tuition fee successfully marked paid."))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error("Please try again later."))
      }
    );
  };

  function request() {
    return { type: feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_REQUEST };
  }
  function success(users) {
    return { type: feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_FAILURE, error };
  }
}

function feeReminder(data, feeListDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/feeReminder ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getFeeHistoryListByAdmin(feeListDetails))
        dispatch(feeAction.getFeeHistoryCount())
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeListConstant.FEE_REMINDER_REQUEST };
  }
  function success(users) {
    return { type: feeListConstant.FEE_REMINDER_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeListConstant.FEE_REMINDER_FAILURE, error };
  }
}