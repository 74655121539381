import React, { useState } from 'react'
import Button from './Button'
import AbsentAll from './AbsentAll'
import ViewAll from './ViewAll'
import AttendanceHeader from './AttendanceHeader'
import { userListAction } from '../../../_actions'

const Attendence = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [active, setActive] = useState("Absent Today")
  const buttonName = ["Absent Today", "View All"]

  const [searchText, setSearchText] = useState("")
  const [city, setCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "");
  const [users, setUsers] = useState([])

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleCity = (e) => {
    let details = {
      "cityId": e.target.value !== "" ? [e.target.value] : [],
      "userType": "PARENT",
      "isDisable": false
    }
    setCity(e.target.value)

    userListAction.getUserTypeListByPromise(details).then((res) => {
      if (res?.data) {
        setUsers(() => res.data.map(ele => ele._id))
      }

    }).catch((err) => {
      setUsers([])
    })

  }

  const handleClick = (data) => {
    setActive(data)
    handleReset()
  }

  const handleReset = () => {
    setCity(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
    setSearchText("")
    setUsers("")
  }





  return (
    <>
      <AttendanceHeader searchText={searchText} handleSearch={handleSearch} city={city} handleCity={handleCity} roleId={roleId} />
      <section className='overflow-hidden overflow-y-auto h-full'>
        <div className='m-5'>
          <section className='flex gap-5'>
            {
              buttonName.map((item, id) => {
                return (
                  <>
                    <div key={id} className={`px-6 py-1 text-white font-bold rounded-full ${active === item ? 'bg-[#023565]' : 'bg-gray-400'}`} onClick={() => handleClick(item)}>
                      <Button name={item} />
                    </div>
                  </>
                )
              })
            }
          </section>
          <section>
            {active === "Absent Today" && <AbsentAll currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage} searchText={searchText} users={users} handleReset={handleReset} />}
            {active === "View All" && <ViewAll searchText={searchText} users={users} handleReset={handleReset} />}
          </section>
        </div>
      </section>
    </>
  )
}

export default Attendence