import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardAction } from '../../_actions/dashboard.action'
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from 'moment/moment';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';

const ButtonThird = ({ totalPages, nlatestReferalListData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    let newReferalDetails = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage - 1,
      size: pageSize,
    }

    dispatch(dashboardAction.getNewReferralsList(newReferalDetails))
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    let newReferalDetails = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage + 1,
      size: pageSize,
    }
    dispatch(dashboardAction.getNewReferralsList(newReferalDetails))
  };
  return (
    <div className='overflow-x-auto'>
      <Loader loading={selector?.Dashboard?.loading} />
      <div className='lg:block hidden'>
        <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
          <thead class=" text-[#313131]  font-bold  border-b-2 border-[#D1D1D1]">
            <tr className="border-b capitalize">
              <th class="px-6 py-4 whitespace-nowrap">S.No</th>
              <th class="px-6 py-4 whitespace-nowrap">user</th>
              <th class="px-6 py-4 whitespace-nowrap">City</th>
              <th class="px-6 py-4 whitespace-nowrap">Referral By</th>
              <th class="px-6 py-4 whitespace-nowrap">createdAt</th>
            </tr>
          </thead>
          <tbody className=''>
            {
              nlatestReferalListData && nlatestReferalListData.length > 0 ? (nlatestReferalListData?.map((item, id) => (
                <tr class="bg-white capitalize" key={id}>
                  <td class="font-[400] text-[12px] text-black py-3 px-6  gap-2">{id + 1}</td>
                  <td class="font-[400] text-[12px] text-black py-3 px-6  gap-2">
                    {item?.refFrom?.name} <br></br> <span>{item?.refFrom?.mobNo}</span></td>
                  <td class="px-6 py-3 text-[12px]">{item?.city?.name || 'N/A'}</td>
                  <td class="px-6 py-3 text-[12px]">
                    {item?.userId?.name} <br></br> <span>{item?.userId?.mobNo}</span></td>
                  <td class="px-6 py-3 text-[12px]">{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
          <button onClick={() => navigate("/app/referral-appSetting")} >
            View all
          </button>
        </div>
      </div>



      <section className="space-y-5 py-10 lg:hidden block">
        {nlatestReferalListData && nlatestReferalListData.length > 0 ? (
          nlatestReferalListData?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>User </p>
                  </div>
                  <div className='w-full'>
                    <div className=" items-center  gap-2 capitalize">
                      {item?.refFrom?.name
                      } <br></br> <span>{item?.refFrom?.mobNo}</span>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    {item?.city?.name || 'N/A'}


                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Address
                    </p>
                  </div>
                  <div className='w-full'>
                    {item?.address ?? 'N/A'}

                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>

                    <p className='text-[16px] font-[700] text-left'>
                      Referral By

                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'
                    >
                      {item?.userId?.name
                      } <br></br> <span>{item?.userId?.mobNo}</span>

                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>CreatedAt</p>
                  </div>
                  <div className='w-full relative z-10'>
                    {moment(item?.createdAt).format('YYYY-MM-DD HH:mm')}





                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
      {/* {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">
              {currentPage}
            </span>{" "}
            of{" "}
            <span className="border px-4 py-2 text-black  rounded">
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
              ? "opacity-50 cursor-not-allowed"
              : ""
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )} */}
    </div>
  )
}

export default ButtonThird
