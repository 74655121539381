import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tutortoliveAction } from "../../_actions";
import ButtonComponent from "./ButtonComponent";
import TableFirst from "./TableFirst";
import TableSecond from "./TableSecond";
import TableThird from "./TableThird";
import "./Manage.css";
import { useLocation, useNavigate } from 'react-router';
import TutorToLiveHeader from "./TutorToLiveHeader";
import { cityAction } from "../../_actions/city.action";

const Tutor = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [tutorCount, setTutorCount] = useState({});
  const location = useLocation();
  const navigate = useNavigate()


  const [dataState, setDataState] = useState({
    newTutor: 0,
    rejected: 0,
    skip: 0,
  });

  const [activeTab, setActiveTab] = useState('Newly Joined Tutors');
  const [activeStatus, setActiveStatus] = useState('NEW_TUTOR');

  useEffect(() => {
    dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: true }));
    dispatch(cityAction.cityGet({ isManagement: true }))
  }, []);

  useEffect(() => {
    setTutorCount(() => selector?.TutorToLive?.getNewJoinTutorListCount?.data);
  }, [selector, dataState]);

  useEffect(() => {

    if (tutorCount) {
      setDataState(tutorCount)
    }
  }, [tutorCount, dataState])

  const handleTabClick = (tabName) => {
    if (tabName == 'Newly Joined Tutors') setActiveStatus('NEW_TUTOR')
    if (tabName == 'Rejected In Interview') setActiveStatus('REJECT_INTERVIEW')
    if (tabName == 'Skipped Tutors') setActiveStatus('SKIP_TUTOR')
    setActiveTab(tabName);
    navigate(`?tab=${tabName}`);
  };

  const buttonNames = [
    'Newly Joined Tutors',
    'Rejected In Interview',
    'Skipped Tutors',
  ];

  function valueReturnData(dataState) {

    switch (dataState) {
      case "Newly Joined Tutors":
        return tutorCount?.newTutor;
      case 'Rejected In Interview':
        return tutorCount?.rejected;
      case 'Skipped Tutors':
        return tutorCount?.skip
      default:
        return null
    }

  }


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location, dataState]);


  const [searchText, setSearchText] = useState('')
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearchCity = (e) => {
    setHeaderCity(e.target.value)
  }


  const newSetState = (data) => {
    setActiveStatus(data)
  }


  return (
    <>
      <TutorToLiveHeader handleSearch={handleSearch} searchText={searchText} handleSearchCity={handleSearchCity} headerCity={headerCity} roleId={roleId} />

      <section className="overflow-hidden overflow-y-auto ">
        <div className="m-5">
          <section className="w-full flex flex-wrap gap-4">
            {buttonNames.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleTabClick(item)}
                  className={`cursor-pointer text-gray-100 px-6 py-2 text-center lg:text-sm text-[12px] rounded-full ${activeTab === item ? 'bg-[#023565]' : 'bg-[#9C9EAB]'
                    }`}
                >
                  <ButtonComponent
                    onClick={() => handleTabClick(item)}

                    name={item}
                    value={valueReturnData(item)}
                  />
                </div>
              )
            })}
          </section>

          <section className="overflow-auto">
            {activeTab === 'Newly Joined Tutors' && <TableFirst newSetState={newSetState} searchText={searchText} headerCity={headerCity} />}
            {activeTab === 'Rejected In Interview' && <TableSecond newSetState={newSetState} searchText={searchText} headerCity={headerCity} />}
            {activeTab === 'Skipped Tutors' && <TableThird newSetState={newSetState} searchText={searchText} headerCity={headerCity} />}
          </section>
        </div>
      </section>
    </>
  );
};

export default Tutor;
