import moment from 'moment'
import React from 'react'

function MobileTable({ banner, handleChange, deleteUser, handleEdit }) {
  return (
    <section className="space-y-5 py-10 md:hidden block">
      {banner?.bannerGetList?.length > 0 ? (banner?.bannerGetList?.map((item, id) => (
        <div className=' bg-white p-4 rounded-[10px]' key={id}>
          <>
            <div className='flex justify-between p-2 w-full'>
              <div className='w-full'>
                <p className='text-[16px] font-[700] text-left'>Banner Name</p>
              </div>
              <div className='w-full'>
                <p className='text-[14px] font-[400] text-end'>{item && item.name ? item.name : null}</p>
              </div>
            </div>
            <div className='flex justify-between p-2 w-full'>
              <div className='w-full'>
                <p className='text-[16px] font-[700] text-left'>Image</p>
              </div>
              <div className='w-full flex justify-end'>
                <img
                  className="h-12 w-16 "
                  src={item.image}
                  alt={item.name}
                />
              </div>
            </div>
            <div className='flex justify-between p-2 w-full'>
              <div className='w-full'>
                <p className='text-[16px] font-[700] text-left'>Type</p>
              </div>
              <div className='w-full'>
                <p className='text-[14px] font-[400] text-end'>  {item.userType}</p>
              </div>
            </div>
            <div className='flex justify-between p-2 w-full'>
              <div className='w-full'>
                <p className='text-[16px] font-[700] text-left'>Date</p>
              </div>
              <div className='w-full'>
                <p className='text-[14px] font-[400] text-end'> {item && item.createdAt
                  ? moment(item.createdAt).format("YYYY-MM-DD")
                  : null}
                </p>
              </div>
            </div>
          </>
        </div>

      ))

      ) : (
        <p>Data Not Found</p>
      )

      }
    </section>
  )
}

export default MobileTable
