import React, { useEffect, useState } from "react";
import ClassComponent from "./Class";
import { useDispatch } from "react-redux";
import SubjectComponent from "./Subject";
import BoardComponent from "./Board";
import Button from './Button'
import ClassSubjectBoardHeader from "./ClassSubjectBoardHeader";
import { classAction } from "../../_actions/class.action";

const ClassSubjectBoard = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Class")
  const [searchText, setSearchText] = useState('')
  const [totalCount, setTotalCount] = useState({})
  const buttonName = ["Class", "Subject", "Board"]
  const [refresh, setRefresh] = useState(false)
  const handleClick = (data) => {
    setActive(data)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    classAction.getCountForClassSubjectBoardByPromise().then((res) => {
      setTotalCount(res.data)
    }
    ).catch(err => {
      setTotalCount({})
    })
    setRefresh(false)
  }, [dispatch, refresh])

  function valueReturnData(data) {
    switch (data) {
      case "Class":
        return totalCount?.classCount;
      case "Subject":
        return totalCount?.subjectCount;
      case "Board":
        return totalCount?.boardCount;
      default:
        return 0;
    }
  }



  return (
    <>
      <ClassSubjectBoardHeader searchText={searchText} handleSearch={handleSearch} />
      <section className="overflow-hidden overflow-y-auto">
        <section className="m-5 flex gap-6">
          {
            buttonName.map((btnName, index) => {
              return (
                <div key={index} className={`flex font-semibold text-white px-3 lg:px-10 py-2 rounded-full ${active === btnName ? 'bg-[#023565]' : 'bg-[#9C9EAB]'}`} onClick={() => handleClick(btnName)}>
                  <Button name={btnName} value={valueReturnData(btnName)} />
                </div>
              )
            })
          }
        </section>
        <section className="">
          {active === "Class" && <ClassComponent searchText={searchText} setRefresh={setRefresh} />}
          {active === "Subject" && <SubjectComponent searchText={searchText} setRefresh={setRefresh} />}
          {active === "Board" && <BoardComponent searchText={searchText} setRefresh={setRefresh} />}
        </section>
      </section>

    </>
  );
};

export default ClassSubjectBoard;
