import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { MdOutlineClose } from 'react-icons/md'

function EditCity({ editModal, setEditModal, submitEdit, editInput, handelOnChangeEditInput, options, selectedOptionsEdit, addSelectEdit, handleSelect, handleRemoveEdit, inputFieldsEdit, handleInputChangeEdit, handleRemoveEditInput, packageList,errorData, setErrorData, }) {

    return (editModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setEditModal(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Edit City</h2>
                        </div>
                        <div className="relative lg:h-[550px] h-[400px] overflow-y-auto">
                            <form onSubmit={submitEdit} className="space-y-2 p-4">
                                <div className="space-y-2">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">City Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        name="name"
                                        placeholder="Enter city name..."
                                        value={editInput?.name}
                                        onChange={handelOnChangeEditInput}
                                        
                                    />
                                    {
                                            errorData && errorData['name'] ? <p className='text-red-600 text-sm'>{errorData['name']}</p> : null
                                        }
                                </div>
                                <div className="space-y-2">
                                    <label htmlFor="tuitionMode" className="block text-sm font-medium text-gray-700">Tuition Mode</label>
                                    <Multiselect
                                        options={options}
                                        selectedValues={selectedOptionsEdit}
                                        onSelect={handleSelect}
                                        onRemove={handleRemoveEdit}
                                        displayValue="label"
                                        className="border-[#D9E1EC] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                    />
                                     {
                                            errorData && errorData['selectedOptions'] ? <p className='text-red-600 text-sm'>{errorData['selectedOptions']}</p> : null
                                        }
                                </div>
                                {Array.isArray(selectedOptionsEdit) && selectedOptionsEdit.length > 0 && selectedOptionsEdit?.find((ele) => ele.value === "HOME_TUITION") && (
                                    <div className="space-y-2">
                                        <label htmlFor="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                                        <div className="flex flex-wrap justify-between space-y-2">
                                            {Array(Math.ceil(inputFieldsEdit.length / 2)).fill(0)?.map((_, rowIndex) => (
                                                <div key={rowIndex} className="flex justify-between place-items-center gap-4 w-full mb-2">
                                                    {inputFieldsEdit.slice(rowIndex * 2, rowIndex * 2 + 2)?.map((inputField, index) => (
                                                        <div key={index} className="flex items-center space-x-2">
                                                            <input
                                                                type="text"
                                                                value={inputField?.value}
                                                                onChange={(event) => handleInputChangeEdit(rowIndex * 2 + index, event)}
                                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                                            />
                                                            <IoIosRemoveCircleOutline
                                                                className="text-lg text-red-800 hover:text-red-900 cursor-pointer transition duration-300 ease-in-out"
                                                                onClick={() => handleRemoveEditInput(rowIndex * 2 + index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={addSelectEdit}
                                                className="text-lg text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
                                            >
                                                <FaPlusCircle className="cursor-pointer text-lg" />
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="space-y-2">
                                    <label htmlFor="management" className="block text-sm font-medium text-gray-700">Work Mode</label>
                                    <select
                                        id="management"
                                        name="management"
                                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        value={editInput?.management}
                                        onChange={handelOnChangeEditInput}
                                    >
                                        <option value="">Select</option>
                                        <option value={true}>Full Management</option>
                                        <option value={false}>Credit Based</option>
                                    </select>
                                    {
                                            errorData && errorData['management'] ? <p className='text-red-600 text-sm'>{errorData['management']}</p> : null
                                        }
                                </div>
                                {String(editInput?.management) === "true" && <div className="space-y-2">
                                    <div className="space-y-2">
                                        <label htmlFor="package" className="block text-sm font-medium text-gray-700">Package Name</label>
                                        <select
                                            id="package"
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                                            name="packageId"
                                            onChange={handelOnChangeEditInput}
                                            value={editInput?.packageId}
                                        >
                                            <option value={""}>Select Package</option>
                                            {packageList?.map((item, id) => (
                                                <option key={id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        {
                                                errorData && errorData['packageName'] ? <p className='text-red-600 text-sm'>{errorData['packageName']}</p> : null
                                            }
                                    </div>


                                    <div className="space-y-2">
                                        <label htmlFor="platformFee" className="block text-sm font-medium text-gray-700">Platform Fee</label>
                                        <input
                                            type="text"
                                            required
                                            disabled
                                            id="platformFee"
                                            value={Array.isArray(packageList) && packageList.length > 0 ? packageList.find((item) => String(item._id) === String(editInput?.packageId))?.amount : 0}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    <div className="space-y-2">
                                        <label htmlFor="packageNotice" className="block text-sm font-medium text-gray-700">Package Notice</label>
                                        <input
                                            type="text"
                                            id="packageNotice"
                                            // required
                                            name="packageNotice"
                                            value={editInput.packageNotice}
                                            onChange={handelOnChangeEditInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            placeholder="Package notice..."

                                        />
                                    </div>
                                </div>
                                }
                                <div className="flex justify-center pt-2">
                                    <button
                                        type="submit"
                                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                    >
                                        Update City
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    )
}

export default EditCity