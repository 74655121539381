import React, { useEffect, useState } from 'react'
import { IoAdd, IoRemove } from 'react-icons/io5';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { dateInGivenFormat } from '../../../../_helpers';
import { noOfClasses } from '../../../../_helpers/dataInitialState';
import { tutionAction, userListAction } from '../../../../_actions';
import CustomDatePicker from '../../../../components/Atoms/CustomDatePicker';
import { cityAction } from '../../../../_actions/city.action'
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";


const formDataInitialState = {
  parentName: "",
  mobNo: "",
  address: "",
  requiredGender: "",
  demoTime: "",
  demoDate: "",
  tuitionFee: "",
  classReference: "",
  cityId: "",
  tutorFee: "",
  numberOfClasses: "",
  secondaryNo: "",
  pinCodeId: "",
  classDuration: "",
  assignToEmployee: "",
  tuitionType: "",
  teachingLocation: ""
}

const initialFieldState = [
  {
    studentName: "",
    selectedClass: {},
    selectedSubjects: [],
    selectedBoard: {},
  },
]



function LeadCreateModel({ createModal, closeLeadModal }) {
  const selector = useSelector(state => state)
  const employeeList = selector?.tution?.getAllLeadManger?.data;


  const newBtn = [{
    name: "HOME TUITION",
    value: "HOME_TUITION"
  }, {
    name: "ONLINE TUITION",
    value: "ONLINE_TUITION"
  }];
  const [currentTab, setCurrentTab] = useState("HOME_TUITION")
  const [errors, setErrors] = useState({})
  const [subjectOptions, setSubjectOptions] = useState([])
  const [pinCodeOptions, setPinCodeOptions] = useState([])

  const [fields, setFields] = useState(initialFieldState);
  const [selectedUserOptions, setSelectedUserOptions] = useState({
    value: "",
    label: "Choose a Parent",
  })

  const handleAddField = () => {
    setFields([...fields, { studentName: '', selectedSubjects: [], selectedBoard: '', selectedClass: '' }]);
  };


  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const [formData, setFormData] = useState(() => formDataInitialState)

  const [mobNoIsValid, setMobNoIsValid] = useState(null)

  const [selectedCity, setSelectedCity] = useState({ label: "Select City", value: "" })
  const [selectedPinCode, setSelectedPinCode] = useState({ label: "Select PinCode", value: "" })
  const [studentOptions, setStudentOptions] = useState([])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: ""
    }));
  };

  const handleMultiSelectChange2 = (selectedOptions, index, name) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = selectedOptions;
    setFields(updatedFields);
  };


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    if (name === "studentName") {
      const studentData = studentOptions.find((stu) => stu.studentFullName.toLowerCase() === value.toLowerCase())
      if (studentData) {
        updatedFields[index][name] = value;
        updatedFields[index]["selectedClass"] = { label: studentData?.grade?.name, value: studentData?.grade?._id };
        updatedFields[index]["selectedSubjects"] = [];
        updatedFields[index]["selectedBoard"] = { label: studentData?.educationBoard?.name, value: studentData?.educationBoard?._id };
        setFields(updatedFields);
        const classSelected = selector?.Class?.classList.find((ele) => studentData?.grade?._id === ele._id)
        setSubjectOptions(classSelected?.subjectId.map((ele) => {
          return {
            label: ele.name,
            value: ele._id
          }
        }))
      } else {
        updatedFields[index][name] = value;
        updatedFields[index]["selectedClass"] = {};
        updatedFields[index]["selectedSubjects"] = [];
        updatedFields[index]["selectedBoard"] = {};
        setFields(updatedFields);
        setSubjectOptions(() => [])
      }

    } else {
      updatedFields[index][name] = value;
      setFields(updatedFields);
    }

  };


  const handleChangeClass = (selectedOption, index) => {
    const updatedFields = [...fields];
    updatedFields[index].selectedClass = selectedOption;
    setFields(updatedFields);
    const classSelected = selector?.Class?.classList.find((ele) => selectedOption.value === ele._id)
    setSubjectOptions(classSelected?.subjectId.map((ele) => {
      return {
        label: ele.name,
        value: ele._id
      }
    }))
  };
  const handleChangeBoard = (selectedOption, index) => {
    const updatedFields = [...fields];
    updatedFields[index].selectedBoard = selectedOption;
    setFields(updatedFields);
  };
  const dispatch = useDispatch()

  const handleTabChange = (item) => {
    setCurrentTab(item.value)
    setErrors({})
  };

  const handleChangUser = (selectedOption, actionMeta) => {
    const value = selectedOption ? selectedOption.value : "";
    if (value) {
      const userData = selector?.userList?.getUserListForSearch?.data.find((ele) => ele._id === value)
      const cityData = selector?.Citys?.cityGetList.find((ele) => ele._id === userData.city)

      if (!cityData.tuitionMode.includes(currentTab)) {
        setCurrentTab(() => cityData.tuitionMode[0])
      }

      setFormData((pre) => {
        return {
          ...pre,
          parentName: userData?.name,
          mobNo: userData.mobNo,
          address: userData.address,
          cityId: cityData._id,
          pinCodeId: userData.pinCode && userData.pinCode[0] ? userData.pinCode[0] : ""
        }
      })
      setMobNoIsValid(true)
      setSelectedCity({ label: cityData.name, value: cityData._id })
      setSelectedPinCode({ label: userData.pinCode && userData.pinCode[0] ? userData.pinCode[0] : "Select PinCode", value: userData.pinCode && userData.pinCode[0] ? userData.pinCode[0] : "" })
      setPinCodeOptions(cityData?.pinCode?.map(ele => {
        return {
          label: ele,
          value: ele
        }
      }) || [])
      userListAction.getAllStudentListByPromise({ id: value }).then(res => {
        setStudentOptions(() => res.data)

      }).catch(err =>
        setStudentOptions([])
      )
      setFields(() => initialFieldState)

    } else {
      setFormData((pre) => {
        return {
          ...pre,
          parentName: "",
          mobNo: "",
          address: "",
          cityId: "",
          pinCodeId: ""


        }
      })
      setMobNoIsValid(null)
      setSelectedCity({ label: "Select City", value: "" })
      setSelectedPinCode({ label: "Select PinCode", value: "" })
      setPinCodeOptions([])
      setStudentOptions([])
      setFields(() => initialFieldState)


    }
    setSelectedUserOptions(selectedOption)

  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    if (name === "cityId") {
      const cityData = selector?.Citys?.cityGetList.find((ele) => ele._id === value)
      setPinCodeOptions(cityData?.pinCode?.map(ele => {
        return {
          label: ele,
          value: ele
        }
      }) || [])
      setSelectedCity(selectedOption)
      setSelectedPinCode({ label: "Select PinCode", value: "" })

    }
    if (name === "pinCodeId") {
      setSelectedPinCode(selectedOption)
    }
  };


  const handleCreateSubmit = () => {
    if (!validateFormData(formData)) {
      return;
    }

    let bookFreeDemo = fields.map((item) => ({
      studentId: item.studentName,
      subjectId: item.selectedSubjects?.map((subject) => subject.value),
      board: item.selectedBoard.value,
      classId: item.selectedClass.value,
    }));


    // return false
    const createdData = { ...formData, demoDate: dateInGivenFormat(formData.demoDate, "DD-MM-YYYY"), preferredTeachMode: currentTab, bookFreeDemo: bookFreeDemo }

    let demoData = {
      status: [0],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };


    dispatch(tutionAction.createDemoByAdmin(createdData, demoData));
    handleResetData()
  };


  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      demoDate: date,
    }));
  };


  const validateFormData = (data) => {
    let errorsData = {};
    if (!data.parentName.trim() || !data.parentName) {
      errorsData.parentName = "Parent name is required.";
    } else if (data.parentName.length < 3) {
      errorsData.parentName = "Parent name must be at least 3 characters long.";
    }

    // Validate mobNo (required, must be a valid phone number)
    const phonePattern = /^[0-9]{10}$/;
    if (!data.mobNo.trim()) {
      errorsData.mobNo = "Mobile number is required.";
    } else if (!phonePattern.test(data.mobNo)) {
      errorsData.mobNo = "Please enter a valid 10-digit mobile number.";
    }

    // Validate address (required)
    if (!data.address.trim() && currentTab === "HOME_TUITION") {
      errorsData.address = "Address is required.";
    }

    // Validate address (required)
    if (!data.teachingLocation.trim() && currentTab === "HOME_TUITION") {
      errorsData.teachingLocation = "Teaching location is required.";
    }

    // Validate requiredGender (required)
    if (!data.requiredGender) {
      errorsData.requiredGender = "Gender is required.";
    }

    // Validate demoDate (required, must be a valid date)
    if (!data.demoDate) {
      errorsData.demoDate = "Demo date is required.";
    } else if (isNaN(Date.parse(data.demoDate))) {
      errorsData.demoDate = "Please enter a valid date.";
    }

    // Validate tuitionFee (required, must be a positive number)
    if (!data.tuitionFee) {
      errorsData.tuitionFee = "Tuition fee is required.";
    } else if (isNaN(data.tuitionFee) || data.tuitionFee <= 0) {
      errorsData.tuitionFee = "Please enter a valid tuition fee.";
    }

    // Validate cityId (required)
    if (!data.cityId.trim()) {
      errorsData.cityId = "City is required.";
    }

    if (!data.tutorFee) {
      errorsData.tutorFee = "Tutor fee is required.";
    } else if (isNaN(data.tutorFee) || data.tutorFee <= 0) {
      errorsData.tutorFee = "Please enter a valid tutor fee.";
    }


    if (!data.numberOfClasses.trim()) {
      errorsData.numberOfClasses = "Session details is required.";
    }
    if (data.secondaryNo && !phonePattern.test(data.secondaryNo)) {
      errorsData.secondaryNo = "Please enter a valid 10-digit secondary number.";
    }

    const pinCodePattern = /^[0-9]{6}$/;
    if (!data.pinCodeId.trim() && currentTab === "HOME_TUITION") {
      errorsData.pinCodeId = "Pin code is required.";
    } else if (!pinCodePattern.test(data.pinCodeId) && currentTab === "HOME_TUITION") {
      errorsData.pinCodeId = "Please enter a valid 6-digit pin code.";
    }
    if (!data.classDuration) {
      errorsData.classDuration = "Class duration is required.";
    } else if (isNaN(data.classDuration) || data.classDuration <= 0) {
      errorsData.classDuration = "Please enter a valid class duration.";
    }

    if (!data.tuitionType) {
      errorsData.tuitionType = "Fee type is required.";
    }

    fields.forEach((item, index) => {
      // Check for studentName
      if (!item.studentName) {
        errorsData[`studentName_${index}`] = 'Student name is required';
      }

      // Check for classId
      if (!item.selectedClass?.value) {
        errorsData[`selectedClass_${index}`] = 'Class is required';
      }

      // Check for board
      if (!item.selectedBoard?.value) {
        errorsData[`selectedBoard_${index}`] = 'Board is required';
      }

      // Check for subject array
      if (!item.selectedSubjects || item.selectedSubjects.length === 0) {
        errorsData[`selectedSubjects_${index}`] = 'Min 1 subject is required';
      }
    });

    if (mobNoIsValid === false) {
      errorsData.mobNo = "This mobile number already exist.";
    }

    setErrors(errorsData)
    return Object.keys(errorsData).length === 0 && mobNoIsValid;
  };

  const handleResetData = () => {
    setFormData(() => formDataInitialState)
    setErrors({})
    setFields([
      {
        studentName: "",
        selectedClass: {},
        selectedSubjects: [],
        selectedBoard: {},
      },
    ])
    setMobNoIsValid(null)
    setSelectedUserOptions({
      value: "",
      label: "Choose a Parent",
    })
    closeLeadModal()
    setStudentOptions(() => [])

  }

  useEffect(() => {
    if (currentTab === "HOME_TUITION") {
      dispatch(cityAction.cityGet({ isManagement: true }))

    } else {
      dispatch(cityAction.cityGet())
    }
  }, [currentTab])

  useEffect(() => {
    if (formData.mobNo != "" && selectedUserOptions.value === "" && formData.mobNo.length >= 10) {
      tutionAction.checkMobNoByPromise({ mobNo: formData.mobNo }).then((resData) => {

        if (resData.data) {
          setMobNoIsValid(false)
        } else {
          setMobNoIsValid(true)
        }
      }).catch((err) => {
      });

    } else if (selectedUserOptions.value !== "") {
      setMobNoIsValid(true)
    } else {
      setMobNoIsValid(null)
    }


  }, [formData.mobNo])

console.log("selectedCity.value",selectedCity.value,employeeList)

  return createModal && (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[800px]">
          <button className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center" onClick={() => {
            handleResetData()
          }}>
            <span>
              <MdOutlineClose />
            </span>
            Close
          </button>

          <div className="form_class rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none border-2">
            <section className="grid grid-cols-2  w-full">
              {newBtn.map((itm, id) => (
                <div key={id} className="w-full flex justify-center  ">
                  <button
                    className={`border mx-4 my-2 text-sm rounded-md py-3 w-full  ${currentTab === itm.value
                      ? "bg-[#023565] font-semibold text-white "
                      : "font-semibold text-[#121212]"
                      }`}
                    onClick={() => handleTabChange(itm)}
                  >
                    {itm.name}
                  </button>
                </div>
              ))}
            </section>
            <section>
              <div className="p-2 list-none w-full h-full">
                <div className="flex items-center justify-center">
                  <Select
                    id="UserData"
                    className="mt-1 lg:w-96 w-60"
                    classNamePrefix="react-select"
                    value={selectedUserOptions}
                    options={
                      Array.isArray(selector?.userList?.getUserListForSearch?.data) && selector?.userList?.getUserListForSearch?.data.length > 0
                        ? [{
                          value: "",
                          label: "Choose a Parent",
                        },
                        ...selector?.userList?.getUserListForSearch?.data.map((user) => ({
                          value: user._id,
                          label: `${user?.name} (${user.mobNo})`,
                        }))]
                        : []
                    }
                    onChange={handleChangUser}
                    placeholder="Choose a Parent"
                  />

                </div>
                <div className="p-4 h-[52vh] overflow-hidden overflow-y-auto Pokercardbar">
                  <form className="py-2">
                    <div className='lg:grid grid-cols-3 gap-4'>
                      <div className="w-full">
                        <label
                          htmlFor="parentName"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Parent Name :
                        </label>
                        <input
                          type="text"
                          name="parentName"
                          id="parentName"
                          required
                          value={formData.parentName}
                          onChange={handleChange}
                          placeholder="Enter Parent Name"
                          className="p-2 border block w-full border-gray-300 rounded-md"
                          disabled={selectedUserOptions.value != "" ? true : false}
                        />
                        {errors.parentName && (
                          <span className="text-red-500 text-sm">
                            {errors.parentName}
                          </span>
                        )}
                      </div>
                      <div className="w-full relative">
                        <label
                          htmlFor="mobNo"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Mobile No :
                        </label>
                        <input
                          type="number"
                          name="mobNo"
                          id="mobNo"
                          value={formData.mobNo}
                          onChange={handleChange}
                          placeholder="Enter Mobile Number"
                          className="p-2 border block w-full border-gray-300 rounded-md"
                          disabled={selectedUserOptions.value != "" ? true : false}

                        />
                        {formData.mobNo != "" && mobNoIsValid != null &&
                          <span
                            className={`absolute top-[36px] right-0 pr-3 flex items-center  ${mobNoIsValid ? 'text-green-500' : 'text-red-500'
                              }`}
                          >
                            {mobNoIsValid ? (
                              <FaCheckCircle className="text-green-500" />
                            ) : (
                              <FaTimesCircle className="text-red-500" />
                            )}
                          </span>
                        }
                        {errors.mobNo && (
                          <span className="text-red-500 text-sm">
                            {errors.mobNo}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="secondaryNo"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Alternate Mobile Number (Optional) :
                        </label>
                        <input
                          name="secondaryNo"
                          id="secondaryNo"
                          value={formData.secondaryNo}
                          onChange={handleChange}
                          placeholder="Enter Alternate Mobile Number"
                          className="p-2 border block w-full border-gray-300 rounded-md"
                          disabled={selectedUserOptions.value != "" ? true : false}

                        />
                      </div>
                    </div>
                    {fields.map((field, index) => (
                      <>
                        <div className='grid lg:grid-cols-3 gap-4'>
                          <div className="w-full">
                            <label
                              htmlFor="studentName"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Student Name :
                            </label>
                            <input
                              type="text"
                              name="studentName"
                              value={field.studentName}
                              onChange={(e) => {
                                handleInputChange(e, index);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [`studentName_${index}`]: "",
                                }));
                              }}
                              placeholder="Enter Student Name"
                              className="p-1.5 border block w-full border-gray-300 rounded-md"
                              list="student-options"

                            />
                            <datalist id="student-options">
                              {studentOptions?.map((student, index) => (
                                <option key={index} value={student.studentFullName} />
                              ))}
                            </datalist>
                            {errors[`studentName_${index}`] && (
                              <span className="text-red-500 text-sm">
                                {errors[`studentName_${index}`]}
                              </span>
                            )}
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="countries"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Class :
                            </label>
                            <Select
                              id="countries"
                              classNamePrefix="react-select capitalize	"
                              options={selector?.Class?.classList?.map(classData => ({
                                label: classData.name,
                                value: classData._id,
                              })) || []}
                              onChange={(selectedOption) => {
                                handleChangeClass(selectedOption, index);
                              }}
                              placeholder="Class"
                              value={field.selectedClass}
                            />
                            {errors[`selectedClass_${index}`] && (
                              <span className="text-red-500">
                                {errors[`selectedClass_${index}`]}
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="selectedBoard"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Board :
                            </label>
                            <Select
                              id="selectedBoard"
                              classNamePrefix="react-select capitalize "
                              options={selector?.Board?.bordList?.list?.map(classData => ({
                                label: classData.name,
                                value: classData._id,
                              })) || []}
                              onChange={(selectedOption) => {
                                handleChangeBoard(selectedOption, index);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [`selectedBoard_${index}`]: "",
                                }));
                              }}
                              placeholder="Board"
                              value={field.selectedBoard}

                            />
                            {errors[`selectedBoard_${index}`] && (
                              <span className="text-red-500 text-sm">
                                {errors[`selectedBoard_${index}`]}
                              </span>
                            )}
                          </div>
                          <div className="lg:w-[650px] w-full lg:col-span-2">
                            <label
                              htmlFor="selectedSubject"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Subject :
                            </label>
                            <Select
                              options={subjectOptions}
                              classNamePrefix="capitalize	"
                              value={field.selectedSubjects}
                              onChange={(selectedOptions) => {
                                handleMultiSelectChange2(selectedOptions, index, "selectedSubjects");
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [`selectedSubjects_${index}`]: "",
                                }));
                              }}
                              isMulti
                              placeholder="Subjects"
                            />
                            {errors[`selectedSubjects_${index}`] && (
                              <span className="text-red-500 text-sm">
                                {errors[`selectedSubjects_${index}`]}
                              </span>
                            )}

                          </div>

                          <div className="w-full flex justify-end items-center lg:pt-6 gap-2">
                            <button
                              type="button"
                              onClick={() => handleRemoveField(index)}
                              className={` px-1 py-1 text-sm flex justify-center items-center bg-red-600 rounded ${fields.length === 1
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                                }`}
                              disabled={fields.length === 1}
                            >
                              <IoRemove size={16} color='#fff' />
                            </button>
                            {index === fields.length - 1 && (
                              <button
                                type="button"
                                onClick={handleAddField}
                                className=" px-1 py-1 text-sm  flex justify-center items-center bg-[#023565] rounded"
                              >
                                <IoAdd size={16} color='#fff' />
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                    <div className='grid lg:grid-cols-4 gap-4'>

                      {currentTab === "HOME_TUITION" ?
                        <>
                          <div className="w-full lg:col-span-2">
                            <label
                              htmlFor="address"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Address :
                            </label>
                            <input
                              name="address"
                              id="address"
                              value={formData.address}
                              onChange={handleChange}
                              placeholder="Enter Address"
                              className="p-1.5 border block w-full border-gray-300 rounded-md"
                            />
                            {errors.address && (
                              <span className="text-red-500 text-sm">
                                {errors.address}
                              </span>
                            )}
                          </div>
                          <div className="w-full lg:col-span-2">
                            <label
                              htmlFor="teachingLocation"
                              className="text-[12px] font-[700] text-left text-[#313131]"
                            >
                              Teaching Location :
                            </label>
                            <input
                              name="teachingLocation"
                              id="teachingLocation"
                              value={formData.teachingLocation}
                              onChange={handleChange}
                              placeholder="Enter teaching location"
                              className="p-1.5 border block w-full border-gray-300 rounded-md"
                            />
                            {errors.teachingLocation && (
                              <span className="text-red-500 text-sm">
                                {errors.teachingLocation}
                              </span>
                            )}
                          </div>
                        </>
                        : null}
                      <div className="w-full">
                        <label
                          htmlFor="cityId"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          City :
                        </label>
                        <Select
                          id="cityId"
                          name="cityId"
                          className="capitalize"
                          classNamePrefix="react-select"
                          value={selectedCity}
                          options={[
                            { label: 'Select city', value: '' },  // Add the "Select city" option
                            ...(selector?.Citys?.cityGetList?.filter(city => city.tuitionMode.includes(currentTab)).map(city => ({
                              label: city.name,
                              value: city._id,
                            })) || [])
                          ]}
                          onChange={(selectedOption, actionMeta) => handleSelectChange(selectedOption, { name: 'cityId' })}
                          placeholder="City"
                        />
                        {errors.cityId && (
                          <span className="text-red-500 text-sm">
                            {errors.cityId}
                          </span>
                        )}

                      </div>
                      {currentTab === "HOME_TUITION" ?
                        <div className="w-full">
                          <label
                            htmlFor="pinCodeId"
                            className="text-[12px] font-[700] text-left text-[#313131]"
                          >
                            Pin Code :
                          </label>

                          <Select
                            id="pinCodeId"
                            name="pinCodeId"
                            className="capitalize"
                            classNamePrefix="react-select"
                            value={selectedPinCode}
                            options={pinCodeOptions}
                            onChange={(selectedOption, actionMeta) => handleSelectChange(selectedOption, { name: 'pinCodeId' })}
                            placeholder="Pincode"
                          />
                          {errors.pinCodeId && (
                            <span className="text-red-500 text-sm">
                              {errors.pinCodeId}
                            </span>
                          )}

                        </div>
                        : null}
                      <div className="w-full">
                        <label
                          htmlFor="requiredGender"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Preferred Gender :
                        </label>
                        <select
                          name="requiredGender"
                          id="requiredGender"
                          value={formData.requiredGender}
                          onChange={handleChange}
                          className="p-2 border block w-full border-gray-300 rounded-md bg-white"
                        >
                          <option value="">Select</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="NO_PREFERENCE">
                            No Preference
                          </option>
                        </select>
                        {errors.requiredGender && (
                          <span className="text-red-500 text-sm">
                            {errors.requiredGender}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Class Duration(in min) :
                        </label>
                        <input
                          type="text"
                          name="classDuration"
                          id="classDuration"
                          value={formData.classDuration}
                          onChange={handleChange}
                          placeholder="Enter Class Duration"
                          className="p-1.5 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.classDuration && (
                          <span className="text-red-500 text-sm">
                            {errors.classDuration}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Session Details :
                        </label>

                        <select
                          name="numberOfClasses"
                          value={formData.numberOfClasses}
                          onChange={handleChange}
                          className="p-2 border block w-full border-gray-300 rounded-md bg-white capitalize"
                        >
                          <option value="">Select</option>
                          {noOfClasses.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        {errors.numberOfClasses && (
                          <span className="text-red-500 text-sm">
                            {errors.numberOfClasses}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Fee Type :
                        </label>
                        <select
                          name="tuitionType"
                          value={formData.tuitionType}
                          onChange={handleChange}
                          className="p-2 border block w-full border-gray-300 rounded-md bg-white capitalize"
                        >
                          <option value="">Select</option>
                          <option value="Per Month">Per Month</option>
                          <option value="For Course">For Course</option>
                          <option value="Per Class">Per Class</option>

                        </select>
                        {errors.tuitionType && (
                          <span className="text-red-500 text-sm">
                            {errors.tuitionType}
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Tuition Fee :
                        </label>
                        <input
                          type="text"
                          name="tuitionFee"
                          id="tuitionFee"
                          value={formData.tuitionFee}
                          onChange={handleChange}
                          placeholder="Enter Tuition Fee"
                          className="p-1.5 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.tuitionFee && (
                          <span className="text-red-500 text-sm">
                            {errors.tuitionFee}
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Tutor Fee
                        </label>
                        <input
                          type="text"
                          name="tutorFee"
                          id="tutorFee"
                          value={formData.tutorFee}
                          onChange={handleChange}
                          placeholder="Enter Tuition Fee"
                          className="p-1.5 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.tutorFee && (
                          <span className="text-red-500 text-sm">
                            {errors.tutorFee}
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="demoDate"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Demo Date :
                        </label>

                        <CustomDatePicker selectedDate={selectedDate} value={dateInGivenFormat(formData.demoDate, "DD/MM/YYYY")} onDateChange={handleDateChange} />
                        {errors.demoDate && (
                          <span className="text-red-500 text-sm">
                            {errors.demoDate}
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <label className="block mb-2 text-sm font-medium text-gray-700">
                          Demo Time (Optional) :
                        </label>
                        <input
                          type="time"
                          name="demoTime"
                          id="demoTime" value={formData.demoTime}
                          onChange={handleChange}
                          className="block w-full p-2 border border-gray-300 rounded-md"
                        />
                        {errors.demoTime && <span className="text-red-500">{errors.demoTime}</span>}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="classReference"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Class Reference (Optional):
                        </label>
                        <input
                          type="text"
                          name="classReference"
                          id="classReference"
                          value={formData.classReference}
                          onChange={handleChange}
                          placeholder="Enter Class Reference"
                          className="p-2 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.classReference && (
                          <span className="text-red-500 text-sm">
                            {errors.classReference}
                          </span>
                        )}
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="assignToEmployee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          Lead Manager (Optional) :
                        </label>
                        <select
                          name="assignToEmployee"
                          id="assignToEmployee"
                          className="p-2 border block w-full border-gray-300 rounded-md bg-white capitalize"
                          value={formData.assignToEmployee}
                          onChange={handleChange}
                        >
                          <option className="text-gray-300">Select Employee</option>
                          {Array.isArray(employeeList) && employeeList.length > 0 &&
                            (selectedCity.value !== ""
                              ? employeeList.filter(ele => ele.city.includes(selectedCity.value)).map((ele) => (
                                <option key={ele._id} value={ele._id}>
                                  {ele.name} ({ele?.role?.name})
                                </option>
                              ))
                              : employeeList.map((ele) => (
                                <option key={ele._id} value={ele._id}>
                                  {ele.name} ({ele?.role?.name})
                                </option>
                              ))
                            )
                          }
                        </select>
                        {errors.assignToEmployee && (
                          <span className="text-red-500 text-sm">
                            {errors.assignToEmployee}
                          </span>
                        )}
                      </div>
                    </div>

                  </form>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    onClick={handleCreateSubmit}
                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                  >
                    Create
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeadCreateModel