import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, packageAction } from "../../_actions";
import { FaAngleRight, FaEdit } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft, MdOutlineClose } from "react-icons/md";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import Button from "./Button";
import { IoMdAdd } from "react-icons/io";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../components/Loader";
import { BsThreeDotsVertical } from "react-icons/bs";


const Package = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [packageList, setPackageList] = useState();

  const [packageId, setPackageId] = useState(null)
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [packageName, setPackageName] = useState("");
  const [amount, setAmount] = useState("");
  const [session, setSession] = useState("");
  const [cityId, setCityID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [ediInput, setEditInput] = useState({
    id: '',
    name: '',
    amount: '',
    session: '',
    cityId: ''

  })

  const [errors, setErrors] = useState({ packageName: '', amount: '', session: '' });

  useEffect(() => {
    let packageList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrders: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(packageAction.packageGet(packageList));
  }, [currentPage]);


  useEffect(() => {
    if (selector?.Package?.packageList?.data) {
      setTotalPages(Math.round(selector?.Package?.packageList?.data / pageSize));
    }
    setPackageList(selector?.Package?.packageList?.data?.list)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const menuRef = useRef(null)





  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setPackageId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openCreatePackageModal = () => {
    setModal(true);
  };

  const handleDelete = (data) => {


    const packageList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrders: "desc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };

    const id = {
      id: data,
    };

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(packageAction.deletePackage(id, packageList))
        },
        {
          label: 'No',
          onClick: () => { } 
        }
      ]
    });
  };



  const handlePackageCreate = () => {
    let valid = true;
    let newErrors = { packageName: '', amount: '', session: '' };

    if (!packageName) {
      newErrors.packageName = 'Package name is required';
      valid = false;
    }
    if (!amount) {
      newErrors.amount = 'Amount is required';
      valid = false;
    }
    if (!session) {
      newErrors.session = 'Session is required';
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;
    let packageList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrders: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };

    let packageCreateData = {
      name: packageName,
      amount: amount,
      session: session,
      // cityId: [cityId],
    };
    dispatch(packageAction.packageCreate(packageCreateData, packageList));
    setModal(false);
    setPackageName('')
    setAmount('')
    setSession('')
    setCityID('')
  };

  const handleAction = (data) => {
    let packageList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrders: "desc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };
    let enadisData = {
      id: data._id,
    };


    confirmAlert({
      title: `Confirm to ${data?.isDisable === false ? 'Inactive' : 'Active'}?`,
      // message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(packageAction.enadisPackage(enadisData, packageList))

        },
        {
          label: 'No',
          onClick: () => { } // Optionally handle the 'No' click
        }
      ]
    })
  };
  const handle = (e) => {
    e.preventDefault();
    setCityID(e.target.value);
  };

  const handleUpdate = (data) => {
    setEditModal(true)
    setEditInput(data)
  }

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setEditInput({ ...ediInput, [name]: value });
  }

  const handlePackageEdit = () => {
    let packageList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrders: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    let editData = {
      id: ediInput._id,

      name: ediInput.name,
      amount: ediInput.amount,
      session: ediInput.session,

    }
    dispatch(packageAction.updatePackage(editData, packageList))
    setEditModal(false)
  }

  const closeModal = () => {
    setModal(false)
    setPackageName("")
    setAmount("")
    setSession("")
    setErrors((prevErrors) => ({
      ...prevErrors,
      packageName: ''
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      amount: ''
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      session: ''
    }));


  }

  const handleMenuAction = (data) => {

    setPackageId((prevdata) => (prevdata === data ? null : data));


  }
  return (
    <section className="overflow-hidden overflow-y-auto">
      <Loader loading={selector?.Package?.loading} />

      <div className="m-5">
        <section className="lg:flex hidden justify-end place-items-center absolute right-10 -my-16 ">
          <div
            className="border  bg-white rounded font-semibold border-solid text-[#C60E6B] py-2 border-[#D9D9D9] flex px-3 -mt-4  place-items-center"
            onClick={() => openCreatePackageModal()}
          >
            <div className=" mr-2 ">
              <FaPlus className="cursor-pointer" />
            </div>
            <Button className="text-[#C60E6B]" name={"New Package"} />
          </div>
        </section>
        <section
          className="lg:hidden flex justify-center absolute top-[11%] right-10 w-10 h-10 items-center font-bold text-[#C60E6B] border bg-white rounded-full"
          onClick={() => openCreatePackageModal()}
        >
          <span>
            <IoMdAdd className="text-xl mx-1" />
          </span>
        </section>
        <div className="md:block hidden overflow-x-auto overflow-y-auto overflow-hidden bg-white rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-black ">
            <thead className="border-b">
              <tr className="">
                <th scope="col" class="px-6 py-4">
                  S No
                </th>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Amount
                </th>
                <th scope="col" class="px-6 py-3">
                  Session
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>

                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {packageList && packageList.length > 0 ? (
                packageList?.map((item, id) => {
                  return (
                    <>
                      <tr
                        class="bg-white border-b capitalize"
                        key={id}
                      >
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {id + 1}
                        </th>
                        <td class="px-6 py-4">{item.name}</td>
                        <td class="px-6 py-4">{item.amount}</td>
                        <td class="px-6 py-4">{item.session}</td>
                        <td
                          className={`px-6 py-4 cursor- font-bold ${item.isDisable ? 'text-red-600' : 'text-green-600'
                            }`}

                        >
                          {item.isDisable ? 'Inactive' : 'Active'}
                        </td>
                        <td class="px-6 py-4 relative">
                          <BsThreeDotsVertical
                            className="text-red-700 text-xl cursor-pointer "
                            onClick={() => handleMenuAction(item._id)}
                          />
                        </td>
                        {packageId === item._id && (
                          <div className="fixed right-14 mt-6 bg-white border border-gray-300 text-[#023565] shadow-lg z-10 rounded-lg" ref={menuRef}>
                            <p
                              className={`px-6 flex  justify-between place-items-center py-4 hover:text-[#E4006F]  cursor-pointer font-bold ${item.isDisable === false ? 'text-[#023565]' : 'text-green-600'}`}
                              onClick={() => handleAction(item)}
                            >
                              {item.isDisable == true ? 'Active' : 'Inactive'}
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                            <p className="px-6 py-4 gap-3 cursor-pointer flex justify-between place-items-center hover:text-[#E4006F] font-semibold" onClick={() => handleUpdate(item)}>
                              <span>View & Update</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />

                            </p>
                            <p className="px-6 py-4 flex justify-between place-items-center hover:text-[#E4006F]  font-semibold" onClick={() => handleDelete(item._id)}>

                              <span className="cursor-pointer" >Delete</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />

                            </p>
                          </div>
                        )}


                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {modal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={closeModal}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Create Package</h2>
                  </div>
                  <div className="relative lg:h-[340px] overflow-y-auto">
                    <div className="p-4 space-y-2">
                      <div className="space-y-2">
                        <label>Package Name</label>
                        <input
                          type="text"
                          value={packageName}
                          onChange={(e) => {
                            setPackageName(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              packageName: ''
                            }));
                          }}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder=""
                          required
                        />
                        {errors.packageName && <p className="text-red-500 text-xs mt-1">{errors.packageName}</p>}
                      </div>
                      <div className="space-y-2">
                        <label>Amount</label>
                        <input
                          type="number"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              amount: ''
                            }));
                          }}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder=""
                          required
                        />
                        {errors.amount && <p className="text-red-500 text-xs mt-1">{errors.amount}</p>}
                      </div>
                      <div className="space-y-2">
                        <label>Session</label>
                        <input
                          type="number"
                          value={session}
                          onChange={(e) => {
                            setSession(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              session: ''
                            }));
                          }}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder=""
                          required
                        />
                        {errors.session && <p className="text-red-500 text-xs mt-1">{errors.session}</p>}

                      </div>
                      <div className="flex justify-center items-center pt-2">
                        <button
                          onClick={handlePackageCreate}
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {editModal && (
          <>

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setEditModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Edit Package</h2>
                  </div>
                  <div className="relative lg:h-[340px] overflow-y-auto">
                    <div className="p-4 space-y-2">
                      <div className="space-y-2">
                        <label>Edit Package Name</label>
                        <input
                          type="text"
                          name="name"
                          value={ediInput?.name}
                          onChange={handleChnage}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder="Enter pincode"
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Edit Amount</label>
                        <input
                          type="text"
                          name="amount"
                          value={ediInput?.amount}
                          onChange={handleChnage}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder=""
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Edit Session</label>
                        <input
                          type="text"
                          name="session"
                          value={ediInput?.session}
                          onChange={handleChnage}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          placeholder=""
                        />
                      </div>
                      <div className="flex justify-center items-center pt-2">
                        <button
                          onClick={handlePackageEdit}
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <section className="md:hidden block  space-y-5">
          {packageList && packageList.length > 0 ? (
            packageList?.map((item, id) => {
              return (
                <div className=' bg-white p-4 rounded-[10px]'>
                  <>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>S No</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{id + 1}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Name</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{item.name}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Amount</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'> {item.amount}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Session	</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{item.session}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Status	</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'> <button
                          className="font-bold rounded-md"
                          onClick={() => handleAction(item._id)}
                          style={{
                            color: item.isDisable ? "red" : "#00AFE2",
                          }}
                        >
                          {item.isDisable ? "Inactive" : "Active"}
                        </button></p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Action</p>
                      </div>
                      <div className='w-full'>
                        <ul className="flex gap-4  items-center ">
                          <li className="cursor-pointer" onClick={() => handleUpdate(item)}><img src="/writing.png" alt="dfvn " /></li>
                          <li className="cursor-pointer" onClick={() => handleDelete(item._id)}><img src="/delete.png" alt="dfvn " /></li>
                        </ul>
                      </div>
                    </div>
                  </>
                </div>
              );
            })
          ) : (
            <div>
              <p className="text-center">
                Data Not Available
              </p>
            </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
            </span>
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Package;
